import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const TOKEN = "fa45d71d096c0e";

export const ipInfoApi = createApi({
  reducerPath: "ipInfoApi",
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    getIpInfo: builder.mutation<IpInfo, null>({
      query: () => ({
        url: `https://ipinfo.io?token=${TOKEN}`,
      }),
    }),
  }),
});

export const { useGetIpInfoMutation } = ipInfoApi;
