import styled from "styled-components";
import waterDropLeftIcon from "assets/waterDropLeftIcon.svg";

export const StyledSideBar = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "87px",
  height: "90%",
  minHeight: "60%",
  padding: "16px 0px 35px",
  background: "#060D40",
  border: "1px solid #11172C",
  borderRadius: "0px 20px 20px 0px",
  position: "fixed",
  top: "0px",
  gap: "15px",
  boxSizing: "border-box",

  "@media screen and (max-width: 1200px)": {
    display: "none",
  },
});

export const SideBarItemWrapper = styled.div({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const StyledSideBarItem = styled.div<{
  isSelected: boolean;
  defaultColor?: string;
  mobile?: boolean;
  height: string;
}>(({ isSelected, defaultColor = "none", height, mobile }) => ({
  height,
  width: "100%",
  minWidth: "86px",
  position: "relative",
  boxSizing: "border-box",
  cursor: "pointer",
  display: "flex",
  flexDirection: mobile ? "row" : "column",
  justifyContent: "center",
  alignItems: "flex-start",
  paddingLeft: "33px",
  gap: "10px",

  "& svg path": {
    fill: isSelected ? "#677AEC" : defaultColor,
    stroke: isSelected ? "#11172C" : "#6B60CF",
  },

  "& a": {
    textAlign: "left",
    textDecoration: "none",
    color: "white",
  },

  "&::before": {
    display: "block",
    opacity: isSelected ? 1 : 0,
    content: "''",
    background: `url(${waterDropLeftIcon})`,
    backgroundRepeat: "no-repeat",
    position: "absolute",
    height: "100px",
    width: "30px",
    left: "0px",
    transform: `translateY(${mobile ? "-37px" : undefined})`,
    zIndex: 1,
    transition: "opacity 0.2s",
  },

  "&:hover": {
    svg: {
      fill: "#677AEC",
    },
  },

  "&:hover::before": {
    opacity: 1,
  },

  "@media screen and (max-width: 1200px)": {
    paddingLeft: isSelected ? "45px" : "33px",
  },
}));
