import React from "react";
import { ErrorMessageText, InputWrapper } from "./styles";
import { ReactComponent as CircleSlash } from "assets/circleSlash.svg";

const Input: React.FC<InputProps> = ({
  value,
  name,
  inputType = "text",
  placeholder = undefined,
  handleChange,
  handleBlur,
  leftIcon,
  rightIcon,
  inputProps,
  error,
}) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event.target.value, name);
  };

  return (
    <InputWrapper error={error}>
      {leftIcon}

      <input
        name={name}
        placeholder={placeholder}
        value={value}
        type={inputType}
        onChange={handleOnChange}
        onBlur={handleBlur || undefined}
        {...inputProps}
      />

      {rightIcon}

      {error && (
        <ErrorMessageText>
          <CircleSlash fill="white" /> {error}
        </ErrorMessageText>
      )}
    </InputWrapper>
  );
};

export default Input;
