import styled from "styled-components";
import background from "assets/balanceBackground.png";

export const StyledBalanceCard = styled.div({
  display: "flex",
  // 15.6
  width: "390px",
  height: "111px",
  gap: "20px",
  alignItems: "center",
  background: `url(${background})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  padding: "30px",
  boxSizing: "border-box",

  "@media screen and (max-width: 1200px)": {
    padding: "0px 0px 0px 30px",
  },

  "@media screen and (max-width: 1000px)": {
    width: "350px",
    padding: "0px 0px 0px 30px",
    height: "100px",
    gap: "25px",
  },
});

export const IconHolder = styled.div({
  width: "75px",
  height: "75px",
  background: "#677AEC",
  borderRadius: "13px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "& svg": {
    path: {
      fill: "white",
      stroke: "white",
    },
  },

  "@media screen and (max-width: 1000px)": {
    width: "60px",
    height: "60px",
  },
});

export const BalanceTextWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
});

export const BalanceTitle = styled.div({
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  color: "white",
  fontFamily: "Stolzl",

  "@media screen and (max-width: 1000px)": {
    fontSize: "12px",
    color: "white",
  },
});

export const BalanceAmount = styled.div({
  fontSize: "23px",
  fontWeight: "bold",
  lineHeight: "41px",
  textTransform: "uppercase",
  color: "#FFFFFF",
  fontFamily: "Benzin",

  "@media screen and (max-width: 1000px)": {
    fontSize: "22px",
    lineHeight: "29px",
  },
});
