import styled from "styled-components";

export const StyledWalletPage = styled.div({
  // 15.6
  minWidth: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  padding: "0px 0px 30px",

  "@media screen and (max-width: 1200px)": {
    alignItems: "center",
  },
});

export const ContentWrapper = styled.div({
  display: "flex",
  gap: "35px",

  "@media screen and (max-width: 1200px)": {
    gap: "15px",
  },

  "@media screen and (max-width: 1000px)": {
    flexDirection: "column",
    alignItems: "center",
  },
});
