import { Table } from "../../components";
import { Form } from "react-bootstrap";
import { SelectGroupWrapper, SelectWrapper, StyledTab } from "./styles";
import {
  useGetBetsStatisticsQuery,
  useGetProfitQuery,
} from "redux/api/adminApi";
import { ChangeEvent, useMemo, useState } from "react";
import moment from "moment";

const betsColumns = [
  { field: "totalBets", title: "Количество" },
  { field: "totalAmount", title: "Общая сумма" },
  { field: "minAmount", title: "Минимальная сумма" },
  { field: "maxAmount", title: "Максимальная сумма" },
  { field: "mediumAmount", title: "Средняя сумма" },
];

const profitColumns = [
  { field: "percentage", title: "Профит" },
  { field: "balanceLosses", title: "Резерв" },
];

const Bets = () => {
  const [period, setPeriod] = useState<[string, string]>(["", ""]);
  const { data, isLoading, isFetching } = useGetBetsStatisticsQuery({
    page: 1,
    perPage: 15,
    filters: {
      ...(period[0] && {
        start_date: period[0],
        end_date: period[1],
      }),
    },
  });
  const { data: profit, isLoading: isProfitLoading } = useGetProfitQuery({
    start_date: period[0] || undefined,
    end_date: period[1] || undefined,
  });

  const formattedData = useMemo(() => {
    if (data) {
      return {
        all: [data.all],
        wins: [data.wins],
      };
    }

    return { all: [], wins: [] };
  }, [isLoading, data]);

  const handlePeriodChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;

    switch (value) {
      case "all": {
        setPeriod(["", ""]);

        return;
      }

      case "yesterday": {
        const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
        const today = moment().format("YYYY-MM-DD");

        setPeriod([yesterday, today]);
        return;
      }

      case "month": {
        const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
        const endOfMonth = moment()
          .endOf("month")
          .add(1, "day")
          .format("YYYY-MM-DD");

        setPeriod([startOfMonth, endOfMonth]);
        return;
      }

      case "last_month": {
        const startOfMonth = moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        const endOfMonth = moment()
          .subtract(1, "month")
          .endOf("month")
          .add(1, "day")
          .format("YYYY-MM-DD");

        setPeriod([startOfMonth, endOfMonth]);
        return;
      }

      default: {
        const today = moment().format("YYYY-MM-DD");
        const endDay = moment().add(1, "day").format("YYYY-MM-DD");

        setPeriod([today, endDay]);
      }
    }
  };

  return (
    <StyledTab>
      <SelectGroupWrapper>
        <SelectWrapper width="49%">
          <span>Период</span>

          <Form.Select defaultValue={"all"} onChange={handlePeriodChange}>
            <option value="all">За все время</option>
            <option value="today">Сегодня</option>
            <option value="yesterday">Вчера</option>
            <option value="month">В этом месяце</option>
            <option value="last_month">В прошлом месяце</option>
          </Form.Select>
        </SelectWrapper>

        <SelectWrapper width="49%">
          <span>Валюта</span>

          <Form.Select aria-label="Default select example">
            <option value="usd">USD</option>
            <option value="euro">EURO</option>
            <option value="grn">GRN</option>
          </Form.Select>
        </SelectWrapper>

        <SelectWrapper width="49%">
          <span>Счет</span>

          <Form.Select aria-label="Default select example">
            <option value="real">Real</option>
            <option value="demo">Demo</option>
            <option value="bonus">Bonus</option>
          </Form.Select>
        </SelectWrapper>
      </SelectGroupWrapper>

      {isLoading || isFetching || !data ? (
        <div>LOADING...</div>
      ) : (
        <>
          <span>Все ставки</span>

          <Table data={formattedData.all} columns={betsColumns} />

          <span>Выиграшные ставки</span>

          <Table data={formattedData.wins} columns={betsColumns} />
        </>
      )}

      {isProfitLoading || !profit ? (
        <div>LOADING...</div>
      ) : (
        <>
          <span>Профит</span>

          <Table data={[profit.data]} columns={profitColumns} />
        </>
      )}
    </StyledTab>
  );
};

export default Bets;
