import React from "react";
import {
  InputWrapper,
  StyledInput,
  InputLabel,
  StyledInputWrapper,
  ErrorMessage,
} from "./styles";

const Input: React.FC<InputProps> = ({
  value,
  name,
  inputType = "text",
  placeholder = undefined,
  handleChange,
  leftIcon,
  rightIcon,
  label,
  inputProps,
  error,
}) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event.target.value, name);
  };

  return (
    <StyledInputWrapper>
      <StyledInput error={error}>
        {label && <InputLabel>{label}</InputLabel>}

        <InputWrapper>
          {leftIcon}

          <input
            name={name}
            placeholder={placeholder}
            value={value}
            type={inputType}
            onChange={handleOnChange}
            {...inputProps}
          />

          {rightIcon}
        </InputWrapper>
      </StyledInput>

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </StyledInputWrapper>
  );
};

export default Input;
