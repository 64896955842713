import BootstrapTablet from "react-bootstrap/Table";

type UnionColumn = {
  title: string;
  field?: string;
  children: { field: string; title: string }[];
};

type Column = {
  field: string;
  title: string;
  children?: never;
};

type Columns = Column | UnionColumn;

type Props = { columns: Columns[]; data: any };

const Table = ({ columns, data }: Props) => (
  <BootstrapTablet striped bordered hover responsive>
    <thead>
      <tr>
        {columns.map((column) => (
          <th key={column.title}>{column.title}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {data.length !== 0 &&
        data.map((row: any, index: number) => (
          <tr key={index}>
            {columns.map((column, i) => (
              <td key={i}>
                {column.children ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    {column.children.map(
                      ({ title, field: childrenField }, index) => (
                        <span key={index}>
                          <b>{title}</b>:{" "}
                          {column.field
                            ? row[column.field][childrenField]
                            : row[childrenField]}
                        </span>
                      )
                    )}
                  </div>
                ) : (
                  <span>{row[column.field] ? row[column.field] : "--"}</span>
                )}
              </td>
            ))}
          </tr>
        ))}
    </tbody>
  </BootstrapTablet>
);

export default Table;
