import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { StyledSideBarItem } from "./styles";

const SideBarItem: React.FC<SideBarItemProps> = ({
  icon,
  pathTo,
  isSelected,
  label = "",
  height = "70px",
  withTooltip = false,
  mobile,
}) => (
  <OverlayTrigger
    placement="right"
    overlay={withTooltip ? <Tooltip>{label}</Tooltip> : <></>}
  >
    <Link to={pathTo} style={{ textDecoration: "none", color: "white" }}>
      <StyledSideBarItem
        mobile={mobile}
        height={height}
        defaultColor={pathTo === "/wallet" ? "#8AEC67" : "none"}
        isSelected={isSelected}
      >
        {icon} {!withTooltip && <span>{label}</span>}
      </StyledSideBarItem>
    </Link>
  </OverlayTrigger>
);

export default SideBarItem;
