import { BalanceCard } from "components/index";
import { useAppSelector } from "redux/hooks";
import { HistoryTable } from "./components";
import { ContentWrapper, StyledWalletHistoryPage } from "./styles";

const WalletHistory = () => {
  const { userData } = useAppSelector("user");

  return (
    <StyledWalletHistoryPage>
      <ContentWrapper>
        <BalanceCard balance={`${Number(userData?.balance).toFixed(2)} USD`} />

        <HistoryTable />
      </ContentWrapper>
    </StyledWalletHistoryPage>
  );
};

export default WalletHistory;
