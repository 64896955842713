import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { generateUrlWithParams } from "utils/helpers";

export const adminApi = createApi({
  reducerPath: "adminApi",
  tagTypes: ["Clients", "KYC", "Withdraw", "Payment", "Settings"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers) => {
      if (window.localStorage.token) {
        headers.set("Authorization", window.localStorage.token as string);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    // CLIENTS
    getAllUsers: builder.query<ClientResponse["data"], PageProps>({
      query: ({ page, perPage, filters }) => {
        let url = `/admin/clients?per_page=${perPage}&page=${page}`;

        if (filters) {
          url += "&" + generateUrlWithParams(filters);
        }

        return {
          url,
        };
      },

      transformResponse: (response: ClientResponse): ClientResponse["data"] =>
        response.data,
      providesTags: ["Clients"],
    }),
    // KYC
    getAllKYC: builder.query<KYCResponse["data"], PageProps>({
      query: ({ page, perPage, filters }) => {
        let url = `/admin/kyc?per_page=${perPage}&page=${page}`;

        if (filters) {
          const { nickname, email, id } = filters;
          url += `&nickname=${nickname}&email=${email}&id=${id}`;
        }

        return {
          url,
        };
      },

      transformResponse: (response: KYCResponse): KYCResponse["data"] =>
        response.data,
      providesTags: ["KYC"],
    }),
    changeKYCStatus: builder.mutation<
      ApiResponseWithoutData,
      { id: number; status: KYCStatus }
    >({
      query: (props) => {
        const { id, status } = props;

        return {
          url: `/admin/kyc/${id}/update-status`,
          method: "POST",
          params: {
            _method: "PATCH",
          },
          body: { status },
        };
      },
      invalidatesTags: ["KYC"],
    }),
    deleteKYC: builder.mutation<any, number>({
      query: (KYCId: number) => {
        return {
          url: `admin/kyc/${KYCId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["KYC"],
    }),

    getBetsStatistics: builder.query<TotalBetsResponse["data"], PageProps>({
      query: ({ filters }) => {
        let url = `/admin/statistics/total-bets`;

        if (filters) {
          url += "?" + generateUrlWithParams(filters);
        }

        return {
          url,
        };
      },

      transformResponse: (
        response: TotalBetsResponse
      ): TotalBetsResponse["data"] => response.data,
    }),
    getStatistics: builder.query<any, any>({
      query: () => `/admin/statistics`,
    }),

    getStatisticsPercentage: builder.query<any, any>({
      query: () => `/admin/statistics/percentage`,
    }),

    getAllBets: builder.query<any, PageProps>({
      query: ({ page, perPage }) =>
        `/admin/bets?page=${page}&per_page=${perPage}`,

      transformResponse: (response: BetsResponse): BetsResponse["data"] => {
        if (response.status !== "success") {
          return [];
        }

        return response.data;
      },
    }),

    getAllWithdrawals: builder.query<WithdrawalsResponse, PageProps>({
      query: ({ page, perPage, filters }) => {
        let url = `/admin/withdrawal?page=${page}&per_page=${perPage}`;

        if (filters) {
          url += "&" + generateUrlWithParams(filters);
        }

        return {
          url,
        };
      },
      providesTags: ["Withdraw"],
    }),

    getAllTransactions: builder.query<TransactionsResponse, PageProps>({
      query: ({ page, perPage, filters }) => {
        let url = `/admin/transaction?page=${page}&per_page=${perPage}`;

        if (filters) {
          url += "&" + generateUrlWithParams(filters);
        }

        return {
          url,
        };
      },
    }),

    changeWithdrawalStatus: builder.mutation<
      any,
      { status: string; id: string }
    >({
      query: ({ status, id }) => ({
        url: `/admin/withdrawal/${id}/update-status`,
        method: "POST",
        params: {
          _method: "PATCH",
        },
        body: {
          status,
        },
        invalidatesTags: ["Withdraw"],
      }),
    }),

    getAllPaymentMethods: builder.query<any, PageProps>({
      query: ({ page, perPage }) =>
        `/admin/payment-methods?page=${page}&per_page=${perPage}`,
      providesTags: ["Payment"],
    }),

    createPaymentMethod: builder.mutation<any, any>({
      query: (body) => ({
        url: `/admin/payment-methods`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Payment"],
    }),

    deletePaymentMethod: builder.mutation<any, any>({
      query: (id: string) => ({
        url: `/admin/payment-methods/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Payment"],
    }),

    getProfit: builder.query<
      ApiResponse<{ percentage: number; balanceLosses: number }>,
      { start_date?: string; end_date?: string }
    >({
      query: ({ start_date, end_date }) => {
        let url = `/admin/statistics/percentage`;

        if (start_date || end_date) {
          url += "?" + generateUrlWithParams({ start_date, end_date });
        }

        return {
          url,
        };
      },
    }),

    getGameSettings: builder.query<AdminSettingsResponse, null>({
      query: () => "/admin/settings",
      providesTags: ["Settings"],
    }),
    updateGameSettings: builder.mutation<
      AdminSettingsResponse,
      Omit<AdminSettingsResponse["data"], "id">
    >({
      query: (body) => ({
        url: "/admin/settings",
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Settings"],
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useGetAllKYCQuery,
  useChangeKYCStatusMutation,
  useDeleteKYCMutation,
  useGetAllBetsQuery,
  useGetAllWithdrawalsQuery,
  useGetBetsStatisticsQuery,
  useGetStatisticsQuery,
  useCreatePaymentMethodMutation,
  useGetAllPaymentMethodsQuery,
  useGetAllTransactionsQuery,
  useChangeWithdrawalStatusMutation,
  useDeletePaymentMethodMutation,
  useGetStatisticsPercentageQuery,
  useGetProfitQuery,
  useGetGameSettingsQuery,
  useUpdateGameSettingsMutation,
} = adminApi;
