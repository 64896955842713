import {
  StyledVerificationModal,
  VerificationIconWrapper,
  VerificationModalBody,
} from "./styles";
import { ReactComponent as VerificationIcon } from "assets/verificationIcon.svg";
import { ReactComponent as CloseIcon } from "assets/closeIcon.svg";
import { useAppDispatch, useAppSelector, useTranslate } from "redux/hooks";
import { toggleVerificationModal } from "redux/stores/userSlice";
import { useEffect } from "react";

const VerificationModal = () => {
  const { isVerificationModalOpen } = useAppSelector("user");
  const dispatch = useAppDispatch();
  const translate = useTranslate();

  useEffect(() => {
    setTimeout(() => {
      dispatch(toggleVerificationModal(null));
    }, 4000);
  }, []);

  const handleClose = () => {
    dispatch(toggleVerificationModal(null));
  };

  return (
    <StyledVerificationModal onClick={handleClose}>
      <VerificationModalBody>
        <CloseIcon
          onClick={handleClose}
          cursor="pointer"
          width={30}
          height={30}
          style={{ alignSelf: "flex-end", marginRight: -30 }}
        />
        <VerificationIconWrapper>
          <VerificationIcon />
        </VerificationIconWrapper>

        <p>
          {translate(
            isVerificationModalOpen === "success"
              ? "PASSPORT_UPLOAD_SUCCESS_TITLE"
              : "PASSPORT_UPLOAD_FAILED_TITLE"
          )}
        </p>

        <span>
          {translate(
            isVerificationModalOpen === "success"
              ? "PASSPORT_UPLOAD_SUCCESS"
              : "PASSPORT_UPLOAD_FAILED"
          )}
        </span>
      </VerificationModalBody>
    </StyledVerificationModal>
  );
};

export default VerificationModal;
