import { Pagination, Table } from "../../components";
import { Form } from "react-bootstrap";
import { SelectGroupWrapper, SelectWrapper, StyledTab } from "./styles";
import {
  useChangeWithdrawalStatusMutation,
  useGetAllWithdrawalsQuery,
} from "redux/api/adminApi";
import { ChangeEvent, useMemo, useState } from "react";
import moment from "moment";

const withdrawalsColumns = [
  { field: "id", title: "ID" },
  { field: "created_at", title: "Дата" },
  { field: "amount", title: "Сумма" },
  { field: "wallet", title: "Кошелек" },
  {
    field: "client",
    title: "Клиент",
    children: [
      { field: "first_name", title: "Имя" },
      { field: "last_name", title: "Фамилия" },
      { field: "email", title: "Email" },
    ],
  },
  { field: "status", title: "Статус" },
];

const statisticsColumns = [
  { field: "total_count", title: "Total withdrawals" },
  { field: "min_amount", title: "Min amount" },
  { field: "max_amount", title: "Max amount" },
  { field: "average_amount", title: "Average amount" },
  { field: "total_amount", title: "Total amount" },
];

const SelectComponent = ({
  value,
  handleChange,
}: {
  value: string;
  handleChange: (value: string) => void;
}) => (
  <select value={value} onChange={({ target }) => handleChange(target.value)}>
    <option value={"pending"}>pending</option>
    <option value={"confirmed"}>confirm</option>
    <option value={"rejected"}>reject</option>
  </select>
);

const Deposit = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [period, setPeriod] = useState<[string, string]>(["", ""]);

  const {
    data: response,
    isLoading,
    refetch,
  } = useGetAllWithdrawalsQuery({
    page,
    perPage,
    filters:
      period[0] !== ""
        ? {
            start_date: period[0],
            end_date: period[1],
          }
        : undefined,
  });

  const [changeStatus] = useChangeWithdrawalStatusMutation();

  const handleStatusChange = async (status: string, id: string) => {
    await changeStatus({ status, id });

    refetch();
  };

  const formattedWithdrawals = useMemo(() => {
    if (response) {
      return response.data.withdrawals.data.map((withdraw: any) => {
        console.log("withdraw => ", withdraw);
        const { id, created_at, amount, client, wallet } = withdraw;

        const tableWithdraw = {
          id,
          wallet,
          created_at: moment(created_at).format("DD-MM-YYYY HH:mm:ss"),
          amount,
          client: {
            first_name: client.first_name,
            last_name: client.last_name,
            email: client.email,
          },
          status: (
            <SelectComponent
              value={withdraw.status}
              handleChange={(status) => handleStatusChange(status, id)}
            />
          ),
        };

        return tableWithdraw;
      });
    }

    return [];
  }, [response, handleStatusChange]);

  if (isLoading || !response) return null;

  const handlePeriodChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;

    switch (value) {
      case "all": {
        setPeriod(["", ""]);

        return;
      }

      case "yesterday": {
        const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
        const today = moment().format("YYYY-MM-DD");

        setPeriod([yesterday, today]);
        return;
      }

      case "month": {
        const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
        const endOfMonth = moment()
          .endOf("month")
          .add(1, "day")
          .format("YYYY-MM-DD");

        setPeriod([startOfMonth, endOfMonth]);
        return;
      }

      case "last_month": {
        const startOfMonth = moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        const endOfMonth = moment()
          .subtract(1, "month")
          .endOf("month")
          .add(1, "day")
          .format("YYYY-MM-DD");

        setPeriod([startOfMonth, endOfMonth]);
        return;
      }

      default: {
        const today = moment().format("YYYY-MM-DD");
        const endDay = moment().add(1, "day").format("YYYY-MM-DD");

        setPeriod([today, endDay]);
      }
    }
  };

  return (
    <StyledTab>
      <SelectGroupWrapper>
        <SelectWrapper width="49%">
          <span>Период</span>

          <Form.Select defaultValue={"all"} onChange={handlePeriodChange}>
            <option value="all">За все время</option>
            <option value="today">Сегодня</option>
            <option value="yesterday">Вчера</option>
            <option value="month">В этом месяце</option>
            <option value="last_month">В прошлом месяце</option>
          </Form.Select>
        </SelectWrapper>

        <SelectWrapper width="49%">
          <span>Статус</span>

          <Form.Select aria-label="Default select example">
            <option value="usd">USD</option>
            <option value="euro">EURO</option>
            <option value="grn">GRN</option>
          </Form.Select>
        </SelectWrapper>
      </SelectGroupWrapper>

      <span>Статистика снятий</span>

      <Table data={[response?.data?.statistics]} columns={statisticsColumns} />

      <span>Список снятий</span>

      <Table data={formattedWithdrawals} columns={withdrawalsColumns} />

      <Pagination
        withPerPage={true}
        lastPage={response?.data?.withdrawals?.last_page}
        currentPage={response?.data?.withdrawals?.current_page}
        perPage={perPage}
        onPageChange={setPage}
        onPerPageChange={setPerPage}
      />
    </StyledTab>
  );
};

export default Deposit;
