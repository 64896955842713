import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers) => {
      if (window.localStorage.token) {
        headers.set("Authorization", window.localStorage.token as string);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginUserProps>({
      query: (body) => ({
        url: `/auth/login`,
        method: "POST",
        body,
      }),
    }),

    registration: builder.mutation<RegistrationResponse, RegisterUserProps>({
      query: (body) => ({
        url: `/auth/register`,
        method: "POST",
        body,
      }),
    }),

    sendOtp: builder.mutation<RegistrationResponse, { email: string }>({
      query: (body) => ({
        url: `/auth/password/send`,
        method: "POST",
        body,
      }),
    }),

    verifyOtp: builder.mutation<
      RegistrationResponse,
      { email: string; code: string }
    >({
      query: (body) => ({
        url: `/auth/password/check`,
        method: "POST",
        body,
      }),
    }),

    resetPassword: builder.mutation<
      RegistrationResponse,
      { email: string; code: string; password: string }
    >({
      query: (body) => ({
        url: `/auth/password/reset`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRegistrationMutation,
  useSendOtpMutation,
  useVerifyOtpMutation,
  useResetPasswordMutation,
} = authApi;
