import moment from "moment";
import { useEffect, useState } from "react";
import { useGetAllUsersQuery } from "redux/api/adminApi";
import { getFormattedData } from "utils/helpers";
import { Pagination, Table } from "../../components";

const columns = [
  { field: "full_name", title: "Фамилия и Имя" },
  { field: "date_of_birth", title: "Дата рождения" },
  { field: "email", title: "Email" },
  { field: "phone", title: "Номер телефона" },
  { field: "created_at", title: "Дата регистрации" },
];

const ActiveUsers = () => {
  const [clients, setClients] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const { data: clientsData, isFetching } = useGetAllUsersQuery({
    page,
    perPage,
  });

  useEffect(() => {
    if (!!clientsData) {
      const tempClients = clientsData.data.map(
        ({
          id,
          first_name,
          last_name,
          date_of_birth,
          email,
          phone,
          created_at,
        }) => ({
          id,
          full_name: `${last_name ? last_name : ""}${
            first_name ? " " + first_name : ""
          }`,
          date_of_birth: date_of_birth ? date_of_birth : "--",
          email,
          phone: phone ? phone : "--",
          created_at: getFormattedData(created_at),
        })
      );

      setClients(tempClients);
    }
  }, [clientsData]);

  return (
    <div>
      {isFetching ? (
        <div>Loading...</div>
      ) : (
        <>
          <Table data={clients} columns={columns} />

          <Pagination
            withPerPage={true}
            lastPage={clientsData?.last_page || 0}
            currentPage={clientsData?.current_page || 0}
            perPage={perPage}
            onPageChange={setPage}
            onPerPageChange={setPerPage}
          />
        </>
      )}
    </div>
  );
};

export default ActiveUsers;
