type UserInfoProps = {
  user: User;
};

const UserInfo: React.FC<UserInfoProps> = ({ user }) => {
  return (
    <div>
      <div>
        <b>Имя:</b>{" "}
        <span>
          {`${user.last_name ? user.last_name : ""}${
            user.first_name ? " " + user.first_name : ""
          }` || "----"}
        </span>
      </div>
      <div>
        <b>Почта:</b> <span>{user.email}</span>
      </div>
      <div>
        <b>ID:</b> <span>{user.id}</span>
      </div>
    </div>
  );
};

export default UserInfo;
