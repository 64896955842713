import {
  StyledProfile,
  UserInformationWrapper,
  InputBlock,
  InputLabel,
  CardContentSmall,
  CardContentLarge,
  UserSecurityWrapper,
  ContentInformationWrapper,
  StyledDateInput,
  InputFilesWrapper,
  DocumentsWrapper,
  StyledFormsWrapper,
  ErrorYearMessage,
} from "./styles";
// components
import { UserInfo } from "./components";
import {
  CurrencyCheckbox,
  FileInput,
  Input,
  SelectInput,
  Button,
  PhoneInput,
} from "components/index";
import { useAppDispatch, useAppSelector, useTranslate } from "redux/hooks";
import { ReactComponent as UserIcon } from "assets/userIcon.svg";
import { ReactComponent as CheckIcon } from "assets/checkIcon.svg";
import { ReactComponent as EyeIcon } from "assets/eyeIcon.svg";
import buttonBackground from "assets/buttonBackground.png";
import { ReactComponent as ShieldIcon } from "assets/shieldIcon.svg";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import {
  useUpdatePasswordMutation,
  useUpdateUserInfoMutation,
  useUpdateKYCMutation,
} from "redux/api/userApi";
import { useEffect, useState } from "react";
import {
  togglePasswordModal,
  toggleVerificationModal,
} from "redux/stores/userSlice";

const currencies = ["$"];

const Profile = () => {
  const { userData } = useAppSelector("user");
  const [updateUser] = useUpdateUserInfoMutation();
  const [updatePassword] = useUpdatePasswordMutation();
  const [updateKYC] = useUpdateKYCMutation();
  const [type, changeType] = useState("password");
  const translate = useTranslate();
  const dispatch = useAppDispatch();

  const userInfoMethods = useForm<UserInformationForm>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {
      day: "",
      month: "",
      year: "",
      first_name: "",
      last_name: "",
      phone: "",
    },
  });

  const accountInfoForm = useForm<UserInformation>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {
      current_password: "",
      new_password: "",
      document_type: "passport",
      document1: null,
      document2: null,
    },
  });

  useEffect(() => {
    if (userData) {
      const { date_of_birth, phone, last_name, first_name } = userData;

      if (date_of_birth) {
        let [date, _] = date_of_birth.split(" ");

        const [year, month, day] = date.split("-");

        if (day.includes("00:")) {
          day.replace(" 00:00:00", "");
        }

        userInfoMethods.setValue("year", year);
        userInfoMethods.setValue("month", month);
        userInfoMethods.setValue("day", day);
      }

      userInfoMethods.setValue("last_name", last_name || "");
      userInfoMethods.setValue("first_name", first_name || "");
      userInfoMethods.setValue("phone", phone || "");
    }
  }, [userData, userInfoMethods.setValue]);

  const isValidValue = (name: "day" | "month" | "year", value: string) => {
    if (userInfoMethods.formState.errors.year) {
      userInfoMethods.clearErrors("year");
    }

    if (value === "") return true;

    if (name === "day") {
      return value.length <= 2 && +value !== 0 && +value <= 31;
    }

    if (name === "month") {
      return value.length <= 2 && +value !== 0 && +value <= 12;
    }

    if (name === "year") {
      return value.length <= 4 && +value !== 0 && +value <= moment().year();
    }

    return true;
  };

  const submitUserInfo = async (data: UserInformationForm) => {
    const { day, month, year, ...otherUserInfo } = data;

    if (year && month && day) {
      const bd = moment()
        .set("year", +year)
        .set("month", +month)
        .set("day", +day)
        .fromNow();

      const years = bd.split(" ")[0];

      if (+years >= 18) {
        await updateUser({
          date_of_birth: `${year}-${month}-${day}`,
          ...otherUserInfo,
        });
      } else {
        userInfoMethods.setError("year", {
          message: translate("BD_VALIDATION"),
        });
      }
    }
  };

  const submitAccountInfo = async (data: UserInformation) => {
    const {
      current_password,
      new_password,
      document1,
      document2,
      document_type,
    } = data;

    if (current_password !== "" && new_password !== "") {
      await updatePassword({
        old_password: current_password,
        new_password,
      })
        .unwrap()
        .then((data) => {
          if (data.status === "error") {
            dispatch(
              togglePasswordModal(
                data.message?.includes("New password cannot be the same")
                  ? "invalid"
                  : "failed"
              )
            );
          }
          if (data.status === "success") {
            dispatch(togglePasswordModal("success"));
          }
        });
    }

    if (
      userData &&
      userData.kyc === null &&
      document_type &&
      document1 &&
      document2
    ) {
      const formData = new FormData();

      formData.append("document_type", document_type);
      formData.append("document[0]", document1);
      formData.append("document[1]", document2);

      await updateKYC(formData)
        .unwrap()
        .then((data) => {
          if (data.code === 400) {
            dispatch(toggleVerificationModal("failed"));

            return;
          }

          dispatch(toggleVerificationModal("success"));
        });
    }
  };

  const data = [
    { value: "passport", label: translate("PASSPORT") },
    { value: "drivers_license", label: translate("DRIVERS_LICENSE") },
    { value: "national_id", label: translate("NATIONAL_ID") },
  ];

  const togglePasswordInputType = () => {
    changeType(type === "text" ? "password" : "text");
  };

  const clearErrorHandler = (key: keyof UserInformation) => {
    setTimeout(() => {
      accountInfoForm.clearErrors(key);
      accountInfoForm.setValue(key, null);
    }, 2000);
  };

  const validateFileSize = (value?: { size: number }) => {
    if (!value) {
      return true;
    } else {
      return value.size / 1024 < 1024 * 5 || "File size must be less than 5 MB";
    }
  };

  return (
    <StyledProfile>
      <ContentInformationWrapper>
        <StyledFormsWrapper>
          <UserInformationWrapper
            onSubmit={userInfoMethods.handleSubmit(submitUserInfo)}
          >
            <UserInfo />

            <CardContentSmall>
              <InputBlock>
                <InputLabel>{translate("DATE_OF_BIRTH")}:</InputLabel>

                <StyledDateInput>
                  <Controller
                    control={userInfoMethods.control}
                    name="day"
                    render={({ field: { value, name, onChange } }) => (
                      <Input
                        variant="dark"
                        placeholder={translate("DAY")}
                        name={name}
                        value={value}
                        inputType="text"
                        handleChange={(inputValue) => {
                          const newValue = isValidValue(name, inputValue)
                            ? inputValue
                            : value;

                          onChange(newValue);
                        }}
                      />
                    )}
                  />

                  <Controller
                    control={userInfoMethods.control}
                    name="month"
                    render={({ field: { value, name, onChange } }) => (
                      <Input
                        variant="dark"
                        placeholder={translate("MONTH")}
                        name={name}
                        value={value}
                        inputType="text"
                        handleChange={(inputValue) => {
                          const newValue = isValidValue(name, inputValue)
                            ? inputValue
                            : value;

                          onChange(newValue);
                        }}
                      />
                    )}
                  />

                  <Controller
                    control={userInfoMethods.control}
                    name="year"
                    rules={{
                      validate: {
                        invalid: (value) =>
                          +value >= 1900 || translate("BD_VALIDATION"),
                      },
                    }}
                    render={({ field: { value, name, onChange } }) => (
                      <Input
                        variant="dark"
                        placeholder={translate("YEAR")}
                        name={name}
                        value={value}
                        inputType="text"
                        handleChange={(inputValue) => {
                          const newValue = isValidValue(name, inputValue)
                            ? inputValue
                            : value;

                          onChange(newValue);
                        }}
                      />
                    )}
                  />
                </StyledDateInput>

                {userInfoMethods.formState.errors.year && (
                  <ErrorYearMessage>
                    {userInfoMethods.formState.errors.year?.message}
                  </ErrorYearMessage>
                )}
              </InputBlock>

              <InputBlock>
                <InputLabel>{`${translate(
                  "PERSONAL_INFORMATION"
                )}:`}</InputLabel>

                <Controller
                  control={userInfoMethods.control}
                  name="first_name"
                  render={({ field: { value, name, onChange } }) => (
                    <Input
                      variant="dark"
                      leftIcon={<UserIcon />}
                      name={name}
                      value={value}
                      inputType="text"
                      handleChange={onChange}
                      placeholder={translate("FIRST_NAME")}
                      rightIcon={value ? <CheckIcon /> : undefined}
                    />
                  )}
                />

                <Controller
                  control={userInfoMethods.control}
                  name="last_name"
                  render={({ field: { value, name, onChange } }) => (
                    <Input
                      variant="dark"
                      leftIcon={<UserIcon />}
                      name={name}
                      value={value}
                      inputType="text"
                      handleChange={onChange}
                      rightIcon={value ? <CheckIcon /> : undefined}
                      placeholder={translate("LAST_NAME")}
                    />
                  )}
                />
              </InputBlock>

              <InputBlock>
                <InputLabel>{`${translate("PHONE_NUMBER")}:`}</InputLabel>

                <Controller
                  control={userInfoMethods.control}
                  name="phone"
                  render={({ field: { value, name, onChange } }) => (
                    <PhoneInput
                      name={name}
                      value={value}
                      handleChange={onChange}
                    />
                  )}
                />
              </InputBlock>

              <Button
                background={`url(${buttonBackground})`}
                width="100%"
                height="70px"
                padding="16px"
                text={translate("SAVE")}
              />
            </CardContentSmall>
          </UserInformationWrapper>

          <UserSecurityWrapper
            onSubmit={accountInfoForm.handleSubmit(submitAccountInfo)}
          >
            <CardContentLarge>
              <InputLabel>{translate("CONTACT_DETAILS")}</InputLabel>

              <Input
                variant="dark"
                leftIcon={<UserIcon />}
                name="email"
                value={userData?.email || ""}
                inputType="email"
                handleChange={() => {}}
                rightIcon={<CheckIcon />}
                inputProps={{
                  readOnly: true,
                }}
              />

              <InputLabel>{translate("ID_CONFIRMATION")}</InputLabel>

              <DocumentsWrapper>
                <Controller
                  control={accountInfoForm.control}
                  name="document_type"
                  render={({ field: { onChange, value } }) => (
                    <SelectInput
                      icon={<ShieldIcon />}
                      value={value}
                      data={data}
                      handleChange={onChange}
                    />
                  )}
                />

                <InputFilesWrapper>
                  <Controller
                    control={accountInfoForm.control}
                    rules={{
                      required: false,
                      validate: {
                        negative: (value: any) => validateFileSize(value),
                      },
                    }}
                    name="document1"
                    render={({
                      field: { onChange, name, value },
                      fieldState: { error },
                    }) => {
                      if (error) {
                        clearErrorHandler("document1");
                      }

                      return (
                        <FileInput
                          error={error?.message}
                          isDocumentLoaded={userData?.kyc}
                          placeholder={translate("MAX_FILE_SIZE")}
                          value={value}
                          name={name}
                          handleChange={onChange}
                        />
                      );
                    }}
                  />

                  <Controller
                    control={accountInfoForm.control}
                    name="document2"
                    rules={{
                      required: false,
                      validate: {
                        negative: (value: any) => validateFileSize(value),
                      },
                    }}
                    render={({
                      field: { onChange, name, value },
                      fieldState: { error },
                    }) => {
                      if (error) {
                        clearErrorHandler("document2");
                      }

                      return (
                        <FileInput
                          error={error?.message}
                          isDocumentLoaded={userData?.kyc}
                          placeholder={translate("BACK_SIDE")}
                          placeholderWithIcon
                          value={value}
                          name={name}
                          handleChange={onChange}
                        />
                      );
                    }}
                  />
                </InputFilesWrapper>
              </DocumentsWrapper>

              <InputLabel>{translate("SECURITY")}</InputLabel>

              <InputBlock>
                <Controller
                  control={accountInfoForm.control}
                  name="current_password"
                  rules={{
                    required: false,
                    validate: {
                      length: (value) =>
                        (value.length >= 8 && value.length <= 30) ||
                        value.length === 0 ||
                        translate("ERROR_LENGTH_FIELD"),
                    },
                  }}
                  render={({
                    field: { onChange, name, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      variant="dark"
                      leftIcon={<UserIcon />}
                      name={name}
                      value={value}
                      inputType={type}
                      error={error?.message}
                      handleChange={onChange}
                      placeholder={translate("OLD_PASSWORD")}
                      rightIcon={<EyeIcon onClick={togglePasswordInputType} />}
                    />
                  )}
                />

                <Controller
                  control={accountInfoForm.control}
                  name="new_password"
                  rules={{
                    required: false,
                    validate: {
                      length: (value) =>
                        (value.length >= 8 && value.length <= 18) ||
                        value.length === 0 ||
                        translate("ERROR_LENGTH_FIELD"),
                    },
                  }}
                  render={({
                    field: { onChange, name, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      variant="dark"
                      leftIcon={<UserIcon />}
                      name={name}
                      value={value}
                      inputType={type}
                      error={error?.message}
                      handleChange={onChange}
                      placeholder={translate("NEW_PASSWORD")}
                      rightIcon={<EyeIcon onClick={togglePasswordInputType} />}
                    />
                  )}
                />

                <InputBlock>
                  <InputLabel>{`${translate("ACCOUNT_CURRENCY")}:`}</InputLabel>

                  <CurrencyCheckbox currencies={currencies} />
                </InputBlock>
              </InputBlock>

              <Button
                background={`url(${buttonBackground})`}
                width="100%"
                height="70px"
                padding="16px"
                text={translate("SAVE")}
              />
            </CardContentLarge>
          </UserSecurityWrapper>
        </StyledFormsWrapper>
      </ContentInformationWrapper>
    </StyledProfile>
  );
};

export default Profile;
