import { LanguagesDictionary } from "dictionary";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "./store";

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector = <T extends keyof RootState>(
  key: T
): RootState[T] => {
  const partialStore = useSelector((state: RootState) => state[key]);

  return partialStore;
};

export const useTranslate = (): ((
  key: keyof typeof LanguagesDictionary["RU"]
) => string) => {
  const language = useSelector((state: RootState) => state.user.language);

  const translate = (key: keyof typeof LanguagesDictionary["RU"]): string => {
    return LanguagesDictionary[language][key];
  };

  return translate;
};

export const useCountdown = (startCounter = 60, loop = false) => {
  const [countDown, setCountDown] = useState(startCounter);

  useEffect(() => {
    if (loop && countDown === -1) {
      setCountDown(startCounter);
      return;
    }

    const interval = setTimeout(() => {
      setCountDown(countDown - 1);
    }, 1000);

    return () => clearTimeout(interval);
  }, [countDown, setCountDown]);

  return countDown;
};
