import styled from "styled-components";
import depositMobileBackground from "assets/depositMobileFormBackground.png";
import depositDesktopBackground from "assets/depositDesktopBackground.png";
import depositTabBackground from "assets/depositTabBackground.png";

export const DepositWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "25px",

  "@media screen and (max-width: 1200px)": {
    alignItems: "center",
  },
});

export const FormWrapper = styled.div({
  width: "390px",
  height: "455px",
  background: `url(${depositDesktopBackground})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "-30px 0px",
  padding: "23px 45px 0px",
  backgroundSize: "cover",

  "@media screen and (max-width: 1200px)": {
    background: `url(${depositTabBackground})`,
    backgroundPosition: "-15px 0px",
    width: "445px",
  },

  "@media screen and (max-width: 1000px)": {
    background: `url(${depositMobileBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0px 0px",
    width: "375px",
    height: "510px",
    padding: "20px 20px 0px 25px",
  },
});
