import { Alert } from "react-bootstrap";
import styled from "styled-components";

export const StyledPaymentMethods = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  position: "relative",
});

export const InputWrapper = styled.div({
  display: "flex",
  gap: "15px",
  alignItems: "flex-end",
});

export const StyledAlerts = styled(Alert)({
  position: "absolute",
  top: 0,
  right: 0,
});
