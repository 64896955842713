import {
  StyledNotification,
  IconWrapper,
  NotificationCounter,
  NotificationList,
  Notification,
  NotificationImage,
  Indicator,
  EmptyState,
} from "./styles";
import { ReactComponent as BellIcon } from "assets/bellIcon.svg";
import {
  useDeleteAllNotificationsMutation,
  useGetNotificationsQuery,
} from "redux/api/userApi";
import { useOutsideClick } from "utils/hooks";
import { useTranslate } from "redux/hooks";

const Notifications = () => {
  const { ref, isVisible, setIsVisible } = useOutsideClick(false);
  const translate = useTranslate();
  const { data, isLoading } = useGetNotificationsQuery(null, {
    pollingInterval: 120000,
    refetchOnReconnect: true,
  });
  const [deleteNotifications] = useDeleteAllNotificationsMutation();

  return (
    <StyledNotification
      isActive={isVisible}
      ref={ref}
      onClick={() => setIsVisible(!isVisible)}
    >
      <span>{translate("NOTIFICATIONS")}</span>

      <IconWrapper>
        <BellIcon />
      </IconWrapper>

      <NotificationCounter children={isLoading ? "" : data?.data?.length} />

      {/* @ts-ignore */}
      {isVisible && (
        <NotificationList>
          {data?.data.length !== 0 && (
            <button onClick={() => deleteNotifications(null)}>
              {translate("DELETE_ALL")}
            </button>
          )}

          {data?.data.length === 0 ? (
            <EmptyState>{translate("NOTIFICATION_EMPTY_STATE")}</EmptyState>
          ) : (
            data?.data.map(({ type, message, parameter }) => (
              <Notification>
                <NotificationImage type={type}>
                  <Indicator />
                </NotificationImage>
                {message} {parameter || ""}
              </Notification>
            ))
          )}
        </NotificationList>
      )}
    </StyledNotification>
  );
};

export default Notifications;
