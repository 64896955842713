import { Input } from "components/index";
import {
  ContentWrapper,
  CryptoWallet,
  StyledWalletDetailsPage,
  CryptoWalletPlus,
} from "./styles";
import { ReactComponent as Tether } from "assets/usdt.svg";
import { ReactComponent as PrivateBank } from "assets/privateBankIcon.svg";
import { ReactComponent as CheckIcon } from "assets/checkIcon.svg";
import { ReactComponent as PlusIcon } from "assets/plusIcon.svg";

const WalletDetails = () => (
  <StyledWalletDetailsPage>
    <ContentWrapper>
      <CryptoWallet>
        <Input
          leftIcon={<Tether />}
          rightIcon={<CheckIcon />}
          variant="dark"
          label="USDT TRC-20"
          name={""}
          value={"dawdawd awd wd24r34 r"}
          inputType={undefined}
          handleChange={() => {}}
        />
      </CryptoWallet>

      <CryptoWallet>
        <Input
          leftIcon={<PrivateBank />}
          rightIcon={<CheckIcon />}
          variant="dark"
          label="Приват24"
          name={""}
          value={" 43t424r2f4f2e f"}
          inputType={undefined}
          handleChange={() => {}}
        />
      </CryptoWallet>

      <CryptoWalletPlus>
        <PlusIcon cursor="pointer" />
      </CryptoWalletPlus>
    </ContentWrapper>
  </StyledWalletDetailsPage>
);

export default WalletDetails;
