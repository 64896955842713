import styled from "styled-components";
import inputBackground from "assets/inputBackground.png";
import inputMiddleBackground from "assets/inputMiddleBackground.png";

export const StyledInputWrapper = styled.div({
  width: "100%",
  display: "flex",
  flexDirection: "column",
});

export const StyledInput = styled.div<{ error: any }>(({ error }) => ({
  width: "100%",
  flexDirection: "column",
  display: "flex",
  border: error ? "1px solid rgba(255, 65, 65, 0.65)" : "none",
  borderRadius: "25px",

  "@media screen and (min-width: 1000px)": {
    borderRadius: "20px",
  },
}));

export const InputLabel = styled.span({
  fontSize: "16px",
  lineHeight: "24px",
  color: "white",
  fontWeight: 400,
  marginBottom: "15px",
  fontFamily: "Stolzl",
});

export const InputWrapper = styled.div({
  height: "52px",
  background: `url(${inputBackground})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  padding: "0px 15px",
  fontSize: "20px",
  lineHeight: "28px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  outline: "none",
  borderRadius: "20px",

  "& svg": {
    width: "25px",
    height: "25px",
  },

  "& input": {
    background: "transparent",
    width: "100%",
    height: "100%",
    outline: "none",
    fontWeight: 400,
    fontSize: "20px",
    cursor: "pointer",
    margin: "0px 20px 0px 10px",
    border: "none",
    color: "white",

    "&::placeholder": {
      color: "#536886",
    },
  },

  "@media screen and (max-width: 1200px)": {
    background: `url(${inputMiddleBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },

  "@media screen and (max-width: 1000px)": {
    height: "56px",
    width: "100%",
  },
});

export const ErrorMessage = styled.span({
  color: "#FF3333A6",
  margin: "0px auto",
});
