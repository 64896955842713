import { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useNavigate, useParams } from "react-router-dom";
// import { useGetStatisticsQuery } from "redux/api/adminApi";
import { ActiveUsers, NewUsers, Bets, Deposit, Withdrawal } from "./components";

const Reports = () => {
  const { tab = "active_users" } = useParams();
  const [selectedTab, setTab] = useState(tab);
  const navigate = useNavigate();
  // const { data: response } = useGetStatisticsQuery({ page: 1, perPage: 15 });

  const handleSelect = (tab: string | null) => {
    setTab(tab || "active_users");
    navigate(`/Sl6ufptgfk/reports/${tab}`);
  };

  return (
    <Tabs activeKey={selectedTab} onSelect={handleSelect} className="mb-3">
      <Tab eventKey="active_users" title="Активные пользователи">
        <ActiveUsers />
      </Tab>

      <Tab eventKey="new_users" title="Новые пользователи">
        <NewUsers />
      </Tab>

      <Tab eventKey="bets" title="Ставки">
        <Bets />
      </Tab>

      <Tab eventKey="deposit" title="Депозит">
        <Deposit />
      </Tab>

      <Tab eventKey="withdrawal" title="Снятие">
        <Withdrawal />
      </Tab>
    </Tabs>
  );
};

export default Reports;
