import { Pagination, Table } from "../components";
import { useState } from "react";
import { useGetAllBetsQuery } from "redux/api/adminApi";
import { StyledBetsPage } from "./styles";

const columns = [
  {
    field: "id",
    title: "ID",
  },
  {
    field: "crash_id",
    title: "Action ID",
  },
  {
    field: "rate_final",
    title: "Final rate",
  },
  {
    field: "status",
    title: "Status",
  },
  {
    title: "Auto Rate",
    children: [
      { title: "Auto Rate", field: "rate_auto" },
      { title: "is Auto Rate taken", field: "taked_rate_auto" },
      { title: "Half auto rate", field: "half_rate_auto" },
      { title: "is Half auto rate taken", field: "taked_half_rate_auto" },
    ],
  },
  {
    field: "user",
    title: "User",
    children: [
      { title: "ID", field: "id" },
      { title: "Email", field: "email" },

      { title: "First name", field: "first_name" },
      { title: "Fast name", field: "last_name" },
    ],
  },
  {
    field: "profit",
    title: "Profit",
  },
];

const Bets = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(15);
  const { data, isLoading } = useGetAllBetsQuery({ page, perPage });

  if (isLoading) return <div>Загрузка...</div>;

  if (data.data.length === 0) return <div>Нет данных</div>;

  const { data: bets, last_page, current_page } = data;

  return (
    <StyledBetsPage>
      <Table data={bets} columns={columns} />

      <Pagination
        withPerPage={true}
        lastPage={last_page}
        currentPage={current_page}
        perPage={perPage}
        onPageChange={setPage}
        onPerPageChange={setPerPage}
      />
    </StyledBetsPage>
  );
};

export default Bets;
