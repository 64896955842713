import styled from "styled-components";
import depositModalBackground from "assets/depositModalBackground.png";
import depositModalMobileBackground from "assets/depositModalMobileBackground.png";
import { ReactComponent as CloseIcon } from "assets/closeIcon.svg";

export const StyledWithdrawModal = styled.div({
  position: "fixed",
  width: "100vw",
  height: "100vh",
  backdropFilter: "blur(5px)",
  zIndex: 4,
  top: 0,
  left: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const WithdrawModalBody = styled.div({
  width: "500px",
  height: "450px",
  background: `url(${depositModalBackground})`,
  backgroundSize: "contain",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  padding: "60px 60px 40px",
  fontFamily: "Benzin",
  color: "white",
  textAlign: "center",
  fontSize: "35px",

  "@media screen and (max-width: 725px)": {
    width: "375px",
    height: "300px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    padding: "20px 20px 15px",
    justifyContent: "flex-start",
    background: `url(${depositModalMobileBackground})`,
  },
});

export const StyledCloseIcon = styled(CloseIcon)({
  cursor: "pointer",
  width: "30px",
  height: "30px",
  alignSelf: "flex-end",
  marginTop: "-30px",

  "@media screen and (max-width: 725px)": {
    width: "15px",
    height: "15px",
    marginTop: "0px",
  },
});
