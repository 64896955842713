import styled from "styled-components";
import depositModalBackground from "assets/depositModalBackground.png";
import depositModalMobileBackground from "assets/depositModalMobileBackground.png";
import depositDetailsMobileBackground from "assets/depositDetailsMobileBackground.png";
import depositDetailsBackground from "assets/depositDetailsBackground.png";
import background from "assets/fileBackground.png";
import activeBackground from "assets/activeFileBackground.png";
import walletBackground from "assets/inputBackground.png";
import { ReactComponent as CloseIcon } from "assets/closeIcon.svg";

export const StyledWalletDetailsModal = styled.div({
  position: "fixed",
  width: "100vw",
  height: "100vh",
  backdropFilter: "blur(5px)",
  zIndex: 4,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const WalletDetailsModalBody = styled.div({
  width: "730px",
  height: "660px",
  background: `url(${depositModalBackground})`,
  backgroundSize: "cover",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  padding: "60px 60px 40px",

  "@media screen and (max-width: 725px)": {
    width: "375px",
    height: "655px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    padding: "20px 20px 15px",
    justifyContent: "flex-start",
    background: `url(${depositModalMobileBackground})`,
  },
});

export const StyledCloseIcon = styled(CloseIcon)({
  cursor: "pointer",
  width: "30px",
  height: "30px",
  alignSelf: "flex-end",
  marginTop: "-30px",

  "@media screen and (max-width: 725px)": {
    width: "15px",
    height: "15px",
    marginTop: "0px",
  },
});

export const ModalTitle = styled.p({
  fontFamily: "Stolzl",
  textAlign: "center",
  fontSize: "31px",
  color: "white",
  textTransform: "uppercase",
  fontWeight: 900,
  marginBottom: "20px",

  "@media screen and (max-width: 725px)": {
    fontSize: "20px",
    marginBottom: "20px",
  },
});

export const CryptosWrapper = styled.div({
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  gap: "10px",
  marginBottom: "5px",

  "@media screen and (max-width: 725px)": {
    flexWrap: "wrap",
    marginBottom: "10px",
  },
});

export const CryptoCoin = styled.div<{ isActive: boolean }>(({ isActive }) => ({
  width: "200px",
  height: "90px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  background: `url(${isActive ? activeBackground : background})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  gap: "10px",
  cursor: "pointer",

  "@media screen and (max-width: 725px)": {
    width: "48%",
    height: "73px",
    gap: "5px",
  },
}));

export const CryptoCoinInfo = styled.div({
  display: "flex",
  gap: "8px",
  padding: "0px 20px",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  fontFamily: "Stolzl",

  "@media screen and (max-width: 725px)": {
    gap: "5px",
    padding: "0px 10px",
  },
});

export const CryptoName = styled.span({
  fontSize: "14px",
  lineHeight: "19px",
  color: "white",
  fontFamily: "Stolzl",
});

export const AttentionText = styled.span({
  fontFamily: "Stolzl",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "25px",
  textAlign: "center",
  color: "#C3CEDB",
  marginBottom: "10px",

  "@media screen and (max-width: 725px)": {
    width: "315px",
    fontSize: "12px",
    lineHeight: "20px",
    marginBottom: "0px",
  },
});

export const DetailsWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
  height: "185px",
  background: `url(${depositDetailsBackground})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "0px -10px",
  padding: "0px 35px",
  marginBottom: "10px",

  "@media screen and (max-width: 725px)": {
    minHeight: "175px",
    background: `url(${depositDetailsMobileBackground})`,
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    padding: "0px 25px",
  },
});

export const DepositDetails = styled.div({
  display: "flex",
  alignItems: "center",
  width: "100%",
  gap: "20px",

  "@media screen and (max-width: 725px)": {
    height: "115px",
  },
});

export const QRcodeWrapper = styled.div({
  display: "flex",
  minWidth: "150px",
  height: "150px",
  padding: "10px",
  background: "rgba(27, 41, 66,0.8)",
  borderRadius: "5px",

  "@media screen and (max-width: 725px)": {
    minWidth: "95px",
    height: "95px",
  },
});

export const DepositInfo = styled.div({
  display: "flex",
  height: "150px",
  flexDirection: "column",
  justifyContent: "space-between",

  "@media screen and (max-width: 725px)": {
    justifyContent: "flex-start",
    height: "auto",
  },
});

export const CryptoTitle = styled.p({
  fontFamily: "Benzin",
  fontSize: "19px",
  lineHeight: "41px",
  color: "#FFFFFF",
  margin: "0px",

  "@media screen and (max-width: 725px)": {
    fontSize: "18px",
  },
});

export const HelpText = styled.span({
  fontFamily: "Stolzl",
  fontSize: "13px",
  lineHeight: "22px",
  color: "#FFFFFF",
  fontWeight: 300,

  "@media screen and (max-width: 725px)": {
    color: "#7F94AF",
  },
});

export const Seconds = styled(HelpText)({
  color: "rgba(116, 237, 143, 1)",
});

export const InputsWrapper = styled.div<{ device: "desktop" | "mobile" }>(
  ({ device }) => ({
    display: device === "desktop" ? "grid" : "none",
    gridTemplateColumns: "125px 30px 215px",
    color: "white",
    alignItems: "center",

    "& span": {
      textAlign: "center",
    },

    "@media screen and (max-width: 725px)": {
      display: device === "mobile" ? "grid" : "none",
      gridTemplateColumns: "95px 20px 170px",
    },
  })
);

export const StyledDepositInput = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  height: "45px",
  background: "rgba(27, 41, 66,0.8)",
  borderRadius: "5px",
  fontFamily: "Stolzl",
  fontWeight: 100,
  padding: "0px 10px 0px 5px",
  gap: "5px",

  "& input": {
    width: "100%",
    textAlign: "center",
    background: "transparent",
    border: "none",
    outline: "none",
    color: "white",

    "&[readonly]": {
      color: "#6D7E9B",
    },
  },

  "& p": {
    width: "100%",
    margin: "0px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    color: "#6D7E9B",
    userSelect: "none",
  },

  "@media screen and (max-width: 725px)": {
    height: "35px",
    fontSize: "12px",
  },
});

export const CopyBoard = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "25px",
  minWidth: "25px",
  height: "25px",
  borderRadius: "5px",
  background: "#2F435E",
  cursor: "pointer",
});

export const WalletBackground = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "90%",
  height: "60px",
  background: `url(${walletBackground})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "0px",
  borderRadius: "50px",
  padding: "0px 20px",
  marginBottom: "5px",
  color: "white",
  fontFamily: "Stolzl",

  "& span": {
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

export const ErrorMessage = styled.span({
  color: "#fc6f6f",
  fontFamily: "Stolzl",
});

export const ActualPrice = styled.span<{ device: "desktop" | "mobile" }>(
  ({ device }) => ({
    fontFamily: "Stolzl",
    fontWeight: 400,
    fontSize: "14px",
    width: "215px",
    display: device === "desktop" ? "inline" : "none",
    color: "white",

    "@media screen and (max-width: 725px)": {
      display: device === "mobile" ? "block" : "none",
    },
  })
);
