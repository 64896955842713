import styled from "styled-components";
import background from "assets/inputBackground.png";

export const StyledInputWrapper = styled.div<{ transparent: boolean }>(
  ({ transparent }) => ({
    display: "flex",
    flexDirection: "column",
    position: "relative",
    fontFamily: "Stolzl",
    background: transparent ? "transparent" : `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    borderRadius: "25px",
  })
);

export const SelectedValue = styled.div({
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "20px",
  color: "#FFF",
});

export const StyledSelectInput = styled.div({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  height: "56px",
  width: "100%",
  padding: "0px 25px",
  borderRadius: "12px",
  cursor: "pointer",
  color: "#fff",

  "@media screen and (max-width: 1200px)": {
    height: "56px",
  },

  "@media screen and (max-width: 1000px)": {
    height: "56px",
  },
});

export const IconWrapper = styled.div({
  marginRight: "20px",
});

export const SelectItemsWrapper = styled.div<{ isOpen: boolean }>(
  ({ isOpen }) => ({
    display: isOpen ? "flex" : "none",
    flexDirection: "column",
    cursor: "pointer",
    position: "absolute",
    width: "100%",
    minWidth: "100px",
    height: "max-content",
    left: "0px",
    top: "60px",
    background: "rgb(66, 67, 130)",
    padding: "10px 20px",
    borderRadius: "12px",
    transition: "height 1s",
    zIndex: 1,
  })
);

export const SelectItem = styled.div({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  color: "#fff",
  height: "40px",
  alignItems: "center",
  gap: "10px",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  transition: "fontWeight .2s",

  "&:hover": {
    fontWeight: 600,
  },
});
