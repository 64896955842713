import { CurrencyCheckboxItem, StyledCurrencyCheckbox } from "./styles";
import checkIcon from "assets/whiteCheckIcon.png";

type CurrencyCheckboxProps = {
  currencies: string[];
};

const CurrencyCheckbox: React.FC<CurrencyCheckboxProps> = ({ currencies }) => {
  return (
    <StyledCurrencyCheckbox>
      {currencies.map((currency) => (
        <CurrencyCheckboxItem key={currency} isChecked={currency === "$"}>
          {currency}

          {currency === "$" && <img src={checkIcon} alt="checkIcon" />}
        </CurrencyCheckboxItem>
      ))}
    </StyledCurrencyCheckbox>
  );
};

export default CurrencyCheckbox;
