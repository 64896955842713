import styled from "styled-components";

export const StyledWalletHistoryPage = styled.div({
  minWidth: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  padding: "0px 0px 30px",

  "@media screen and (max-width: 1200px)": {
    alignItems: "center",
  },
});

export const ContentWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "25px",

  "@media screen and (max-width: 1000px)": {
    alignItems: "center",
  },
});
