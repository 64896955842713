export const DISABLED_COUNTRIES = [
  { name: "Afghanistan", code: "AF" },
  { name: "American Samoa", code: "AS" },
  { name: "Bahamas", code: "BS" },
  { name: "Belarus", code: "BY" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Crimea", code: "" },
  { name: "Cuba", code: "CU" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Ethiopia", code: "ET" },
  { name: "Ghana", code: "GH" },
  { name: "Guam", code: "GU" },
  { name: "Guyana", code: "GY" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Nigeria", code: "NG" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Pakistan", code: "PK" },
  { name: "Panama", code: "PA" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Samoa", code: "WS" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Sudan", code: "SD" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Russian Federation", code: "RU" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Uganda", code: "UG" },
  { name: "United States", code: "US" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Vanuatu", code: "VU" },
  { name: "Yemen", code: "YE" },
  { name: "Zimbabwe", code: "ZW" },
];
