import { useMemo, useState } from "react";
import AuthButton from "./AuthButton";
import AuthInput from "./AuthInput";
// styles
import { StyledLoginForm, LoginFormTitle, InputsContainer } from "./styles";
import { ReactComponent as LockIcon } from "assets/lockIcon.svg";
import { ReactComponent as EyeIcon } from "assets/eyeIcon.svg";
import { Controller, useForm } from "react-hook-form";
import { useResetPasswordMutation } from "redux/api/AuthApi";
import { useNavigate } from "react-router-dom";
import { useTranslate } from "redux/hooks";

const NewPassword = () => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const [type, setInputType] = useState("password");
  const { handleSubmit, control, getValues, watch } = useForm({
    mode: "onChange",
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const handleChangeInputType = () => {
    setInputType(type === "password" ? "text" : "password");
  };

  const submit = async (data: any) => {
    const email = window.localStorage.getItem("email") || "";
    const code = window.localStorage.getItem("code") || "";

    const response = await resetPassword({
      email,
      code,
      password: data.confirmPassword,
    }).unwrap();

    if (response.status === "success") {
      window.localStorage.removeItem("email");
      window.localStorage.removeItem("code");

      navigate("/");
    }
  };

  const isPasswordsEquals = useMemo(() => {
    const response = getValues(["password", "confirmPassword"]);

    return response[0] === response[1];
  }, [watch("confirmPassword"), getValues]);

  return (
    <StyledLoginForm>
      <LoginFormTitle>
        Придумайте <br /> новый пароль
      </LoginFormTitle>

      <StyledLoginForm onSubmit={handleSubmit(submit)}>
        <InputsContainer>
          <Controller
            control={control}
            name="password"
            rules={{
              required: true,
              validate: {
                length: (value) =>
                  (value.length >= 8 && value.length <= 18) ||
                  translate("ERROR_LENGTH_FIELD"),
              },
            }}
            render={({
              field: { name, value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <AuthInput
                placeholder="Новый пароль"
                name={name}
                value={value}
                inputType={type}
                handleBlur={onBlur}
                handleChange={onChange}
                leftIcon={<LockIcon />}
                error={error?.message}
                rightIcon={
                  <EyeIcon cursor="pointer" onClick={handleChangeInputType} />
                }
              />
            )}
          />

          <Controller
            control={control}
            name="confirmPassword"
            rules={{
              required: true,
              validate: {
                equals: (value, formValues) =>
                  value === formValues.password ||
                  translate("ERROR_EQUALS_PASSWORD"),
                length: (value) =>
                  (value.length >= 8 && value.length <= 18) ||
                  translate("ERROR_LENGTH_FIELD"),
              },
            }}
            render={({
              field: { name, value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <AuthInput
                placeholder="Подтвердить пароль"
                name={name}
                value={value}
                inputType={type}
                handleBlur={onBlur}
                handleChange={onChange}
                leftIcon={<LockIcon />}
                error={error?.message}
                rightIcon={
                  <EyeIcon cursor="pointer" onClick={handleChangeInputType} />
                }
              />
            )}
          />
        </InputsContainer>

        <AuthButton
          width="250px"
          disabled={!isPasswordsEquals || isLoading}
          onClick={handleSubmit(submit)}
          text="Изменить пароль"
        />
      </StyledLoginForm>
    </StyledLoginForm>
  );
};

export default NewPassword;
