import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
// styles
import {
  PictureViewerWrapper,
  StyledModalHeader,
  ImagesCounter,
} from "./styles";

type DocumentViewerProps = {
  document1: string;
  document2: string;
};

const DocumentsView: React.FC<DocumentViewerProps> = ({
  document1,
  document2,
}) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      {!!document1 && (
        <PictureViewerWrapper
          onClick={() => setShowModal(true)}
          backgroundImage={`${process.env.REACT_APP_BASE_URL_FOR_IMAGES}/uploads/documents/${document1}`}
        >
          {document1 && document2 ? (
            <ImagesCounter className="bg-primary text-white">2</ImagesCounter>
          ) : null}
        </PictureViewerWrapper>
      )}

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="example-custom-modal-styling-title"
        fullscreen
      >
        <StyledModalHeader closeButton />
        <Modal.Body>
          <Carousel interval={null}>
            {!!document1 && (
              <Carousel.Item>
                <PictureViewerWrapper
                  backgroundImage={`${process.env.REACT_APP_BASE_URL_FOR_IMAGES}/uploads/documents/${document1}`}
                  fullScreen
                />
              </Carousel.Item>
            )}
            {!!document1 && !!document2 ? (
              <Carousel.Item>
                <PictureViewerWrapper
                  backgroundImage={`${process.env.REACT_APP_BASE_URL_FOR_IMAGES}/uploads/documents/${document2}`}
                  fullScreen
                />
              </Carousel.Item>
            ) : null}
          </Carousel>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DocumentsView;
