import React, { ReactElement, useMemo } from "react";
import {
  IconWrapper,
  SelectedValue,
  SelectItem,
  SelectItemsWrapper,
  StyledInputWrapper,
  StyledSelectInput,
} from "./styles";
import { ReactComponent as CheckIcon } from "assets/checkIcon.svg";
import { useOutsideClick } from "utils/hooks";

type DataItem = { value: string; label: string };

type SelectInputProps = {
  icon?: ReactElement;
  data: DataItem[];
  value: string;
  handleChange: (value: string) => void;
  transparent?: boolean;
};

const SelectInput: React.FC<SelectInputProps> = ({
  icon,
  data,
  value,
  handleChange,
  transparent = false,
}) => {
  const { ref, isVisible, setIsVisible } = useOutsideClick(false);

  const handleToggle = () => {
    setIsVisible(!isVisible);
  };

  const handleOnChange = (data: DataItem) => {
    handleChange(data.value);
  };

  const selectedItem = useMemo(() => {
    return data.find((item) => value === item.value);
  }, [value, data]);

  return (
    <StyledInputWrapper transparent={transparent}>
      <StyledSelectInput ref={ref} onClick={handleToggle}>
        {icon && <IconWrapper>{icon}</IconWrapper>}

        <SelectedValue>{selectedItem?.label}</SelectedValue>
      </StyledSelectInput>

      <SelectItemsWrapper isOpen={isVisible}>
        {data.map((item, index) => (
          <SelectItem key={index} onClick={() => handleOnChange(item)}>
            {item.label}

            {value === item.value && <CheckIcon width={20} height={20} />}
          </SelectItem>
        ))}
      </SelectItemsWrapper>
    </StyledInputWrapper>
  );
};

export default SelectInput;
