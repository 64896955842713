import { useAppSelector } from "redux/hooks";
import DocumentModal from "./modals/DocumentModal";
import VerificationModal from "./modals/VerificationModal";
import DepositDetailsModal from "./modals/DepositDetailsModal";
import VerificationDocumentModal from "./modals/VerificationDocumentModal";
import PasswordModal from "./modals/PasswordModal";

const ModalContainer = () => {
  const {
    isVerificationModalOpen,
    isDocumentModalOpen,
    isDepositModalOpen,
    isVerificationDocumentModalOpen,
    isPasswordModalOpen,
  } = useAppSelector("user");

  return (
    <>
      {isVerificationModalOpen && <VerificationModal />}
      {isDocumentModalOpen && <DocumentModal />}
      {isDepositModalOpen && <DepositDetailsModal />}
      {isVerificationDocumentModalOpen && <VerificationDocumentModal />}
      {isPasswordModalOpen && <PasswordModal />}
    </>
  );
};

export default ModalContainer;
