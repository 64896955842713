import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Offcanvas, ListGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useAppDispatch } from "redux/hooks";
import { setUserData } from "redux/stores/userSlice";

// components
import ContentWithLoader from "./ContentWithLoader";
import Humburger from "../../assets/Humburger.svg";

//styles
import {
  StyledAdminLayout,
  StyledAdminContentContainer,
  AdminHeader,
  AdminSidebarHeader,
  AdminSidebarTitle,
  AdminSidebarCloseBtn,
  AdminSidebarLink,
  AdminSidebarListItem,
} from "./styles";

const links = [
  {
    label: "Пользователи",
    pathTo: `/Sl6ufptgfk/users`,
  },
  {
    label: "Документы (KYC)",
    pathTo: `/Sl6ufptgfk/kyc`,
  },
  {
    label: "Ставки",
    pathTo: "/Sl6ufptgfk/bets",
  },
  {
    label: "Отчеты",
    pathTo: "/Sl6ufptgfk/reports",
  },
  {
    label: "Методы оплати",
    pathTo: "/Sl6ufptgfk/payment-methods",
  },
  {
    label: "Настройки",
    pathTo: "/Sl6ufptgfk/settings",
  },
];

export default function AdminLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isSidebarOpen, sidebarToggler] = useState(false);

  const handlerSidebar = () => {
    sidebarToggler(!isSidebarOpen);
  };

  const logout = () => {
    navigate("/");

    window.localStorage.removeItem("token");

    dispatch(setUserData(null));
  };

  return (
    <StyledAdminLayout>
      <AdminHeader>
        <img
          src={Humburger}
          alt="menu"
          onClick={handlerSidebar}
          width={30}
          height={30}
        />
      </AdminHeader>

      <Offcanvas show={isSidebarOpen} onHide={handlerSidebar}>
        <AdminSidebarHeader>
          <AdminSidebarTitle as="div">
            <AdminSidebarCloseBtn onClick={handlerSidebar} aria-label="Hide" />
          </AdminSidebarTitle>
        </AdminSidebarHeader>

        <AdminSidebarHeader>
          <AdminSidebarTitle as="div">
            Админ
            <Button variant="primary" onClick={logout}>
              Выйти
            </Button>
          </AdminSidebarTitle>
        </AdminSidebarHeader>

        <Offcanvas.Body>
          <ListGroup>
            {links.map(({ label, pathTo }) => (
              <AdminSidebarLink key={pathTo} to={pathTo}>
                <AdminSidebarListItem
                  onClick={handlerSidebar}
                  active={location.pathname === pathTo}
                  eventKey={pathTo}
                >
                  {label}
                </AdminSidebarListItem>
              </AdminSidebarLink>
            ))}
          </ListGroup>
        </Offcanvas.Body>
      </Offcanvas>

      <ContentWithLoader>
        <StyledAdminContentContainer>
          <Outlet />
        </StyledAdminContentContainer>
      </ContentWithLoader>
    </StyledAdminLayout>
  );
}
