import { useMemo } from "react";
import {
  VerificationIconWrapper,
  VerificationIndicator,
  StyledVerification,
  FilesWrapper,
} from "./styles";
import { ReactComponent as FilesIcon } from "assets/filesIcon.svg";
import { useAppDispatch, useAppSelector, useTranslate } from "redux/hooks";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toggleVerificationDocumentModal } from "redux/stores/userSlice";

const Verification = () => {
  const dispatch = useAppDispatch();
  const translate = useTranslate();
  const { userData, language } = useAppSelector("user");

  const tooltipStatusMessages = useMemo(() => {
    if (userData) {
      switch (userData?.kyc) {
        case "approved":
          return translate("VERIFICATION_APPROVED");
        case "pending":
          return translate("VERIFICATION_PENDING");
        case "rejected":
          return translate("VERIFICATION_REJECTED");
        default:
          return translate("VERIFY");
      }
    }

    return "";
  }, [userData, language]);

  const titles = {
    approved: "",
    pending: translate("VERIFICATIONS"),
    rejected: translate("VERIFICATION_FAILED"),
  };

  const handleOpenModal = () => {
    dispatch(toggleVerificationDocumentModal(true));
  };

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip>{tooltipStatusMessages}</Tooltip>}
    >
      <StyledVerification
        onClick={
          userData?.kyc === null || userData?.kyc === "rejected"
            ? handleOpenModal
            : undefined
        }
        status={userData?.kyc}
      >
        <span>
          {userData && userData.kyc
            ? titles[userData.kyc]
            : translate("VERIFY")}
        </span>

        <div style={{ position: "relative" }}>
          <VerificationIconWrapper status={userData?.kyc} />

          <FilesWrapper status={userData?.kyc}>
            <FilesIcon />
          </FilesWrapper>
        </div>

        {userData && userData.kyc === "approved" && <VerificationIndicator />}
      </StyledVerification>
    </OverlayTrigger>
  );
};

export default Verification;
