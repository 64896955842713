import { ReactComponent as Hamburger } from "assets/hamburger.svg";
import { ReactComponent as CloseIcon } from "assets/closeIcon.svg";
import { useOutsideClick } from "utils/hooks";
import {
  IconWrapper,
  StyledMobileMenu,
  LinkList,
  UserInfoBlock,
  UserText,
  LinkWrapper,
  UserContacts,
  UserEmail,
} from "./styles";
import Avatar from "components/Avatar";
import { useAppDispatch, useAppSelector, useTranslate } from "redux/hooks";
import SideBarItem from "components/SideBar/SideBarItem";
import { ReactComponent as SettingsIcon } from "assets/settingsIcon.svg";
import { ReactComponent as WalletIcon } from "assets/walletIcon.svg";
import { ReactComponent as GameIcon } from "assets/gameIcon.svg";
import { ReactComponent as LogoutIcon } from "assets/logoutIcon.svg";
import { ReactComponent as CardIcon } from "assets/cardIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { setUserData } from "redux/stores/userSlice";

const MobileMenu = () => {
  const { ref, isVisible, setIsVisible } = useOutsideClick(false);
  const dispatch = useAppDispatch();
  const { userData } = useAppSelector("user");
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const translate = useTranslate();

  useEffect(() => {
    setIsVisible(false);
  }, [pathname, setIsVisible]);

  const handleLogout = () => {
    window.localStorage.removeItem("token");
    dispatch(setUserData(null));
    navigate("/");
  };

  const iconProps = [
    {
      icon: <GameIcon />,
      pathTo: "/game",
      label: translate("GAME"),
    },
    {
      icon: <WalletIcon />,
      pathTo: "/wallet",
      label: translate("WALLET"),
    },
    {
      icon: <CardIcon />,
      pathTo: "/wallet/history",
      label: translate("WALLET_HISTORY"),
    },
    {
      icon: <SettingsIcon />,
      pathTo: "/profile",
      label: translate("PERSONAL_AREA"),
    },
  ];

  return (
    <StyledMobileMenu isActive={isVisible} ref={ref}>
      <IconWrapper>
        {isVisible ? (
          <CloseIcon onClick={() => setIsVisible(false)} />
        ) : (
          <Hamburger onClick={() => setIsVisible(!isVisible)} />
        )}
      </IconWrapper>

      {isVisible && (
        <LinkList>
          <UserInfoBlock>
            <UserContacts>
              <Avatar withIcon={false} width="60px" height="60px" />

              <UserText>
                <span>
                  {userData?.first_name} {userData?.last_name}
                </span>
                <UserEmail>{userData?.email}</UserEmail>
              </UserText>
            </UserContacts>

            <LogoutIcon cursor="pointer" onClick={handleLogout} />
          </UserInfoBlock>

          <LinkWrapper>
            {iconProps.map(({ icon, pathTo, label }, index) => (
              <SideBarItem
                mobile
                height="30px"
                isSelected={pathname === pathTo}
                key={index}
                icon={icon}
                pathTo={pathTo}
                label={label}
              />
            ))}
          </LinkWrapper>
        </LinkList>
      )}
    </StyledMobileMenu>
  );
};

export default MobileMenu;
