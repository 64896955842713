import BalanceCard from "components/BalanceCard";
import { useState } from "react";
import { useAppSelector } from "redux/hooks";
import DepositForm from "../DepositForm";
import WithdrawForm from "../WithdrawForm";
import { FormWrapper, StyledMobileForm, Tab, Tabs } from "./styles";

const MobileForm = () => {
  const { userData } = useAppSelector("user");
  const [tab, setTab] = useState<"deposit" | "withdraw">("deposit");

  return (
    <FormWrapper>
      <BalanceCard balance={`${Number(userData?.balance).toFixed(2)} USD`} />

      <StyledMobileForm tab={tab}>
        <Tabs>
          <Tab isActive={tab === "deposit"} onClick={() => setTab("deposit")}>
            Пополнить
          </Tab>

          <Tab isActive={tab === "withdraw"} onClick={() => setTab("withdraw")}>
            Вывести
          </Tab>
        </Tabs>

        {tab === "deposit" ? <DepositForm /> : <WithdrawForm />}
      </StyledMobileForm>
    </FormWrapper>
  );
};

export default MobileForm;
