import styled from "styled-components";
import background from "assets/fileBackground.png";
import activeBackground from "assets/activeFileBackground.png";

export const StyledFileInput = styled.div<{
  isFileLoad: boolean;
  error?: string;
}>(({ isFileLoad, error }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "100%",
  height: "69px",
  position: "relative",
  background: `url(${isFileLoad ? activeBackground : background})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "0px -5px",
  borderRadius: "20px",
  fontFamily: "Stolzl",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "19px",
  color: "#9595DB",
  border: error ? "1px solid rgba(255, 65, 65, 0.65)" : "none",

  "& input": {
    opacity: 0,
    position: "absolute",
    width: "100%",
    height: "69px",
    cursor: "pointer",
  },
}));

export const LoadedPlaceholder = styled.div({
  display: "flex",
  alignItems: "center",
  padding: "0px 15px",
  gap: "10px",
  width: "100%",
  height: "100%",
  cursor: "pointer",

  "@media screen and (max-width: 1200px)": {
    padding: "0px 20px",
    gap: "20px",
  },

  "@media screen and (max-width: 1000px)": {
    padding: "0px 20px",
    gap: "10px",
  },
});

export const PlaceholderText = styled.div({
  width: "108px",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "19px",
  color: "#fff",
});

export const Placeholder = styled.div({
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "19px",
  color: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "20px",
  padding: "0px 20px",

  "& span": {
    width: "90px",
  },
});
