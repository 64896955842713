import { Carousel, Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { toggleDocumentModal } from "redux/stores/userSlice";
import { StyledDocumentModal } from "./styles";

const DocumentModal = () => {
  const { userData } = useAppSelector("user");
  const dispatch = useAppDispatch();

  return (
    <StyledDocumentModal>
      <Modal
        centered
        show={true}
        backdropClassName="document-modal-backdrop"
        onHide={() => dispatch(toggleDocumentModal(false))}
      >
        <Modal.Header closeButton />

        <Modal.Body>
          <Carousel variant="dark" interval={null}>
            {userData?.kyc_documents?.document1 && (
              <Carousel.Item>
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={`${process.env.REACT_APP_BASE_URL_FOR_IMAGES}/uploads/documents/${userData?.kyc_documents?.document1}`}
                  alt="First slide"
                />
              </Carousel.Item>
            )}

            {userData?.kyc_documents?.document2 && (
              <Carousel.Item>
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={`${process.env.REACT_APP_BASE_URL_FOR_IMAGES}/uploads/documents/${userData?.kyc_documents?.document2}`}
                  alt="Second slide"
                />
              </Carousel.Item>
            )}
          </Carousel>
        </Modal.Body>
      </Modal>
    </StyledDocumentModal>
  );
};

export default DocumentModal;
