import { CodeInput } from "components/index";
import Button from "./AuthButton";
// styles
import {
  StyledLoginForm,
  LoginFormTitle,
  CodeInputsContainer,
  CodeInputWrapper,
} from "./styles";
import { Controller, useForm } from "react-hook-form";
import { useVerifyOtpMutation } from "redux/api/AuthApi";
import { useNavigate } from "react-router-dom";
import { useTranslate } from "redux/hooks";

const LoginForm = () => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const [verifyOTP, { isLoading }] = useVerifyOtpMutation();
  const { handleSubmit, control, setError } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {
      code: "",
    },
  });

  const submit = async ({ code }: { code: string }) => {
    const email = window.localStorage.getItem("email") || "";

    const response = await verifyOTP({
      email,
      code: code,
    }).unwrap();

    if (response.status === "error") {
      setError("code", { message: response.message });
    }

    if (response.status === "success") {
      window.localStorage.setItem("code", code);

      navigate("/new-password");
    }
  };

  return (
    <StyledLoginForm>
      <LoginFormTitle>Введите код с вашей почты</LoginFormTitle>

      <StyledLoginForm onSubmit={handleSubmit(submit)}>
        <CodeInputsContainer>
          <CodeInputWrapper>
            <Controller
              control={control}
              name="code"
              rules={{
                required: true,
                validate: {
                  length: (value) =>
                    value.length === 6 || translate("CODE_LENGTH_ERROR"),
                },
              }}
              render={({
                field: { value, name, onChange },
                fieldState: { error },
              }) => (
                <CodeInput
                  name={name}
                  value={value}
                  error={error?.message}
                  handleChange={onChange}
                />
              )}
            />
          </CodeInputWrapper>
        </CodeInputsContainer>

        <Button
          width="250px"
          disabled={isLoading}
          onClick={handleSubmit(submit)}
          text="Отправить код"
        />
      </StyledLoginForm>
    </StyledLoginForm>
  );
};

export default LoginForm;
