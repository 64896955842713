import styled from "styled-components";
import depositMobileBackground from "assets/depositMobileFormBackground.png";
import withdrawMobileBackground from "assets/withdrawMobileFormBackground.png";
import tabBackground from "assets/tabBackground.png";
import activeTabBackground from "assets/activeTabBackground.png";

export const FormWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "20px",
});

export const StyledMobileForm = styled.div<{ tab: "deposit" | "withdraw" }>(
  ({ tab }) => ({
    display: "flex",
    flexDirection: "column",
    width: "375px",
    background: `url(${
      tab === "deposit" ? depositMobileBackground : withdrawMobileBackground
    })`,
    height: tab === "deposit" ? "510px" : "680px",
    padding: "25px 25px 0px",
  })
);

export const Tabs = styled.div({
  display: "flex",
  gap: "10px",
  marginBottom: "15px",
});

export const Tab = styled.div<{ isActive: boolean }>(({ isActive }) => ({
  width: "152px",
  height: "45px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: `url(${isActive ? activeTabBackground : tabBackground})`,
  cursor: "pointer",
  color: isActive ? "white" : "#516888",
}));
