import { AuthButton } from "./styles";

const Button: React.FC<ButtonProps> = ({
  text = "",
  background = "#677AEA",
  color = "#FFF",
  onClick,
  width = "max-content",
  height = "80px",
  padding = "26px",
  margin = "0px",
  disabled = false,
}) => {
  return (
    <AuthButton
      margin={margin}
      height={height}
      onClick={onClick}
      width={width}
      background={background}
      color={color}
      disabled={disabled}
    >
      {text}
    </AuthButton>
  );
};

export default Button;
