import { useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import {
  useCreatePaymentMethodMutation,
  useDeletePaymentMethodMutation,
  useGetAllPaymentMethodsQuery,
} from "redux/api/adminApi";
import {
  Input,
  // Pagination,
  Table,
} from "../components";
import { InputWrapper, StyledAlerts, StyledPaymentMethods } from "./style";

const columns = [
  { field: "id", title: "ID" },
  { field: "name", title: "Название" },
  { field: "symbol", title: "Symbol" },
  { field: "wallet", title: "Кошелек" },
  { field: "created_at", title: "Дата" },
  { field: "actions", title: "Действия" },
];

const PaymentMethods = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [formData, setFormField] = useState({
    name: "",
    symbol: "",
    wallet: "",
  });
  const { data, isLoading } = useGetAllPaymentMethodsQuery({ page, perPage });
  const [deletePaymentMethod] = useDeletePaymentMethodMutation();
  const [createPaymentMethod] = useCreatePaymentMethodMutation();
  const handleChange = (value: string, name: string) => {
    setFormField({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    createPaymentMethod(formData)
      .unwrap()
      .then((response) => {
        if (response.code === 400) {
          const error = response.errors.symbol[0];

          setErrorMessage(error);

          setTimeout(() => {
            setErrorMessage("");
          }, 2000);
        }
      })
      .finally(() => {
        setFormField({
          name: "",
          symbol: "",
          wallet: "",
        });
      });
  };

  const formattedData = useMemo(() => {
    if (data) {
      return data.data.data.map((item: any) => ({
        ...item,
        actions: (
          <Button onClick={() => deletePaymentMethod(item.id)}>Удалить</Button>
        ),
      }));
    }

    return [];
  }, [data]);

  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <StyledPaymentMethods>
      {errorMessage && (
        <StyledAlerts variant="danger">{errorMessage}</StyledAlerts>
      )}

      <InputWrapper>
        <Input
          label="Название"
          width="200px"
          type="text"
          name="name"
          required
          handleChange={handleChange}
        />
        <Input
          label="Symbol"
          width="200px"
          type="text"
          name="symbol"
          required
          handleChange={handleChange}
        />
        <Input
          label="Кошелек"
          width="200px"
          type="text"
          name="wallet"
          required
          handleChange={handleChange}
        />

        <Button onClick={handleSubmit}>Save</Button>
      </InputWrapper>

      <Table data={formattedData} columns={columns} />

      {/* <Pagination
        withPerPage={true}
        lastPage={data.last_page}
        currentPage={data.current_page}
        perPage={perPage}
        onPageChange={setPage}
        onPerPageChange={setPerPage}
      /> */}
    </StyledPaymentMethods>
  );
};

export default PaymentMethods;
