import mobileNotificationBackground from "assets/mobileNotificationBackground.png";
import styled from "styled-components";

export const StyledMobileMenu = styled.div<{ isActive: boolean }>(
  ({ isActive }) => ({
    display: "none",
    position: "relative",
    zIndex: 1,

    "@media screen and (max-width: 1200px)": {
      display: "flex",
      padding: "2px",
      background: isActive ? "rgb(24, 28, 68)" : "transparent",
      borderRadius: "25px 25px 0px 0px",
    },
  })
);

export const IconWrapper = styled.div({
  display: "flex",
  width: "48px",
  height: "48px",
  alignItems: "center",
  justifyContent: "center",
  background: `url(${mobileNotificationBackground})`,
  backgroundSize: "cover",
  cursor: "pointer",
});

export const LinkList = styled.div({
  position: "absolute",
  width: "350px",
  padding: "25px 0px 30px",
  height: "430px",
  background: "rgb(24, 28, 68)",
  bottom: "-429px",
  right: "0px",
  borderRadius: "17px 0px 17px 17px ",
  display: "flex",
  flexDirection: "column",
});

export const UserInfoBlock = styled.div({
  display: "flex",
  padding: "0px 25px",
  color: "white",
  gap: "15px",
  alignItems: "center",
  justifyContent: "space-between",
});

export const UserContacts = styled.div({
  display: "flex",
  gap: "15px",
  alignItems: "center",
  justifyContent: "space-between",
});

export const UserText = styled.div({
  fontWeight: 400,
  fontSize: "13px",
  lineHeight: "19px",
  color: "white",
  display: "flex",
  flexDirection: "column",
  gap: "5px",
});

export const UserEmail = styled.div({
  textOverflow: "ellipsis",
  width: "185px",
  overflow: "hidden",
  whiteSpace: "nowrap",
});

export const LinkWrapper = styled.div({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "30px",
  fontWeight: "900",
  textTransform: "uppercase",
  marginTop: "35px",
});
