import styled from "styled-components";

export const StyledAdminSettings = styled.div({
  display: "flex",
  width: "100%",
  flexDirection: "column",
  gap: "15px",
});

export const AdminSettingsForm = styled.form({
  display: "flex",
  width: "100%",
  gap: "15px",
  flexWrap: "wrap",
});

export const ErrorText = styled.span({
  color: "red",
});
