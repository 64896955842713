import styled from "styled-components";

export const StyledGame = styled.div({
  minWidth: "100%",

  "& iframe": {
    border: "none",
    borderRadius: "33px",
    padding: 0,
    margin: 0,
    boxSizing: "border-box",
  },
});
