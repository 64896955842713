import lockIcon from "../assets/lockIcon.svg";
import emailIcon from "../assets/emailIcon.svg";
import eyeIcon from "../assets/eyeIcon.svg";
import userIcon from "../assets/userIcon.svg";
import twitterIcon from "../assets/twitterIcon.svg";
import googleIcon from "../assets/googleIcon.svg";
import faceBookIcon from "../assets/faceBookIcon.svg";
import waterDropIcon from "../assets/waterDropIcon.svg";
import settingsIcon from "../assets/settingsIcon.svg";
import logoutIcon from "../assets/logoutIcon.svg";
import checkIcon from "../assets/checkIcon.svg";
import logo from "../assets/logo.svg";

export const ICON = {
  logo,
  lock: lockIcon,
  email: emailIcon,
  eye: eyeIcon,
  user: userIcon,
  twitterIcon: twitterIcon,
  googleIcon: googleIcon,
  faceBookIcon: faceBookIcon,
  waterDrop: waterDropIcon,
  settings: settingsIcon,
  check: checkIcon,
  logout: logoutIcon,
};
