import { WithdrawForm } from "../Form";
import { WithdrawWrapper } from "./styles";

const Withdraw = () => {
  return (
    <WithdrawWrapper>
      <WithdrawForm />
    </WithdrawWrapper>
  );
};

export default Withdraw;
