import { useMemo } from "react";
import {
  ArrowButton,
  ArrowIcon,
  Page,
  StyledPaginationWrapper,
} from "./styles";

type HistoryPaginationProps = {
  handleSelectPage: (page: number) => void;
  currentPage: number;
  totalPages: number;
};

const HistoryPagination: React.FC<HistoryPaginationProps> = ({
  handleSelectPage,
  currentPage,
  totalPages,
}) => {
  const pagesArray = useMemo(() => {
    const pages = [];

    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <Page
          key={i}
          onClick={() => handleSelectPage(i)}
          isSelected={i === currentPage}
        >
          {i}
        </Page>
      );
    }

    return pages;
  }, [currentPage]);

  const handleSelect = (direction: "prev" | "next") => {
    if (currentPage === 1 && direction === "prev") return;
    if (currentPage === totalPages && direction === "next") return;

    handleSelectPage(direction === "prev" ? currentPage - 1 : currentPage + 1);
  };

  return (
    <StyledPaginationWrapper>
      <ArrowButton onClick={() => handleSelect("prev")}>
        <ArrowIcon />
      </ArrowButton>

      {pagesArray}

      <ArrowButton onClick={() => handleSelect("next")}>
        <ArrowIcon side="right" />
      </ArrowButton>
    </StyledPaginationWrapper>
  );
};

export default HistoryPagination;
