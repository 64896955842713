import styled from "styled-components";
import background from "assets/cryptoBackground.png";
import mobileBackground from "assets/mobileCryptoBackground.png";

export const StyledWalletDetailsPage = styled.div({
  minWidth: "100%",
  display: "flex",
  flexDirection: "column",

  "@media screen and (max-width: 1200px)": {
    alignItems: "center",
  },
});

export const ContentWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "100%",
  gap: "10px",

  "@media screen and (max-width: 1200px)": {
    maxWidth: "985px",
  },

  "@media screen and (max-width: 1000px)": {
    alignItems: "center",
  },
});

export const InputsWrapper = styled.div({
  marginTop: "60px",
  display: "flex",
  flexDirection: "column",
  gap: "40px",
});

export const CryptoWallet = styled.div({
  background: `url(${background})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  padding: "25px 35px",
  maxWidth: "680px",
  minHeight: "147px",
  width: "100%",

  "@media screen and (max-width: 1000px)": {
    height: "123px",
    width: "375px",
    minWidth: "375px",
    background: `url(${mobileBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    justifyContent: "center",
  },
});

export const CryptoWalletPlus = styled(CryptoWallet)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});
