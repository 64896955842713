import styled from "styled-components";
import background from "assets/tableBackground.png";
import tableTabBackground from "assets/tableTabBackground.png";
import mobileTableBackground from "assets/mobileTableBackground.png";

export const StyledTable = styled.div({
  background: `url(${background})`,
  padding: "15px 22px",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  width: "1025px",
  height: "100%",
  minHeight: "550px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",

  "@media screen and (max-width: 1200px)": {
    width: "935px",
    background: `url(${tableTabBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },

  "@media screen and (max-width: 1000px)": {
    width: "375px",
    height: "680px",
    background: `url(${mobileTableBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
});

export const TableWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  height: "100%",
});

export const StyledHistoryTable = styled.table({
  display: "grid",
  gridTemplateRows: "56px auto",
  rowGap: "20px",
  height: "100%",
  padding: "15px 15px 0px",
  fontFamily: "Stolzl",
  overflow: "scroll",
  maxHeight: "500px",
  marginBottom: "5px",

  "&::-webkit-scrollbar": {
    display: "none",
  },

  "& thead tr": {
    display: "grid",
    gridTemplateColumns: "50px repeat(5, 1fr)",
    color: "white",
    background: "rgba(16, 19, 45, 0.69)",
    height: "56px",
    padding: "15px 30px",
    alignItems: "center",
    borderRadius: "25px",

    "& th": {
      textAlign: "center",

      "&:first-child": {
        textAlign: "start",
      },
    },
  },

  "& thead": {
    "@media screen and (max-width: 1000px)": {
      display: "none",
      height: "0px",
      padding: "0px",
    },
  },

  "& tbody tr": {
    color: "white",
    display: "grid",
    gridTemplateColumns: "50px repeat(5, 1fr)",
    padding: "0px 30px",
    borderBottom: "1px solid #496377",
    alignItems: "center",
    gridTemplateAreas: `"a b c d e f"`,
    gridTemplateRows: "45px",

    "& td": {
      textAlign: "center",

      "&:first-child": {
        textAlign: "start",
      },

      "@media screen and (max-width: 1000px)": {
        display: "flex",
        flexDirection: "column",
      },
    },

    "@media screen and (max-width: 1000px)": {
      gridTemplateAreas: `"a c e" "b d f"`,
      gridTemplateColumns: "repeat(3, 1fr)",
      gridTemplateRows: "repeat(2, 40px)",
      columnGap: "10%",
      rowGap: "10px",
      fontSize: "12px",
      padding: "0px 0px 10px",

      "& td": {
        textAlign: "left",
      },
    },
  },

  "@media screen and (max-width: 1200px)": {
    maxHeight: "460px",
  },

  "@media screen and (max-width: 1000px)": {
    maxHeight: "580px",
  },
});

export const MobileCellTitle = styled.span({
  display: "none",
  color: "#94B0C8",
  fontFamily: "Stolzl",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "24px",
  textAlign: "left",

  "@media screen and (max-width: 1000px)": {
    display: "block",
  },
});

export const Status = styled.span<{ color: string }>(({ color }) => ({
  color,
  fontFamily: "Stolzl",
  textTransform: "capitalize",
}));

export const TableEmptyState = styled.div({
  width: "100%",
  height: "200px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  fontWeight: 700,
  fontSize: "25px",
  textAlign: "center",
});
