import { useState, useEffect } from "react";
import "moment/locale/ru";
import { Button } from "react-bootstrap";
// components
import { Table, Pagination } from "../components";
import { SelectStatus, UserInfo, DocumentsView } from "./components";
// api
import {
  useGetAllKYCQuery,
  useDeleteKYCMutation,
} from "../../../redux/api/adminApi";
// utils
import { getFormattedData } from "../../../utils/helpers";
// constans
import { PER_PAGE } from "../../../utils/constants";
// styles
import { StyledDocumentsPage } from "./styles";

export default function DocumentssModule() {
  const [KYCes, setKYCes] = useState<KYCForTable[]>([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_PAGE[0]);

  const { data } = useGetAllKYCQuery({
    page,
    perPage,
  });
  const [deleteKYC, { isLoading: isLoadingDelete, isError: isErrorDelete }] =
    useDeleteKYCMutation();

  useEffect(() => {
    if (!!data) {
      const tempDocs = data.data.map((doc) => {
        const { id, status } = doc;
        const tempDoc: KYCForTable = {
          id,
          status,
          created_at: "",
          documents: [],
          user: <></>,
          selection_status: <></>,
          actions: <></>,
        };
        tempDoc.created_at = getFormattedData(doc.created_at);

        tempDoc.documents = (
          <DocumentsView document1={doc.document1} document2={doc.document2} />
        );

        tempDoc.user = <UserInfo user={doc.user} />;

        tempDoc.selection_status = <SelectStatus kyc={tempDoc} />;

        tempDoc.actions = (
          <Button
            onClick={() => {
              handleDeleteKYC(tempDoc.id);
            }}
            variant="danger"
            size="sm"
            disabled={isLoadingDelete}
          >
            Удалить
          </Button>
        );

        return tempDoc;
      });

      setKYCes(tempDocs);
    }
  }, [data]); // eslint-disable-line

  const columns = [
    { field: "id", title: "ID" },
    { field: "created_at", title: "Дата" },
    { field: "documents", title: "Документы" },
    { field: "user", title: "Пользователь" },
    { field: "selection_status", title: "Статус" },
    { field: "actions", title: "Действия" },
  ];

  async function handleDeleteKYC(id: number) {
    if (window.confirm(`Удалить документа (KYC) с ID=${id}?`)) {
      try {
        await deleteKYC(id);
        if (isErrorDelete) {
          console.log(isErrorDelete);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <StyledDocumentsPage>
      DocumentsModule
      {!!data ? (
        <>
          <Table data={KYCes} columns={columns} />
          <Pagination
            withPerPage={true}
            lastPage={data?.last_page}
            currentPage={data?.current_page}
            perPage={perPage}
            onPageChange={setPage}
            onPerPageChange={setPerPage}
          />
        </>
      ) : (
        <div> Нет данных</div>
      )}
    </StyledDocumentsPage>
  );
}
