import { Pagination, Table } from "../../components";
import { Form } from "react-bootstrap";
import { SelectWrapper, StyledTab } from "./styles";
import {
  useGetAllUsersQuery,
  useGetStatisticsPercentageQuery,
} from "redux/api/adminApi";
import { ChangeEvent, useEffect, useState } from "react";
import { getFormattedData } from "utils/helpers";
import moment from "moment";

const columns = [
  { field: "full_name", title: "Фамилия и Имя" },
  { field: "date_of_birth", title: "Дата рождения" },
  { field: "email", title: "Email" },
  { field: "phone", title: "Номер телефона" },
  { field: "created_at", title: "Дата регистрации" },
];

const NewUsers = () => {
  const [period, setPeriod] = useState<[string, string]>(["", ""]);
  const [clients, setClients] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data: clientsData, isFetching } = useGetAllUsersQuery({
    page,
    perPage,
    filters:
      period[0] !== ""
        ? {
            start_date: period[0],
            end_date: period[1],
          }
        : undefined,
  });

  useEffect(() => {
    if (!!clientsData) {
      const tempClients = clientsData.data.map(
        ({
          id,
          first_name,
          last_name,
          date_of_birth,
          email,
          phone,
          created_at,
        }) => ({
          id,
          full_name: `${last_name ? last_name : ""}${
            first_name ? " " + first_name : ""
          }`,
          date_of_birth: date_of_birth ? date_of_birth : "--",
          email,
          phone: phone ? phone : "--",
          created_at: getFormattedData(created_at),
        })
      );

      setClients(tempClients);
    }
  }, [clientsData]);

  const handlePeriodChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;

    switch (value) {
      case "all": {
        setPeriod(["", ""]);

        return;
      }

      case "yesterday": {
        const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
        const today = moment().format("YYYY-MM-DD");

        setPeriod([yesterday, today]);
        return;
      }

      case "month": {
        const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
        const endOfMonth = moment()
          .endOf("month")
          .add(1, "day")
          .format("YYYY-MM-DD");

        setPeriod([startOfMonth, endOfMonth]);
        return;
      }

      case "last_month": {
        const startOfMonth = moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        const endOfMonth = moment()
          .subtract(1, "month")
          .endOf("month")
          .add(1, "day")
          .format("YYYY-MM-DD");

        setPeriod([startOfMonth, endOfMonth]);
        return;
      }

      default: {
        const today = moment().format("YYYY-MM-DD");
        const endDay = moment().add(1, "day").format("YYYY-MM-DD");

        setPeriod([today, endDay]);
      }
    }
  };

  return (
    <StyledTab>
      <SelectWrapper width="50%">
        <span>Период</span>

        <Form.Select defaultValue={"all"} onChange={handlePeriodChange}>
          <option value="all">За все время</option>
          <option value="today">Сегодня</option>
          <option value="yesterday">Вчера</option>
          <option value="month">В этом месяце</option>
          <option value="last_month">В прошлом месяце</option>
        </Form.Select>
      </SelectWrapper>

      <Table data={clients} columns={columns} />

      <Pagination
        withPerPage={true}
        lastPage={clientsData?.last_page || 0}
        currentPage={clientsData?.current_page || 0}
        perPage={perPage}
        onPageChange={setPage}
        onPerPageChange={setPerPage}
      />
    </StyledTab>
  );
};

export default NewUsers;
