import styled from "styled-components";
import withdrawMobileBackground from "assets/withdrawMobileFormBackground.png";
import withdrawTabBackground from "assets/withdrawTabBackground.png";
import withdrawDesktopBackground from "assets/withdrawDesktopBackground.png";

export const WithdrawWrapper = styled.div({
  width: "450px",
  height: "580px",
  display: "flex",
  flexDirection: "column",
  background: `url(${withdrawDesktopBackground})`,
  backgroundSize: "cover",
  backgroundPosition: "-30px 0px",
  padding: "25px 50px 0px",

  "@media screen and (max-width: 1200px)": {
    background: `url(${withdrawTabBackground})`,
    backgroundSize: "cover",
    width: "405px",
    backgroundPosition: "0px 0px",
    backgroundRepeat: "no-repeat",
    padding: "25px 40px 0px 35px",
  },

  "@media screen and (max-width: 1000px)": {
    width: "375px",
    background: `url(${withdrawMobileBackground})`,
    backgroundRepeat: "no-repeat",
    padding: "25px 20px 0px 25px",
  },
});
