import styled, { keyframes } from "styled-components";

const upDownMoves = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const StyledLoaderWrapper = styled.div`
  width: 100%;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    animation: ${upDownMoves} 2.5s infinite linear;
  }
`;
