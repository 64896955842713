import { Pagination, Table } from "../../components";
import { Form } from "react-bootstrap";
import { SelectGroupWrapper, SelectWrapper, StyledTab } from "./styles";
import { useGetAllTransactionsQuery } from "redux/api/adminApi";
import { ChangeEvent, useState } from "react";
import moment from "moment";

const statisticsColumns = [
  { field: "total_count", title: "Total withdrawals" },
  { field: "min_amount", title: "Min amount" },
  { field: "max_amount", title: "Max amount" },
  { field: "average_amount", title: "Average amount" },
  { field: "total_amount", title: "Total amount" },
];

const columns = [
  { field: "id", title: "ID" },
  { field: "order_id", title: "Order ID" },
  { field: "created_at", title: "Дата" },
  { field: "amount", title: "Сумма" },
  { field: "payment_method_id", title: "Payment method" },
  {
    field: "user",
    title: "User",
    children: [
      { field: "first_name", title: "First name" },
      { field: "last_name", title: "Last name" },
      { field: "email", title: "Email" },
    ],
  },
  { field: "status", title: "Статус" },
];

const Deposit = () => {
  const [period, setPeriod] = useState<[string, string]>(["", ""]);
  const [status, setSelectedStatus] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data: response, isLoading } = useGetAllTransactionsQuery({
    page,
    perPage,
    filters: {
      ...(period[0] && {
        start_date: period[0],
        end_date: period[1],
      }),
      status: status || undefined,
    },
  });

  if (isLoading || !response) return null;

  const handlePeriodChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;

    switch (value) {
      case "all": {
        setPeriod(["", ""]);

        return;
      }

      case "yesterday": {
        const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
        const today = moment().format("YYYY-MM-DD");

        setPeriod([yesterday, today]);
        return;
      }

      case "month": {
        const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
        const endOfMonth = moment()
          .endOf("month")
          .add(1, "day")
          .format("YYYY-MM-DD");

        setPeriod([startOfMonth, endOfMonth]);
        return;
      }

      case "last_month": {
        const startOfMonth = moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        const endOfMonth = moment()
          .subtract(1, "month")
          .endOf("month")
          .add(1, "day")
          .format("YYYY-MM-DD");

        setPeriod([startOfMonth, endOfMonth]);
        return;
      }

      default: {
        const today = moment().format("YYYY-MM-DD");
        const endDay = moment().add(1, "day").format("YYYY-MM-DD");

        setPeriod([today, endDay]);
      }
    }
  };

  return (
    <StyledTab>
      <SelectGroupWrapper>
        <SelectWrapper width="49%">
          <span>Период</span>

          <Form.Select defaultValue={"all"} onChange={handlePeriodChange}>
            <option value="all">За все время</option>
            <option value="today">Сегодня</option>
            <option value="yesterday">Вчера</option>
            <option value="month">В этом месяце</option>
            <option value="last_month">В прошлом месяце</option>
          </Form.Select>
        </SelectWrapper>

        <SelectWrapper width="49%">
          <span>Статус</span>

          <Form.Select
            value={status}
            onChange={({ target }) => setSelectedStatus(target.value)}
          >
            <option value="">All</option>
            <option value="pending">Pending</option>
            <option value="completed">Completed</option>
          </Form.Select>
        </SelectWrapper>
      </SelectGroupWrapper>

      <span>Статистика депозитов</span>

      <Table data={[response.data.statistics]} columns={statisticsColumns} />

      <span>Список депозитов</span>

      <Table data={response.data.transactions.data} columns={columns} />

      <Pagination
        withPerPage={true}
        lastPage={response.data.transactions.last_page}
        currentPage={response.data.transactions.current_page}
        perPage={perPage}
        onPageChange={setPage}
        onPerPageChange={setPerPage}
      />
    </StyledTab>
  );
};

export default Deposit;
