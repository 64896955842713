import styled from "styled-components";

export const StyledAvatar = styled.div<AvatarProps>(
  ({ src = "", width, height }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width,
    height,
    minWidth: width,
    minHeight: height,
    borderRadius: "50%",
    background: src ? `url(${src})` : "lightgray",
    position: "relative",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",

    svg: {
      width: `calc(100% - 20px)`,
      height: `calc(100% - 20px)`,
    },

    "@media screen and (max-width: 1000px)": {
      width,
      height,
      minWidth: width,
      minHeight: height,
    },
  })
);

export const StyledCircleForIcon = styled.div({
  width: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "56px",
  background: "#677AEC",
  position: "absolute",
  top: "0px",
  right: "0px",
  border: "5px solid #5F768E",
  cursor: "pointer",
  overflow: "hidden",

  "& input": {
    width: "40px",
    height: "40px",
    opacity: 0,
    position: "absolute",
    cursor: "pointer",
  },

  "@media screen and (max-width: 1000px)": {
    width: "40px",
    height: "40px",
    cursor: "pointer",
  },
});

export const ErrorMessageText = styled.div({
  position: "absolute",
  display: "flex",
  alignItems: "center",
  width: "max-content",
  height: "58px",
  borderRadius: "20px",
  padding: "0px 20px",
  background: "rgba(255, 51, 51, 0.8)",
  color: "white",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "28px",
  fontFamily: "Stolzl",
  left: "calc(100% + 15px)",
  gap: "20px",

  "&:before": {
    content: '""',
    width: "15px",
    height: "15px",
    position: "absolute",
    left: "-15px",
    border: "6px solid transparent",
    borderRight: "6px solid rgba(255, 51, 51, 0.65)",
  },
});
