export { default as Profile } from "./Profile";
export { default as Wallet } from "./Wallet";
export { default as WalletDetails } from "./WalletDetails";
export { default as WalletHistory } from "./WalletHistory";
export { default as LoginForm } from "./Login/LoginForm";
export { default as ResetPassword } from "./Login/ResetPassword";
export { default as CodeVerification } from "./Login/CodeVerification";
export { default as Registration } from "./Login/Registration";
export { default as SocialNetworks } from "./Login/SocialNetworks";
export { default as NewPassword } from "./Login/NewPassword";
export * from "./Admin/Users";
export * from "./Admin/Statistics";
export * from "./Admin/Bets";
export * from "./Admin/Reports";
export * from "./Admin/Documents";
