import styled from "styled-components";

export const StyledTab = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  width: "100%",
  height: "100%",
});

export const SelectWrapper = styled.div<{ width?: string }>(
  ({ width = "100%" }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    width,
  })
);

export const StyledSelect = styled.select({
  height: "40px",
  borderRadius: "10px",
  padding: "0px 10px",
  cursor: "pointer",
  appearance: "none",
  MozAppearance: "none",
  WebkitAppearance: "none",
});

export const SelectGroupWrapper = styled.div({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  rowGap: "10px",
});
