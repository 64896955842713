export const RU = {
  PERSONAL_AREA: "ЛИЧНЫЙ КАБИНЕТ",
  DATE_OF_BIRTH: "Дата рождения",
  PERSONAL_INFORMATION: "Личная информация",
  ACCOUNT_CURRENCY: "Валюта аккаунта",
  PHONE_NUMBER: "Номер телефона",
  DAY: "День",
  MONTH: "Месяц",
  YEAR: "Год",
  OLD_PASSWORD: "Старый пароль",
  NEW_PASSWORD: "Новый пароль",
  REGISTERED: "Зарегистрирован",
  CHANGE: "Изменить",
  LOADING: "Загрузка",
  FIRST_NAME: "Имя",
  LAST_NAME: "Фамилия",
  SAFETY: "Безопасность",
  WALLET: "КОШЕЛЕК",
  YOUR_DETAILS: "Реквизиты",
  WITHDRAW_HISTORY: "История",
  YOUR_BALANCE: "Ваш активный баланс",
  REPLENISH_FUNDS: "ПОПОЛНИТЬ СРЕДСТВА",
  TOP_UP_AMOUNT: "Сумма пополнения",
  SELECT_REPLENISHMENT_DETAILS: "Выберите реквизиты пополнения",
  CONFIRM_ENTRY: "Подтвердить ввод",
  CONFIRM_ENTRY_VIA_FIAT: "Подтвердить ввод через Fiat",
  WITHDRAWAL: "ВЫВЕСТИ СРЕДСТВА",
  WITHDRAWAL_AMOUNT: "Сумма вывода",
  SELECT_DETAILS_FOR_WITHDRAWAL: "Выберите монету для вывода",
  SELECT_WALLET_FOR_WITHDRAWAL: "Вставьте кошелек для вывода",
  CONFIRM_WITHDRAWAL: "Подтвердить вывод",
  TYPE: "Тип",
  DATE: "Дата",
  DETAILS: "Реквизиты",
  AMOUNT: "Сумма",
  STATUS: "Статус",
  ID: "id",
  CONTACT_DETAILS: "Контактные данные",
  SAVE: "Сохранить изменения",
  ID_CONFIRMATION: "Подтверждение личности",
  SECURITY: "Безопасность",
  MAX_FILE_SIZE: "Макс. 5МБ в jpg  png",
  BACK_SIDE: "Обратная сторона",
  DOCUMENT_ATTACHED: "Документ прикреплен",
  PASSPORT: "Паспорт",
  DRIVERS_LICENSE: "Водительские права",
  NATIONAL_ID: "Загран.паспорт",
  NOTIFICATION_EMPTY_STATE: "У вас нет новых уведомлений",
  WARNING_WITHDRAWAL_TEXT:
    "Внимание! Выбирайте сеть и проверяйте правильность введенных данных, в противном случае ваши средства могут быть утеряны",
  VERIFICATION_APPROVED: "Вашы документы успешно прошли верификацию.",
  VERIFICATION_PENDING: "Вашы документы проходят верификацию.",
  VERIFICATION_REJECTED: "Вашы документы не прошли верификацию.",
  GAME: "Игра",
  PERSONAL_AREA_LABEL: "Личный кабинет",
  WALLET_HISTORY: "История транзакций",
  WALLET_LABEL: "Кошелек",
  TABLE_EMPTY_STATE: "Здесь будут отображаться ваши транзакции",
  ERROR_EMPTY_FIELD: "Поле не может быть пустным!",
  ERROR_LENGTH_FIELD: "Пароль не может быть меньше 8 и больше 30 символов!",
  ERROR_EQUALS_PASSWORD: "Пароли должни бить одинаковыми!",
  CREATE_PASSWORD: "Придумайте пароль",
  CONFIRM_PASSWORD: "Подтвердите пароль",
  MIN_DEPOSIT_AMOUNT: "Минимальная сумма депозита 10 $",
  NOTIFICATIONS: "Сообщения",
  VERIFICATIONS: "Верификация..",
  VERIFY: "Верификацировать",
  VERIFICATION_FAILED: "Верификация не пройдена!",
  DELETE_ALL: "Удалить все",
  PASSWORD_STATUS_SUCCESS: "Ваш пароль был удачно изменен",
  PASSWORD_STATUS_FAIL: "Вы ввели не действующий пароль",
  PASSWORD_INVALID: "Новый пароль не может совпадать со старым паролем.",
  PASSPORT_UPLOAD_SUCCESS_TITLE: "Паспорт загружен!",
  PASSPORT_UPLOAD_FAILED_TITLE: "Паспорт не был загружен!",
  PASSPORT_UPLOAD_FAILED:
    "Ваши документы не были загружены по сколько размер файла привишает 4 мегабайта",
  PASSPORT_UPLOAD_SUCCESS:
    "Пожалуйста, ожидайте верификации, прежде чем сможете продолжить далее..",
  PASSWORD_WHITESPACE_ERROR:
    "Пароль не должен начинаться и заканчиваться пробелом",
  REQUIRED_FIELD: "Поле не может быть пустым.",
  ALPHABET_EMAIL_ERROR:
    "Электронная почта должна содержать латинские буквы и цифры",
  EMAIL_LENGTH_ERROR: "Электронная почта должна быть от 5 до 50 символов",

  CODE_LENGTH_ERROR: "Код должен быть 6 символов",
  BD_VALIDATION: "Вам должно быть больше 18 лет",
  PAY: "Оплатить",
  GENERATE_NEW_WALLET: "Сгенерировать новый кошелек",
};
