import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./stores/userSlice";
import { authApi } from "./api/AuthApi";
import { userApi } from "./api/userApi";
import { adminApi } from "./api/adminApi";
import { ipInfoApi } from "./api/ipInfoApi";

export const store = configureStore({
  reducer: {
    user: userSlice,
    authApi: authApi.reducer,
    userApi: userApi.reducer,
    adminApi: adminApi.reducer,
    ipInfoApi: ipInfoApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      userApi.middleware,
      adminApi.middleware,
      ipInfoApi.middleware
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
