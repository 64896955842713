import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/plain.css";
import { useTranslate } from "redux/hooks";
import { PhoneInputWrapper, StyledPhoneInput } from "./styles";
import { useEffect, useState } from "react";
import { useGetIpInfoMutation } from "redux/api/ipInfoApi";

type PhoneInputComponentProps = {
  name: string;
  value: string;
  handleChange: (data: string) => void;
};

const PhoneInputComponent: React.FC<PhoneInputComponentProps> = ({
  name,
  value,
  handleChange,
}) => {
  const [defaultCountry, setDefaultCountry] = useState("");
  const [getIpInfo] = useGetIpInfoMutation();
  const translate = useTranslate();

  useEffect(() => {
    const getDefaultCountry = async () => {
      const info = await getIpInfo(null).unwrap();

      if (info) {
        setDefaultCountry(info.country.toLowerCase());
      }
    };

    if (!value) {
      getDefaultCountry();
    }
  }, []);

  return (
    <StyledPhoneInput>
      <PhoneInputWrapper>
        <PhoneInput
          country={!value ? defaultCountry : undefined}
          disableInitialCountryGuess={true}
          placeholder={translate("PHONE_NUMBER")}
          inputProps={{ name }}
          value={value}
          onChange={handleChange}
        />
      </PhoneInputWrapper>
    </StyledPhoneInput>
  );
};

export default PhoneInputComponent;
