import { useEffect, useMemo } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/esm/Form";
import { Controller, useForm } from "react-hook-form";
import {
  useGetGameSettingsQuery,
  useUpdateGameSettingsMutation,
} from "redux/api/adminApi";
import { SelectWrapper } from "../components/Input/styles";
import { AdminSettingsForm, ErrorText, StyledAdminSettings } from "./styles";

const defaultValues = {
  min_bet: 0,
  max_bet: 0,
  min_bots: 0,
  max_bots: 0,
  min_withdraw_amount: 0,
};

const AdminSettings = () => {
  const [updateSettings] = useUpdateGameSettingsMutation();
  const { data, isLoading } = useGetGameSettingsQuery(null);

  const { handleSubmit, control, reset } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues,
  });

  useEffect(() => {
    if (data) {
      const { min_bet, max_bet, min_bots, max_bots, min_withdraw_amount } =
        data.data;

      reset({ min_bet, max_bet, min_bots, max_bots, min_withdraw_amount });
    }
  }, [data]);

  if (isLoading || !data) return <div>Loading...</div>;

  const submit = async (data: typeof defaultValues) => {
    const { min_bet, max_bet, min_bots, max_bots, min_withdraw_amount } = data;

    await updateSettings({
      min_bet: +min_bet,
      max_bet: +max_bet,
      min_bots: +min_bots,
      max_bots: +max_bots,
      min_withdraw_amount: +min_withdraw_amount,
    });
  };

  return (
    <StyledAdminSettings>
      <AdminSettingsForm id="settings" onSubmit={handleSubmit(submit)}>
        <Controller
          name="min_bet"
          control={control}
          rules={{
            required: "is required",
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <SelectWrapper width="25%" minWidth="255px">
              <span>Минимальная ставка</span>

              <Form.Control
                type="number"
                isInvalid={Boolean(error)}
                value={value}
                onChange={onChange}
              />

              {error && <ErrorText>{error?.message}</ErrorText>}
            </SelectWrapper>
          )}
        />

        <Controller
          name="max_bet"
          control={control}
          rules={{
            required: "is required",
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <SelectWrapper width="25%" minWidth="255px">
              <span>Максимальная ставка</span>

              <Form.Control type="number" value={value} onChange={onChange} />

              {error && <ErrorText>{error?.message}</ErrorText>}
            </SelectWrapper>
          )}
        />

        <Controller
          name="min_withdraw_amount"
          control={control}
          rules={{
            required: "is required",
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <SelectWrapper width="25%" minWidth="255px">
              <span>Сумма минимального вывода</span>

              <Form.Control type="number" value={value} onChange={onChange} />

              {error && <ErrorText>{error?.message}</ErrorText>}
            </SelectWrapper>
          )}
        />
        <Controller
          name="min_bots"
          control={control}
          rules={{
            required: "is required",
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <SelectWrapper width="25%" minWidth="255px">
              <span>Минимальное количество ботов</span>

              <Form.Control type="number" value={value} onChange={onChange} />

              {error && <ErrorText>{error?.message}</ErrorText>}
            </SelectWrapper>
          )}
        />

        <Controller
          name="max_bots"
          control={control}
          rules={{
            required: "is required",
            validate: {
              max: (value) =>
                +value <= 100 || "Рекомендованое количество не больше 100",
            },
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <SelectWrapper width="25%" minWidth="255px">
              <span>Максимальное количество ботов</span>

              <Form.Control type="number" value={value} onChange={onChange} />

              {error && <ErrorText>{error?.message}</ErrorText>}
            </SelectWrapper>
          )}
        />
      </AdminSettingsForm>

      <Button
        type="submit"
        variant="success"
        form="settings"
        style={{ width: 200, alignSelf: "center" }}
      >
        Submit
      </Button>
    </StyledAdminSettings>
  );
};

export default AdminSettings;
