import { createRoot } from "react-dom/client";
import Router from "./Router";
import { store } from "../redux/store";
import { Provider } from "react-redux";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import TagManager from "react-gtm-module";
import { hotjar } from "react-hotjar";

TagManager.initialize({
  gtmId: "GTM-PJ86Q69",
});

const root = createRoot(document.getElementById("root") as HTMLElement);

hotjar.initialize(3524465, 6);

root.render(
  <Provider store={store}>
    <Router />
  </Provider>
);
