import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type initialStateProps = {
  userData: User | null;
  language: Dictionary;
  depositAmount: string;
  isVerificationModalOpen: null | "success" | "failed";
  isDocumentModalOpen: boolean;
  isDepositModalOpen: boolean;
  isWithdrawModalOpen: boolean;
  isVerificationDocumentModalOpen: boolean;
  isPasswordModalOpen: null | "success" | "failed" | "invalid";
};

const initialState: initialStateProps = {
  userData: null,
  language: "RU",
  depositAmount: "",
  isVerificationModalOpen: null,
  isDocumentModalOpen: false,
  isDepositModalOpen: false,
  isWithdrawModalOpen: false,
  isVerificationDocumentModalOpen: false,
  isPasswordModalOpen: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<User | null>) => {
      state.userData = action.payload;
    },

    changeLanguage: (state, action: PayloadAction<Dictionary>) => {
      state.language = action.payload;
    },

    changeDeposit: (state, action: PayloadAction<string>) => {
      state.depositAmount = action.payload;
    },

    toggleVerificationModal: (
      state,
      action: PayloadAction<initialStateProps["isVerificationModalOpen"]>
    ) => {
      state.isVerificationModalOpen = action.payload;
    },

    toggleDocumentModal: (
      state,
      action: PayloadAction<initialStateProps["isDocumentModalOpen"]>
    ) => {
      state.isDocumentModalOpen = action.payload;
    },
    toggleWalletDepositModal: (
      state,
      action: PayloadAction<initialStateProps["isDepositModalOpen"]>
    ) => {
      state.isDepositModalOpen = action.payload;
    },
    toggleWithdrawModal: (
      state,
      action: PayloadAction<initialStateProps["isWithdrawModalOpen"]>
    ) => {
      state.isWithdrawModalOpen = action.payload;
    },
    toggleVerificationDocumentModal: (
      state,
      action: PayloadAction<
        initialStateProps["isVerificationDocumentModalOpen"]
      >
    ) => {
      state.isVerificationDocumentModalOpen = action.payload;
    },
    togglePasswordModal: (
      state,
      action: PayloadAction<initialStateProps["isPasswordModalOpen"]>
    ) => {
      state.isPasswordModalOpen = action.payload;
    },
  },
});

export const {
  setUserData,
  changeLanguage,
  changeDeposit,
  toggleVerificationModal,
  toggleDocumentModal,
  toggleWalletDepositModal,
  toggleWithdrawModal,
  toggleVerificationDocumentModal,
  togglePasswordModal,
} = userSlice.actions;

export default userSlice.reducer;
