import { useState } from "react";
import { Form } from "react-bootstrap";
// api
import { useChangeKYCStatusMutation } from "../../../../redux/api/adminApi";
// constans
import { KYC_STATUSES } from "../../../../utils/constants";

type SelectStatusProps = {
  kyc: KYCForTable;
};

const SelectStatus: React.FC<SelectStatusProps> = ({ kyc }) => {
  const [changeKYCStatus, { isLoading, isError }] =
    useChangeKYCStatusMutation();

  const [status, setStatus] = useState<KYCStatus>(kyc.status);

  async function handleChangeStatus(
    event: React.ChangeEvent<HTMLSelectElement>,
    id: number
  ) {
    if (
      window.confirm(
        `Изменить статус документа (KYC) с "${kyc.status}" на "${event.target.value}"?`
      )
    ) {
      try {
        const newStatus = event.target.value as KYCStatus;
        await changeKYCStatus({ id, status: newStatus });

        if (!isError) {
          setStatus(newStatus);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
  return (
    <Form.Select
      size="sm"
      as="select"
      value={status}
      disabled={isLoading}
      defaultValue="pending"
      onChange={(e) => {
        handleChangeStatus(e, kyc.id);
      }}
    >
      {KYC_STATUSES.map((statusItem) => (
        <option key={statusItem} disabled={statusItem === "pending"}>
          {statusItem}
        </option>
      ))}
    </Form.Select>
  );
};

export default SelectStatus;
