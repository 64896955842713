import { ContentWrapper, StyledWalletPage } from "./styles";
import { MobileForm } from "./components";
import { useWindowSize } from "utils/hooks";
import Deposit from "./components/Deposit";
import Withdraw from "./components/Withdraw";

const Wallet = () => {
  const { width } = useWindowSize();

  return (
    <StyledWalletPage>
      <ContentWrapper>
        {width <= 1000 ? (
          <MobileForm />
        ) : (
          <>
            <Deposit />

            <Withdraw />
          </>
        )}
      </ContentWrapper>
    </StyledWalletPage>
  );
};

export default Wallet;
