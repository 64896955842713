import { useMemo } from "react";
// components
import { Pagination as BootstrapPagination, PageItem } from "react-bootstrap";
// constants
import {
  PER_PAGE,
  DESKTOP,
  TABLET,
  MOBILE_HORIZONTAL,
  MOBILE_VERTICAL,
} from "../../../../utils/constants";
// hooks
import { useWindowSize } from "../../../../utils/hooks";
// styles
import {
  StyledWrapper,
  StyledPagination,
  StyledPePageComponent,
} from "./styles";

type TablePagination = {
  lastPage: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  withPerPage?: boolean;
  perPage?: number;
  onPerPageChange?: (perPage: number) => void;
};

type PaginationRules = {
  mobile_v: number;
  mobile_h: number;
  tablet: number;
  desktop: number;
};

const Pagination: React.FC<TablePagination> = ({
  lastPage,
  perPage = PER_PAGE[0],
  currentPage,
  onPageChange,
  onPerPageChange = () => {},
  withPerPage = false,
}) => {
  const { width } = useWindowSize();

  const handlerPaginationClick = (event: React.SyntheticEvent<HTMLElement>) => {
    const { id } = event.currentTarget;

    onPageChange(+id);
  };

  const handlerSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;

    withPerPage && onPerPageChange(+value);
  };

  const minMaxRules: PaginationRules = {
    mobile_v: 5,
    mobile_h: 10,
    tablet: 15,
    desktop: 20,
  };

  const layout = useMemo((): keyof PaginationRules => {
    if (width < MOBILE_VERTICAL) return "mobile_v";
    if (width > MOBILE_VERTICAL && width < MOBILE_HORIZONTAL) return "mobile_h";
    if (width > MOBILE_HORIZONTAL && width < TABLET) return "tablet";

    return "desktop";
  }, [width]);

  const paginationItems = useMemo(() => {
    const items = [];
    const maxPage = minMaxRules[layout];

    const maxPageCount = maxPage > lastPage ? lastPage : maxPage;

    let minItemNumber =
      currentPage <= maxPageCount ? 1 : currentPage - (maxPageCount - 1);
    let maxItemNumber = currentPage < maxPageCount ? maxPageCount : currentPage;

    for (let number = minItemNumber; number <= maxItemNumber; number++) {
      items.push(
        <PageItem
          onClick={handlerPaginationClick}
          key={number}
          active={number === currentPage}
          id={String(number)}
        >
          {number}
        </PageItem>
      );
    }

    return items;
  }, [lastPage, currentPage, layout]); // eslint-disable-line

  return (
    <StyledWrapper>
      {withPerPage && (
        <StyledPePageComponent
          size="sm"
          onChange={handlerSelect}
          value={perPage}
        >
          {PER_PAGE.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </StyledPePageComponent>
      )}
      <StyledPagination>
        {width < DESKTOP && currentPage !== 1 && (
          <>
            <BootstrapPagination.First onClick={() => onPageChange(1)} />
            <BootstrapPagination.Prev
              onClick={() => onPageChange(currentPage - 1)}
            />
          </>
        )}

        {paginationItems}

        {width < DESKTOP && currentPage < lastPage && (
          <>
            <BootstrapPagination.Next
              onClick={() => onPageChange(currentPage + 1)}
            />
            <BootstrapPagination.Last onClick={() => onPageChange(lastPage)} />
          </>
        )}
      </StyledPagination>
    </StyledWrapper>
  );
};

export default Pagination;
