import moment from "moment";
import "moment/locale/ru";

// getting the date and time in the format DD.MM.YYYY hh:mm:ss
export const getFormattedData = (data: string) => {
  const deMomentData = moment(data).format("L");
  const deMomentTime = moment(data).format("LTS");

  return `${deMomentData} ${deMomentTime}`;
};

export const generateUrlWithParams = (data: { [x: string]: any }) => {
  let url = "";

  const keys = Object.keys(data);

  for (let i = 0; i < keys.length; i++) {
    if (data[keys[i]]) {
      url += `&${keys[i]}=${data[keys[i]]}`;
    }

    continue;
  }

  return url.replace("&", "");
};
