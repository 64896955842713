import {
  StyledPasswordModal,
  VerificationIconWrapper,
  VerificationModalBody,
} from "./styles";
import { ReactComponent as CloseIcon } from "assets/closeIcon.svg";
import { useAppDispatch, useAppSelector, useTranslate } from "redux/hooks";
import { togglePasswordModal } from "redux/stores/userSlice";
import { useEffect } from "react";

const PasswordModal = () => {
  const { isPasswordModalOpen } = useAppSelector("user");
  const dispatch = useAppDispatch();
  const translate = useTranslate();

  useEffect(() => {
    setTimeout(() => {
      dispatch(togglePasswordModal(null));
    }, 4000);
  }, []);

  const handleClose = () => {
    dispatch(togglePasswordModal(null));
  };

  const text: Record<string, string> = {
    success: translate("PASSWORD_STATUS_SUCCESS"),
    failed: translate("PASSWORD_STATUS_FAIL"),
    invalid: translate("PASSWORD_INVALID"),
  };

  return (
    <StyledPasswordModal onClick={handleClose}>
      <VerificationModalBody>
        <CloseIcon
          onClick={handleClose}
          cursor="pointer"
          width={30}
          height={30}
          style={{ alignSelf: "flex-end", marginRight: -30 }}
        />

        <p>{text[isPasswordModalOpen || "failed"]}</p>
      </VerificationModalBody>
    </StyledPasswordModal>
  );
};

export default PasswordModal;
