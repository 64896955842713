import styled from "styled-components";
import { Pagination, Form } from "react-bootstrap";

export const StyledWrapper = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "wrap",
  gap: "10px",
});

export const StyledPagination = styled(Pagination)({
  margin: 0,
});

export const StyledPePageComponent = styled(Form.Select)({
  width: "5rem",
  height: "2.5rem",
});
