import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const userApi = createApi({
  reducerPath: "userApi",
  tagTypes: ["User", "Notifications", "Withdrawal", "Transaction"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers) => {
      if (window.localStorage.token) {
        headers.set("Authorization", window.localStorage.token);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    authMe: builder.query<AuthMeResponse, null>({
      query: () => ({
        url: `/auth/me`,
        method: "POST",
      }),
      transformResponse: (response: AuthMeResponse) => {
        if (response.status === "error") {
          return {
            code: 500,
            status: "error",
            user: {} as User,
          };
        }

        return response;
      },
      providesTags: ["User"],
      keepUnusedDataFor: 1,
    }),

    updateUserInfo: builder.mutation<any, any>({
      query: (body: any) => ({
        url: `/client/update-data`,
        method: "POST",
        params: {
          _method: "PATCH",
        },
        body,
      }),
      invalidatesTags: ["User"],
    }),
    updatePassword: builder.mutation<ApiResponseWithoutData, any>({
      query: (body) => ({
        url: `/client/update-password`,
        method: "POST",
        params: {
          _method: "PATCH",
        },
        body,
      }),
      invalidatesTags: ["User"],
    }),

    updateKYC: builder.mutation<ApiResponseWithoutData, FormData>({
      query: (body) => ({
        url: `/kyc`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["User"],
    }),

    getTransactionsHistory: builder.query<
      TransactionsUserResponse,
      TransactionsBody
    >({
      query: ({ page, perPage, startDate, endDate }) =>
        `/transactions?page=${page}&per_page=${perPage}&start_date=${startDate}&end_date=${endDate}`,
      keepUnusedDataFor: 5,
      providesTags: ["Transaction"],
    }),

    updateAvatar: builder.mutation<any, FormData>({
      query: (body: FormData) => ({
        url: "/client/upload-avatar",
        method: "POST",
        body,
      }),
      invalidatesTags: ["User"],
    }),

    getDepositMethods: builder.query<any, null>({
      query: () => "/payment-methods",
    }),

    createTransaction: builder.mutation<NewTransactionResponse, NewTransaction>(
      {
        query: (body: any) => ({
          url: "/payment-gateway/create-transaction",
          method: "POST",
          body,
        }),
        invalidatesTags: ["Transaction"],
      }
    ),

    getAllWithdrawals: builder.query<WithdrawalsUserResponse, TransactionsBody>(
      {
        query: ({ page, perPage, startDate, endDate }) => ({
          url: `/withdrawals?page=${page}per_page=${perPage}&start_date=${startDate}&end_date=${endDate}`,
        }),
        providesTags: ["Withdrawal"],
      }
    ),

    createWithdrawal: builder.mutation<any, { amount: string; wallet: string }>(
      {
        query: (body) => ({
          url: "/withdrawals",
          method: "POST",
          body,
        }),
        invalidatesTags: ["Withdrawal"],
      }
    ),

    getNotifications: builder.query<ApiResponse<ApiNotification[]>, null>({
      query: () => "/notifications",
      transformResponse: (response: ApiResponse<ApiNotification[]>) => {
        if (!response.status) {
          return {
            code: 500,
            status: "error",
            data: [],
          };
        }

        return response;
      },
      providesTags: ["Notifications"],
    }),

    deleteAllNotifications: builder.mutation<any, null>({
      query: () => ({
        url: "/notifications",
        method: "DELETE",
      }),
      invalidatesTags: ["Notifications"],
    }),
  }),
});

export const {
  useAuthMeQuery,
  useUpdateAvatarMutation,
  useGetTransactionsHistoryQuery,
  useUpdateUserInfoMutation,
  useUpdatePasswordMutation,
  useUpdateKYCMutation,
  useGetDepositMethodsQuery,
  useCreateTransactionMutation,
  useGetNotificationsQuery,
  useCreateWithdrawalMutation,
  useDeleteAllNotificationsMutation,
  useGetAllWithdrawalsQuery,
} = userApi;
