import { Button, Input } from "components/index";
import { CardTitle, CardBody, CardAmount, ErrorMessage } from "../styles";
import { useAppDispatch, useAppSelector, useTranslate } from "redux/hooks";
import buttonBackground from "assets/buttonBackground.png";
import {
  changeDeposit,
  toggleWalletDepositModal,
} from "redux/stores/userSlice";
import { useEffect, useMemo, useState } from "react";
import { useCreateTransactionMutation } from "redux/api/userApi";
import { useGetIpInfoMutation } from "redux/api/ipInfoApi";
import { DISABLED_COUNTRIES } from "./constants";

const DepositForm = () => {
  const { depositAmount } = useAppSelector("user");
  const [error, setError] = useState<null | string>(null);
  const [isFiatVisible, setFiatVisibility] = useState(false);
  const dispatch = useAppDispatch();
  const translate = useTranslate();
  const [createTransaction] = useCreateTransactionMutation();
  const [getIpInfo] = useGetIpInfoMutation();

  useEffect(() => {
    const getDefaultCountry = async () => {
      const info = await getIpInfo(null).unwrap();

      if (info) {
        const isVisible = !DISABLED_COUNTRIES.find(
          ({ code }) => code === info.country
        );

        setFiatVisibility(isVisible);
      }
    };

    getDefaultCountry();
  }, []);

  const handleDepositChange = (value: string) => {
    if (error) {
      setError(null);
    }

    dispatch(changeDeposit(value));
  };

  const openPaymentModal = () => {
    if (+depositAmount < 10) {
      setError(translate("MIN_DEPOSIT_AMOUNT"));

      return;
    }

    dispatch(toggleWalletDepositModal(true));
  };

  const openFiatWidget = async () => {
    if (+depositAmount < 10) {
      setError(translate("MIN_DEPOSIT_AMOUNT"));

      return;
    }

    const response = await createTransaction({
      amount: +depositAmount,
      fiat: true,
      gateway: "switchere",
    })
      .unwrap()
      .then((response) => {
        if (response.status === "success") {
          return {
            order_id: response.transaction.order_id,
            status: response.status,
          };
        }
      });

    if (response?.status === "success") {
      // @ts-ignore
      window.switchereSdk.init({
        mode: "modal",
        partnerKey: process.env.REACT_APP_SWITCHERE_KEY,
        httpReturnFailed: window.location.href,
        httpReturnSuccess: window.location.href,
        payinAmount: +depositAmount,
        payinCurrency: "USD",
        partnerOrderId: response.order_id,
      });
    }
  };

  return (
    <CardBody>
      <CardAmount size="small">
        <CardTitle>{translate("REPLENISH_FUNDS")}</CardTitle>

        <Input
          name="depositAmount"
          variant="dark"
          placeholder="0 USD"
          value={depositAmount}
          inputType="text"
          handleChange={handleDepositChange}
        />
      </CardAmount>

      {error && <ErrorMessage>{error}</ErrorMessage>}

      <Button
        background={`url(${buttonBackground})`}
        onClick={openPaymentModal}
        width="100%"
        height="70px"
        padding="16px"
        text={translate("CONFIRM_ENTRY")}
      />

      {isFiatVisible && (
        <Button
          background={`url(${buttonBackground})`}
          onClick={openFiatWidget}
          width="100%"
          height="70px"
          padding="16px"
          text={translate("CONFIRM_ENTRY_VIA_FIAT")}
        />
      )}
    </CardBody>
  );
};

export default DepositForm;
