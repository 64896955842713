import { useState } from "react";
import AuthButton from "./AuthButton";
import Input from "./AuthInput";
// styles
import {
  StyledLoginForm,
  LoginFormTitle,
  InputsContainer,
  StyledLink,
  StyledForm,
  RedirectLinksWrapper,
} from "./styles";
import { ReactComponent as EmailIcon } from "assets/emailIcon.svg";
import { ReactComponent as LockIcon } from "assets/lockIcon.svg";
import { ReactComponent as EyeIcon } from "assets/eyeIcon.svg";
import { useLoginMutation } from "redux/api/AuthApi";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
// import SocialNetworks from "./SocialNetworks";

const LoginForm = () => {
  const navigate = useNavigate();
  const [login, { isLoading }] = useLoginMutation();
  const { handleSubmit, control, setError } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const [type, setInputType] = useState("password");

  const submit = async (data: any) => {
    await login(data)
      .unwrap()
      .then((response) => {
        if (response.status === "success") {
          window.localStorage.setItem(
            "token",
            `Bearer ${response.data.user.token}`
          );

          navigate(response.data.user.is_admin ? `/Sl6ufptgfk/users` : "/game");
        }

        if (response.status === "error") {
          setError("email", { message: response.message });
        }
      });
  };

  const handleChangeInputType = () => {
    setInputType(type === "password" ? "text" : "password");
  };

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <StyledLoginForm>
        <LoginFormTitle>Войти</LoginFormTitle>

        <StyledForm onSubmit={handleSubmit(submit)}>
          <InputsContainer>
            <Controller
              control={control}
              name="email"
              rules={{
                required: "This field can`t be empty",
              }}
              render={({
                field: { value, name, onChange },
                fieldState: { error },
              }) => (
                <Input
                  placeholder="E-mail"
                  name={name}
                  value={value}
                  inputType="email"
                  error={error?.message}
                  handleChange={onChange}
                  leftIcon={<EmailIcon />}
                />
              )}
            />

            <Controller
              control={control}
              name="password"
              rules={{
                required: "This field can`t be empty",
              }}
              render={({
                field: { value, name, onChange },
                fieldState: { error },
              }) => (
                <Input
                  placeholder="Пароль"
                  name={name}
                  value={value}
                  inputType={type}
                  error={error?.message}
                  handleChange={onChange}
                  leftIcon={<LockIcon />}
                  rightIcon={
                    <EyeIcon cursor="pointer" onClick={handleChangeInputType} />
                  }
                />
              )}
            />
          </InputsContainer>

          <AuthButton
            width="250px"
            height="60px"
            margin="0px 0px 20px"
            text={isLoading ? "Загрузка..." : "Войти"}
          />
        </StyledForm>

        <RedirectLinksWrapper>
          <StyledLink to="/reset-password">
            Забыли пароль <b>&rsaquo;</b>
          </StyledLink>

          <StyledLink to="/registration">
            Нет аккаунта? <b>&rsaquo;</b>
          </StyledLink>
        </RedirectLinksWrapper>
      </StyledLoginForm>

      {/* <SocialNetworks /> */}
    </div>
  );
};

export default LoginForm;
