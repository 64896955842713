import styled from "styled-components";
import mobileNotificationBackground from "assets/mobileNotificationBackground.png";
import background from "assets/inputBackground.png";

export const StyledHistoryFilters = styled.div({
  display: "flex",
  height: "60px",
  width: "100%",
  borderBottom: "none",
  alignItems: "center",
  paddingLeft: "15px",
  gap: "10px",
  maxWidth: "500px",

  "@media screen and (max-width: 1000px)": {
    background: `url(${background})`,
    backgroundSize: "cover",
    gap: "0px",
    paddingLeft: "0px",
  },
});

export const IconWrapper = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "50px",
  height: "50px",
  background: `url(${mobileNotificationBackground})`,
  backgroundSize: "contain",

  "@media screen and (max-width: 1000px)": {
    // display: "none",
    background: "none",
    width: "30px",
  },
});

export const StyledDateInput = styled.input({
  width: "115px",
  color: "white",

  "@media screen and (max-width: 1000px)": {
    width: "95px",
  },
});

export const InputsWrapper = styled.div({
  display: "flex",
  alignItems: "center",
  fontFamily: "Stolzl",
  fontSize: "14px",
  gap: "5px",
  color: "#94B0C8",

  "& input": {
    background: "transparent",
    border: "none",
    outline: "none",
  },

  "@media screen and (max-width: 1000px)": {
    fontSize: "12px",
  },
});
