import styled from "styled-components";
import inputBackground from "assets/inputBackground.png";

export const InputWrapper = styled.div<{ error?: string }>(({ error }) => ({
  height: "75px",
  background: `url(${inputBackground})`,
  padding: "10px 36px",
  fontSize: "20px",
  lineHeight: "28px",
  inputBorder: "none",
  placeholderColor: "#232851",
  width: "100%",
  borderRadius: "14px",
  display: "flex",
  alignItems: "center",
  outline: "none",
  backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  position: "relative",

  border: error ? "1px solid rgba(255, 65, 65, 0.65)" : "none",
}));

export const StyledInput = styled.input({
  width: "100%",
  height: "100%",
  background: "rgba(14, 5, 34, 0.81)",
  border: "none",
  outline: "none",
  fontWeight: 400,
  fontSize: "34px",
  lineHeight: "28px",
  color: "#677AEC",
  cursor: "pointer",
  textAlign: "center",
  letterSpacing: "10px",

  "&::placeholder": {
    fontSize: "20px",
  },
});
