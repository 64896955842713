import {
  IconWrapper,
  StyledHistoryFilters,
  StyledDateInput,
  InputsWrapper,
} from "./styles";
import { ReactComponent as CalendarIcon } from "assets/calendarIcon.svg";
import moment from "moment";
import { ChangeEvent } from "react";

const today = moment().format("YYYY-MM-DD");
const min = moment({ year: 1900 }).format("YYYY-MM-DD");

type HistoryFiltersProps = {
  startDate: string;
  endDate: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const HistoryFilters: React.FC<HistoryFiltersProps> = ({
  startDate,
  endDate,
  handleChange,
}) => (
  <StyledHistoryFilters>
    <IconWrapper>
      <CalendarIcon />
    </IconWrapper>

    <InputsWrapper>
      <span>Выписку с:</span>

      <StyledDateInput
        value={startDate}
        onChange={handleChange}
        name="startDate"
        type="date"
        min={min}
        max={today}
        onKeyDown={(e) => e.preventDefault()}
      />

      <span>до:</span>

      <StyledDateInput
        value={endDate}
        min={min}
        max={today}
        onChange={handleChange}
        name="endDate"
        type="date"
        onKeyDown={(e) => e.preventDefault()}
      />
    </InputsWrapper>
  </StyledHistoryFilters>
);

export default HistoryFilters;
