import styled from "styled-components";

export const StyledButton = styled.button<{
  width: string;
  height: string;
  padding: string;
  margin: string;
  background: string;
  color: string;
  disabled: boolean;
}>(({ width, height, padding, margin, background, color, disabled }) => ({
  background,
  width,
  height,
  margin,
  padding,
  color,
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "28px",
  borderRadius: "14px",
  border: "none",
  cursor: "pointer",
  alignSelf: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",

  "&:disabled": {
    background: "slategray",
    pointerEvents: disabled ? "none" : undefined,
  },

  "&:active": {
    boxShadow: "inset 10px 10px 12px rgba(0,0,0,0.6)",
  },

  "@media screen and (max-width: 1000px)": {
    height: "56px",
  },
}));
