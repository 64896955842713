import React from "react";
import {
  LoadedPlaceholder,
  Placeholder,
  PlaceholderText,
  StyledFileInput,
} from "./styles";
import { ReactComponent as CheckCircleIcon } from "assets/checkCircleIcon.svg";
import { ReactComponent as DownloadIcon } from "assets/downloadIcon.svg";
import { toggleDocumentModal } from "redux/stores/userSlice";
import { useAppDispatch, useTranslate } from "redux/hooks";
import { ErrorText } from "modules/Profile/styles";

type FileInputProps = {
  name: string;
  value: File | null;
  placeholder: string;
  handleChange: (value: File | null) => void;
  placeholderWithIcon?: boolean;
  isDocumentLoaded?: string | null;
  error?: string;
};

const FileInput: React.FC<FileInputProps> = ({
  name,
  handleChange,
  value,
  placeholder,
  placeholderWithIcon,
  isDocumentLoaded = false,
  error,
}) => {
  const dispatch = useAppDispatch();
  const translate = useTranslate();
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    const file = files ? files[0] : null;

    handleChange(file);
  };

  const isFileLoaded =
    value || (isDocumentLoaded !== "rejected" && isDocumentLoaded !== null);

  const openDocumentModal = () =>
    isDocumentLoaded !== null &&
    isDocumentLoaded !== "rejected" &&
    dispatch(toggleDocumentModal(true));

  return (
    <StyledFileInput error={error} isFileLoad={Boolean(value || isFileLoaded)}>
      {isFileLoaded ? (
        <LoadedPlaceholder onClick={openDocumentModal}>
          <CheckCircleIcon />

          <PlaceholderText>{translate("DOCUMENT_ATTACHED")}</PlaceholderText>
        </LoadedPlaceholder>
      ) : (
        <Placeholder>
          {placeholderWithIcon && <DownloadIcon width="32px" height="32px" />}

          <span>{placeholder}</span>
        </Placeholder>
      )}

      <input
        style={{ zIndex: isFileLoaded ? -1 : 0 }}
        disabled={Boolean(isFileLoaded)}
        name={name}
        type="file"
        onChange={handleOnChange}
      />

      {error && <ErrorText>{error}</ErrorText>}
    </StyledFileInput>
  );
};

export default FileInput;
