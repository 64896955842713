import { ErrorMessageText, StyledAvatar, StyledCircleForIcon } from "./styles";
import { ReactComponent as CameraIcon } from "assets/cameraIcon.svg";
import { ReactComponent as UserCircle } from "assets/userCircle.svg";
import { ChangeEvent, useEffect, useState } from "react";
import { useUpdateAvatarMutation } from "redux/api/userApi";
import { useAppSelector } from "redux/hooks";

const Avatar: React.FC<AvatarProps> = ({
  width = "56px",
  height = "56px",
  alt = "icon",
  withIcon = true,
}) => {
  const { userData } = useAppSelector("user");
  const [avatar, setAvatar] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [updateAvatar] = useUpdateAvatarMutation();

  useEffect(() => {
    if (userData) {
      setAvatar(userData.avatar);
    }
  }, [userData?.avatar, userData]);

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (files) {
      const formData = new FormData();

      formData.append("avatar", files[0]);

      await updateAvatar(formData)
        .unwrap()
        .then((response) => {
          if (response.status === "error") {
            setErrorMessage(response.errors.avatar[0]);

            setTimeout(() => {
              setErrorMessage("");
            }, 2000);
          }
        });
    }
  };

  return (
    <StyledAvatar
      src={
        avatar
          ? `${process.env.REACT_APP_BASE_URL_FOR_IMAGES}/${avatar}`
          : undefined
      }
      width={width}
      height={height}
      alt={alt}
    >
      {withIcon && (
        <StyledCircleForIcon>
          <CameraIcon cursor="pointer" width="15" height="15" />

          <input type="file" onChange={handleChange} />
        </StyledCircleForIcon>
      )}

      {!avatar && <UserCircle />}

      {errorMessage && <ErrorMessageText>{errorMessage}</ErrorMessageText>}
    </StyledAvatar>
  );
};

export default Avatar;
