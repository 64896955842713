import {
  StyledHistoryTable,
  StyledTable,
  MobileCellTitle,
  Status,
  TableWrapper,
  TableEmptyState,
} from "./styles";
import HistoryFilters from "../HistoryFilters";
import { useTranslate } from "redux/hooks";
import {
  useGetTransactionsHistoryQuery,
  useGetAllWithdrawalsQuery,
} from "redux/api/userApi";
import { ReactComponent as EthIcon } from "assets/eth.svg";
import { ReactComponent as UsdtIcon } from "assets/usdt.svg";
import { ReactComponent as BtcIcon } from "assets/btc.svg";
import HistoryPagination from "../HistoryPagination";
import { ChangeEvent, useMemo, useState } from "react";

const icons = {
  USDTTRC20: <UsdtIcon width={25} />,
  ETH: <EthIcon width={25} />,
  BTC: <BtcIcon width={25} />,
};

const colors = {
  pending: "#FFAA5C",
  completed: "#8AEC67",
  confirmed: "#8AEC67",
  approve: "#8AEC67",
  rejected: "#FF9393",
  failed: "#FF9393",
};

const titles = ["ID", "TYPE", "DATE", "DETAILS", "AMOUNT", "STATUS"];

const HistoryTable = () => {
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const translate = useTranslate();
  const { data: transactions, isLoading } = useGetTransactionsHistoryQuery({
    page,
    perPage: 7,
    startDate,
    endDate,
  });

  const { data: withdrawals, isLoading: isWithdrawalsLoading } =
    useGetAllWithdrawalsQuery({
      page,
      perPage: 7,
      startDate,
      endDate,
    });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    if (name === "startDate") {
      setStartDate(value);
    } else {
      setEndDate(value);
    }
  };

  const data = useMemo(() => {
    let tableItems: ItemForHistoryTable[] = [];

    if (withdrawals) {
      const formattedWithdrawals: ItemForHistoryTable[] =
        withdrawals.data.data.map<ItemForHistoryTable>(
          ({ id, amount, status, created_at }) => ({
            id,
            amount,
            created_at,
            type: "withdraw",
            status,
            payment_method_id: 0,
          })
        );

      tableItems = [...tableItems, ...formattedWithdrawals];
    }

    if (transactions) {
      const formattedTransactions: ItemForHistoryTable[] =
        transactions.data.data.map<ItemForHistoryTable>(
          ({
            id,
            amount,
            status,
            created_at,
            payment_method_id,
            payment_method,
          }) => ({
            id,
            amount,
            created_at,
            type: "deposit",
            status,
            payment_method_id,
            payment_method,
          })
        );

      tableItems = [...tableItems, ...formattedTransactions];
    }

    tableItems = tableItems.sort(
      (a: ItemForHistoryTable, b: ItemForHistoryTable) => {
        return b.created_at.localeCompare(a.created_at);
      }
    );

    return tableItems;
  }, [withdrawals, transactions]);

  return (
    <StyledTable>
      <TableWrapper>
        <HistoryFilters
          startDate={startDate}
          endDate={endDate}
          handleChange={handleChange}
        />

        {(isLoading ||
          !transactions ||
          isWithdrawalsLoading ||
          !withdrawals) && <div>Loading...</div>}

        {transactions &&
          transactions.code === 200 &&
          transactions.data.data.length === 0 && (
            <TableEmptyState>{translate("TABLE_EMPTY_STATE")}</TableEmptyState>
          )}

        {data && (
          <StyledHistoryTable>
            <thead>
              <tr>
                {titles.map((title, index) => (
                  //@ts-ignore
                  <th key={index}>{translate(title)}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map(
                ({
                  id,
                  status,
                  type,
                  created_at,
                  payment_method,
                  payment_method_id,
                  amount,
                }) => (
                  <tr key={id}>
                    <td style={{ gridArea: "a" }}>
                      <MobileCellTitle>
                        {/* @ts-ignore */}
                        {translate(titles[0])}:
                      </MobileCellTitle>
                      <span>{id}</span>
                    </td>
                    <td style={{ gridArea: "b" }}>
                      <MobileCellTitle>
                        {/* @ts-ignore */}
                        {translate(titles[1])}:
                      </MobileCellTitle>
                      <span>{type}</span>
                    </td>
                    <td style={{ gridArea: "c" }}>
                      <MobileCellTitle>
                        {/* @ts-ignore */}
                        {translate(titles[2])}:
                      </MobileCellTitle>
                      <span>{new Date(created_at).toLocaleDateString()}</span>
                    </td>
                    <td style={{ gridArea: "d" }}>
                      <MobileCellTitle>
                        {/* @ts-ignore */}
                        {translate(titles[3])}:
                      </MobileCellTitle>
                      <span>
                        {/* @ts-ignore */}
                        {payment_method_id && payment_method.symbol
                          ? // @ts-ignore
                            icons[payment_method.symbol]
                          : "Fiat"}
                      </span>
                    </td>
                    <td style={{ gridArea: "e" }}>
                      <MobileCellTitle>
                        {/* @ts-ignore */}
                        {translate(titles[4])}:
                      </MobileCellTitle>
                      <span>{amount}</span>
                    </td>
                    <td style={{ gridArea: "f" }}>
                      <MobileCellTitle>
                        {/* @ts-ignore */}
                        {translate(titles[5])}:
                      </MobileCellTitle>
                      {/* @ts-ignore */}
                      <Status color={colors[status]}>{status}</Status>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </StyledHistoryTable>
        )}
      </TableWrapper>

      <HistoryPagination
        handleSelectPage={setPage}
        currentPage={transactions?.data.current_page || 0}
        totalPages={transactions?.data.last_page || 0}
      />
    </StyledTable>
  );
};

export default HistoryTable;
