import styled from "styled-components";
import background from "assets/socialBackground.png";
import activeBackground from "assets/activeSocialBackground.png";

export const StyledCurrencyCheckbox = styled.div({
  display: "flex",
  justifyContent: "space-between",
  gap: "5px",
  fontFamily: "Stolzl",

  // "@media screen and (max-width: 1200px)": {
  //   gap: "0px",
  // },
});

export const CurrencyCheckboxItem = styled.div<{ isChecked: boolean }>(
  ({ isChecked }) => ({
    display: "flex",
    height: "50px",
    padding: "17px 22px",
    fontWeight: 600,
    background: `url(${isChecked ? activeBackground : background})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "100%",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    gap: "30px",
    color: "#fff",

    "@media screen and (max-width: 1200px)": {
      width: "130px",
      height: "50px",
    },

    "@media screen and (min-width: 1000px)": {
      width: "110px",
      height: "65px",
    },
  })
);
