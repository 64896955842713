import { useState, useEffect } from "react";
import debounce from "lodash/debounce";
// components
import { Table, Pagination, Input } from "../components";
// styles
import { StyledUsersPage } from "./styles";
// api
import { useGetAllUsersQuery } from "../../../redux/api/adminApi";
// utils
import { getFormattedData } from "../../../utils/helpers";
// constants
import { PER_PAGE } from "../../../utils/constants";
import { Accordion } from "react-bootstrap";
import { SelectGroupWrapper } from "../Reports/components/styles";

type Client = {
  id: number;
  full_name: string;
  date_of_birth: string;
  email: string;
  phone: string;
  created_at: string;
};

export default function UsersModule() {
  const [clients, setClients] = useState<Client[]>([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_PAGE[0]);
  const [formData, setFormField] = useState({
    fullname: "",
    date_of_birth: "",
    email: "",
    phone: "",
  });

  const { data: clientsData } = useGetAllUsersQuery({
    page,
    perPage,
    filters: formData,
  });

  useEffect(() => {
    if (!!clientsData) {
      const tempClients = clientsData.data.map(
        ({
          id,
          first_name,
          last_name,
          date_of_birth,
          email,
          phone,
          created_at,
          balance,
        }) => ({
          id,
          full_name: `${last_name ? last_name : ""}${
            first_name ? " " + first_name : ""
          }`,
          date_of_birth: date_of_birth ? date_of_birth : "--",
          email,
          phone: phone ? phone : "--",
          created_at: getFormattedData(created_at),
          balance: `${balance ?? 0} $`,
        })
      );

      setClients(tempClients);
    }
  }, [clientsData]);

  const columns = [
    { field: "id", title: "ID" },
    { field: "full_name", title: "Фамилия и Имя" },
    { field: "date_of_birth", title: "Дата рождения" },
    { field: "email", title: "Email" },
    { field: "phone", title: "Номер телефона" },
    { field: "created_at", title: "Дата регистрации" },
    { field: "balance", title: "Баланс" },
  ];

  const handleChangeFormField = debounce((value: string, name: string) => {
    console.log("render");
    setFormField({
      ...formData,
      [name]: value,
    });
  }, 500);

  const handleChange = debounce(handleChangeFormField, 500);

  return (
    <StyledUsersPage>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Фильтры</Accordion.Header>

          <Accordion.Body>
            <SelectGroupWrapper>
              <Input
                width="49%"
                label="Фамилия или Имя"
                type="text"
                name="fullname"
                handleChange={handleChangeFormField}
              />

              <Input
                width="49%"
                label="Дата рождения"
                type="text"
                name="date_of_birth"
                handleChange={handleChangeFormField}
              />

              <Input
                width="49%"
                label="Email"
                type="text"
                name="email"
                handleChange={handleChange}
              />

              <Input
                width="49%"
                label="Номер телефона"
                type="text"
                name="phone"
                handleChange={handleChangeFormField}
              />
            </SelectGroupWrapper>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {!!clientsData ? (
        <>
          <Table data={clients} columns={columns} />

          <Pagination
            withPerPage={true}
            lastPage={clientsData.last_page}
            currentPage={clientsData.current_page}
            perPage={perPage}
            onPageChange={setPage}
            onPerPageChange={setPerPage}
          />
        </>
      ) : (
        "Нет данных"
      )}
    </StyledUsersPage>
  );
}
