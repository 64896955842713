import {
  StyledCloseIcon,
  StyledWithdrawModal,
  WithdrawModalBody,
} from "./styles";
import { SyntheticEvent } from "react";

type WithdrawModalProps = {
  data: any;
  handleClose: () => void;
};

const WithdrawModal: React.FC<WithdrawModalProps> = ({
  data,
  handleClose: close,
}) => {
  const handleClose = (event: SyntheticEvent) => {
    //@ts-ignore
    if (["close", "cover"].includes(event.target.id)) {
      close();
    }
  };

  const { message } = data;

  return (
    <StyledWithdrawModal id="cover" onClick={handleClose}>
      <WithdrawModalBody>
        <StyledCloseIcon id="close" onClick={handleClose} />

        <span>{message}</span>
      </WithdrawModalBody>
    </StyledWithdrawModal>
  );
};

export default WithdrawModal;
