import styled from "styled-components";
import Modal from "react-bootstrap/Modal";

type PictureViewerWrapperProps = {
  backgroundImage: string;
  fullScreen?: boolean;
};

export const PictureViewerWrapper = styled.div<PictureViewerWrapperProps>`
  position: relative;
  background-image: url(${(props) => props?.backgroundImage});
  background-color: #000000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: ${(props) =>
    props.fullScreen ? "calc(100vh - 24px - 2rem)" : "90px"};
  cursor: ${(props) => (props.fullScreen ? "inherit" : "pointer")}; ;
`;

export const StyledModalHeader = styled(Modal.Header)({
  padding: "0.5rem 0.5rem 0 0.5rem",
  borderBottom: "none",
});

export const ImagesCounter = styled.div({
  position: "absolute",
  bottom: "0.5rem",
  right: "0.5rem",
  fontSize: "12px",
  fontWeight: "bold",
  minWidth: "1.6em",
  padding: "0 0.2em",
  textAlign: "center",
  border: "0.5px solid",
  borderRadius: "50%",
});
