import styled from "styled-components";
import background from "assets/userInfoBackground.png";

export const StyledUserInfo = styled.div({
  display: "flex",
  alignItems: "center",
  width: "360px",
  height: "130px",
  gap: "30px",
  fontFamily: "Stolzl",
  background: `url(${background})`,
  backgroundSize: "contain",
  padding: "12px 20px",
  backgroundRepeat: "no-repeat",

  "@media screen and (max-width: 1000px)": {
    width: "350px",
    height: "130px",
    backgroundSize: "contain",
  },
});

export const UserData = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
});

export const UserId = styled.div({
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "24px",
  color: "white",
});

export const UserName = styled.span({
  width: "100%",
  fontWeight: 500,
  fontSize: "22px",
  lineHeight: "32px",
  color: "#FFF",
});
