import { Link } from "react-router-dom";
import styled from "styled-components";
import inputBackground from "assets/inputBackground.png";
import buttonBackground from "assets/buttonBackground.png";
import socialBackground from "assets/socialBackground.png";
import activeSocialBackground from "assets/activeSocialBackground.png";
import backgroundPlate from "assets/plate.png";
import mobilePlate from "assets/mobilePlate.png";

export const StyledLoginForm = styled.div({
  width: "100%",
  display: "flex",
  flexDirection: "column",
});

export const LoginFormTitle = styled.div({
  display: "block",
  margin: "50px 0px 28px",
  fontFamily: "Benzin",
  fontSize: "31px",
  fontWeight: 600,
  lineHeight: "41px",
  textAlign: "center",
  color: "#FFFFFF",
  textTransform: "uppercase",

  "@media screen and (max-width: 1000px)": {
    fontSize: "26px",
    margin: "25px 0px 28px",
  },
});

export const InputsContainer = styled.div({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "10px",
  marginBottom: "20px",

  "@media screen and (max-width: 1000px)": {
    marginBottom: "15px",
  },
});

export const StyledLink = styled(Link)({
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "28px",
  textDecorationLine: "underline",
  color: "white",
  textAlign: "center",
  cursor: "pointer",
  fontFamily: "Stolzl",

  "&:hover": {
    color: "#677AEC",
  },
});

export const StyledSocialNetworks = styled.div({
  width: "100%",
  height: "70px",
  display: "grid",
  gridTemplateColumns: "repeat(2, 102px)",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "15px",
  columnGap: "15px",

  "@media screen and (max-width: 1000px)": {
    height: "75px",
    gridTemplateColumns: "repeat(2, 102px)",
    columnGap: "15px",
  },
});

export const IconWrapper = styled.div<{ interactive?: boolean }>(
  ({ interactive = true }) => ({
    position: "relative",
    boxSizing: "border-box",
    padding: "20px 0px",
    height: "100%",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: `url(${socialBackground})`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",

    "&:hover": {
      background: `url(${activeSocialBackground})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",

      "svg path:nth-child(2n+1)": {
        ...(interactive ? { fill: "white" } : {}),
      },
    },

    "@media screen and (max-width: 1000px)": {
      width: "103px",
      height: "71px",
    },
  })
);

export const CodeInputsContainer = styled.div({
  width: "100%",
  display: "flex",
  gap: "20px",
  marginBottom: "30px",
});

export const CodeInputWrapper = styled.div({
  display: "flex",
  gap: "10px",
  width: "100%",
});

export const StyledForm = styled.form({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const RedirectLinksWrapper = styled.div({
  display: "flex",
  justifyContent: "center",
  gap: "25px",
});

export const StyledInput = styled.div({
  width: "100%",
  flexDirection: "column",
  display: "flex",
});

export const InputWrapper = styled.div<{ error?: string }>(({ error }) => ({
  height: "54px",
  maxWidth: "500px",
  background: `url(${inputBackground})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  padding: "10px 45px",
  fontSize: "20px",
  lineHeight: "28px",
  width: "100%",
  borderRadius: "50px",
  display: "flex",
  alignItems: "center",
  outline: "none",
  border: error ? "1px solid rgba(255, 65, 65, 0.65);" : "none",
  position: "relative",

  "& svg": {
    width: "20px",
    height: "20px",

    path: {
      fill: error ? "rgba(255, 65, 65, 0.65);" : "default",
    },
  },

  "& input": {
    width: "100%",
    height: "100%",
    outline: "none",
    fontWeight: 400,
    fontSize: "20px",
    cursor: "pointer",
    margin: "0px 20px 0px 10px",
    border: "none",
    color: "white",

    "&::placeholder": {
      color: error ? "rgba(255, 65, 65, 0.65);" : "lightgrey",
    },
  },

  "@media screen and (max-width: 1000px)": {
    borderRadius: "30px",
    height: "52px",
    padding: "10px 25px",
  },
}));

export const AuthButton = styled.button<{
  width: string;
  height: string;
  margin: string;
  background: string;
  color: string;
  disabled: boolean;
}>(({ width, height, margin, color }) => ({
  background: `url(${buttonBackground})`,
  width,
  height,
  margin,
  color,
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "28px",
  borderRadius: "14px",
  border: "none",
  cursor: "pointer",
  alignSelf: "center",

  "@media screen and (max-width: 1000px)": {
    width: "190px",
    height: "60px",
    backgroundSize: "contain",
    marginBottom: "15px",
  },
}));

//////////////////

export const LoginPage = styled.div({
  minWidth: "100%",
  minHeight: "100%",
  display: "flex",
  justifyContent: "center",
  margin: "0px auto",
  alignSelf: "flex-end",

  "& input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus":
    {
      border: "none",
      "-webkit-text-fill-color": "#677AEC",
      "-webkit-box-shadow": "0 0 0px 1000px transparent inset",
      transition: "background-color 5000s ease-in-out 0s",
    },
});

export const LoginPageContent = styled.div({
  width: "100%",
  maxWidth: "1100px",
  display: "grid",
  justifyContent: "flex-end",
  gridTemplateColumns: "550px minmax(370px, 500px)",

  "@media screen and (max-width: 1000px)": {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gridTemplateRows: "290px 1fr",
    gridTemplateColumns: "none",
  },
});

export const LoginPageFooter = styled(LoginPageContent)({
  alignItems: "flex-start",
});

export const LoginFormWrapper = styled.div({
  width: "550px",
  height: "630px",
  display: "flex",
  gap: "15px",
  padding: "0px 100px",
  zIndex: 2,
  background: `url(${backgroundPlate})`,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top",
  alignSelf: "flex-end",

  "& input": {
    background: "transparent",
  },

  "@media screen and (max-width: 1000px)": {
    width: "375px",
    height: "560px",
    background: `url(${mobilePlate})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    padding: "20px",
    order: 2,
  },
});

export const ImageWrapper = styled.img({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-end",
  height: "100%",
  maxHeight: "850px",
  zIndex: 1,
  alignSelf: "flex-end",
  marginBottom: "70px",

  "@media screen and (max-width: 1000px)": {
    marginBottom: "-15px",
    height: "100%!important",
  },
});

export const ImageWrapperBlock = styled.div({
  display: "flex",
  height: "100%",
  overflow: "hidden",

  "@media screen and (max-width: 1000px)": {
    width: "375px",
    justifyContent: "center",
  },
});

export const ErrorMessageText = styled.div({
  position: "absolute",
  display: "flex",
  alignItems: "center",
  width: "max-content",
  height: "58px",
  borderRadius: "20px",
  padding: "0px 20px",
  background: "rgba(255, 51, 51, 0.65)",
  color: "white",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "28px",
  fontFamily: "Stolzl",
  left: "calc(100% + 15px)",
  gap: "20px",

  svg: {
    path: {
      fill: "white!important",
    },
  },

  "&:before": {
    content: '""',
    width: "15px",
    height: "15px",
    position: "absolute",
    left: "-15px",
    border: "6px solid transparent",
    borderRight: "6px solid rgba(255, 51, 51, 0.65)",
  },
});
