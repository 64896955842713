import {
  StyledVerificationDocumentModal,
  VerificationModalBody,
} from "./styles";

import { ReactComponent as ShieldIcon } from "assets/shieldIcon.svg";
import { useAppDispatch, useAppSelector, useTranslate } from "redux/hooks";
import {
  toggleVerificationDocumentModal,
  toggleVerificationModal,
} from "redux/stores/userSlice";
import { SyntheticEvent } from "react";
import { Controller, useForm } from "react-hook-form";
import SelectInput from "components/SelectInput";
import { DocumentsWrapper, InputFilesWrapper } from "modules/Profile/styles";
import FileInput from "components/FileInput";
import Button from "components/Button";
import buttonBackground from "assets/buttonBackground.png";
import { useUpdateKYCMutation } from "redux/api/userApi";

const VerificationDocumentModal = () => {
  const translate = useTranslate();
  const dispatch = useAppDispatch();
  const { userData } = useAppSelector("user");
  const [updateKYC, { isLoading }] = useUpdateKYCMutation();
  const { control, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues: {
      document_type: "passport",
      document1: null,
      document2: null,
    },
  });

  const documentTypes = [
    { value: "passport", label: translate("PASSPORT") },
    { value: "drivers_license", label: translate("DRIVERS_LICENSE") },
    { value: "national_id", label: translate("NATIONAL_ID") },
  ];

  const handleClose = (event: SyntheticEvent) => {
    //@ts-ignore
    if (["background"].includes(event.target.id)) {
      dispatch(toggleVerificationDocumentModal(false));
    }
  };

  const submit = async (data: any) => {
    const formData = new FormData();

    formData.append("document_type", data.document_type);
    formData.append("document[0]", data.document1);
    formData.append("document[1]", data.document2);

    await updateKYC(formData)
      .unwrap()
      .then((data) => {
        if (data.status === "error") {
          dispatch(toggleVerificationModal("failed"));
        }

        if (data.status === "success") {
          dispatch(toggleVerificationModal("success"));
        }

        dispatch(toggleVerificationDocumentModal(false));
      });
  };

  return (
    <StyledVerificationDocumentModal id="background" onClick={handleClose}>
      <VerificationModalBody>
        <p>ВЕРЕФИЦИРОВАТЬ аккаунт</p>

        <form id="verification" onSubmit={handleSubmit(submit)}>
          <DocumentsWrapper>
            <Controller
              control={control}
              name="document_type"
              render={({ field: { onChange, value } }) => (
                <SelectInput
                  icon={<ShieldIcon />}
                  value={value}
                  data={documentTypes}
                  handleChange={onChange}
                />
              )}
            />

            <InputFilesWrapper>
              <Controller
                control={control}
                rules={{
                  required: true,
                  validate: {
                    negative: (value: any) =>
                      value.size / 1024 < 1024 * 5 ||
                      "File size must be less than 5 MB",
                  },
                }}
                name="document1"
                render={({
                  field: { onChange, name, value },
                  fieldState: { error },
                }) => (
                  <FileInput
                    error={error?.message}
                    isDocumentLoaded={userData?.kyc}
                    placeholder={translate("MAX_FILE_SIZE")}
                    value={value}
                    name={name}
                    handleChange={onChange}
                  />
                )}
              />

              <Controller
                control={control}
                name="document2"
                rules={{
                  required: true,
                  validate: {
                    negative: (value: any) =>
                      value.size / 1024 < 1024 * 5 ||
                      "File size must be less than 5 MB",
                  },
                }}
                render={({
                  field: { onChange, name, value },
                  fieldState: { error },
                }) => (
                  <FileInput
                    error={error?.message}
                    isDocumentLoaded={userData?.kyc}
                    placeholder={translate("BACK_SIDE")}
                    placeholderWithIcon
                    value={value}
                    name={name}
                    handleChange={onChange}
                  />
                )}
              />
            </InputFilesWrapper>
          </DocumentsWrapper>
        </form>

        <Button
          form="verification"
          disabled={isLoading}
          background={`url(${buttonBackground})`}
          margin="10px auto"
          padding="0px 20px"
          text={isLoading ? "Загрузка..." : "Отправить документи"}
        />
      </VerificationModalBody>
    </StyledVerificationDocumentModal>
  );
};

export default VerificationDocumentModal;
