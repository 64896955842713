import { ErrorMessageText } from "components/Avatar/styles";
import React from "react";
import { InputWrapper, StyledInput } from "./styles";
import { ReactComponent as CircleSlash } from "../../assets/circleSlash.svg";

const CodeInput: React.FC<any> = ({ value, name, handleChange, error }) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(+event.target.value)) return;

    handleChange(event.target.value, name);
  };

  return (
    <InputWrapper>
      <StyledInput
        placeholder="******"
        autoFocus={true}
        name={String(name)}
        value={value}
        type="password"
        maxLength={6}
        onChange={handleOnChange}
        autoComplete="off"
        inputMode="numeric"
      />

      {error && (
        <ErrorMessageText>
          <CircleSlash fill="white" /> {error}
        </ErrorMessageText>
      )}
    </InputWrapper>
  );
};

export default CodeInput;
