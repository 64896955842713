import styled from "styled-components";
import plateSmall from "assets/plateSmall.png";
import plateMobile from "assets/plateMobile.png";
import plateMobileMiddle from "assets/plateMobileMiddle.png";

export const StyledProfile = styled.div({
  minWidth: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  padding: "0px 0px 30px",

  "@media screen and (max-width: 1200px)": {
    alignItems: "center",
  },
});

export const ContentInformationWrapper = styled.div({
  display: "flex",

  "@media screen and (max-width: 1000px)": {
    flexDirection: "column",
    alignItems: "center",
  },
});

export const UserInformationWrapper = styled.form({
  width: "100%",
  maxWidth: "490px",
  display: "flex",
  flexDirection: "column",
  gap: "25px",

  "@media screen and (max-width: 1000px)": {
    maxWidth: "100%",
    alignItems: "center",
  },
});

export const UserSecurityWrapper = styled.form({
  width: "100%",
  maxWidth: "665px",
  display: "flex",
  flexDirection: "column",
});

export const CardContentSmall = styled.div({
  width: "360px",
  height: "480px",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  background: `url(${plateSmall})`,
  backgroundSize: "cover",
  backgroundPosition: "-30px 0px",
  padding: "20px 30px 0px",

  "@media screen and (max-width: 1000px)": {
    width: "375px",
    height: "430px",
    background: `url(${plateMobile})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    padding: "20px 30px 0px",
    gap: "4px",
  },
});

export const CardContentLarge = styled(CardContentSmall)({
  width: "410px",
  height: "615px",
  background: `url(${plateMobileMiddle})`,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  gap: "3px",

  "@media screen and (max-width: 1000px)": {
    width: "375px",
  },
});

export const InputBlock = styled.div({
  minWidth: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "5px",

  "@media screen and (max-width: 1000px)": {
    gap: "4px",
  },
});

export const InputLabel = styled.div({
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#fff",
  fontFamily: "Stolzl",
  textAlign: "center",
});

export const StyledDateInput = styled.div({
  display: "flex",
  boxSizing: "border-box",
  gap: "5px",

  "& input": {
    width: "100%",
    border: "none",
    borderRadius: "14px",
    outline: "none",
    boxSizing: "border-box",
    fontWeight: 400,
    margin: "0px",
    textAlign: "center",

    "&::placeholder": {
      color: "#536886",
    },
  },
});

export const StyledFormsWrapper = styled.div({
  display: "flex",
  gap: "35px",

  "@media screen and (max-width: 1200px)": {
    gap: "15px",
  },

  "@media screen and (max-width: 1000px)": {
    flexDirection: "column",
    gap: "15px",
  },
});

export const EmailAndPhoneWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "10px",

  "@media screen and (max-width: 1000px)": {
    gap: "3px",
  },
});

export const InputFilesWrapper = styled.div({
  display: "grid",
  gridTemplateColumns: "minmax(1%, 50%) minmax(1%, 50%)",
  columnGap: "10px",
});

export const DocumentsWrapper = styled.div({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "5px",
});

export const ErrorText = styled.div({
  position: "absolute",
  display: "flex",
  alignItems: "center",
  width: "max-content",
  height: "58px",
  borderRadius: "20px",
  padding: "0px 20px",
  background: "rgb(255, 51, 51)",
  color: "white",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "28px",
  fontFamily: "Stolzl",
  left: "calc(100% + 15px)",
  gap: "20px",
  zIndex: 1,

  svg: {
    path: {
      fill: "white!important",
    },
  },

  "&:before": {
    content: '""',
    width: "15px",
    height: "15px",
    position: "absolute",
    left: "-15px",
    border: "6px solid transparent",
    borderRight: "6px solid rgb(255, 51, 51)",
  },
});

export const ErrorYearMessage = styled.span({
  color: "#FF3333A6",
  margin: "0px auto",
  fontSize: "14px",
  lineHeight: "14px",
});
