// components
import { Avatar } from "components/index";
import SideBarItem from "./SideBarItem";
// styles
import { StyledSideBar, SideBarItemWrapper } from "./styles";
// icon
import { ReactComponent as SettingsIcon } from "assets/settingsIcon.svg";
import { ReactComponent as WalletIcon } from "assets/walletIcon.svg";
import { ReactComponent as GameIcon } from "assets/gameIcon.svg";
import { ReactComponent as DocumentIcon } from "assets/documentIcon.svg";
import { ReactComponent as CardIcon } from "assets/cardIcon.svg";
import { ReactComponent as LogoutIcon } from "assets/logoutIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useTranslate } from "redux/hooks";
import { setUserData } from "redux/stores/userSlice";

const SideBar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const translate = useTranslate();

  const handleLogout = () => {
    window.localStorage.removeItem("token");
    dispatch(setUserData(null));
    navigate("/");
  };

  const iconProps = [
    {
      icon: <GameIcon />,
      pathTo: "/game",
      label: translate("GAME"),
    },
    {
      icon: <WalletIcon />,
      pathTo: "/wallet",
      label: translate("WALLET_LABEL"),
    },
    // {
    //   icon: <DocumentIcon />,
    //   pathTo: "/wallet/details",
    // },
    {
      icon: <CardIcon />,
      pathTo: "/wallet/history",
      label: translate("WALLET_HISTORY"),
    },
    {
      icon: <SettingsIcon />,
      pathTo: "/profile",
      label: translate("PERSONAL_AREA_LABEL"),
    },
  ];

  return (
    <StyledSideBar>
      <Avatar withIcon={false} />

      <SideBarItemWrapper>
        {iconProps.map(({ icon, pathTo, label }, index) => (
          <SideBarItem
            isSelected={pathname === pathTo}
            key={index}
            icon={icon}
            pathTo={pathTo}
            label={label}
            withTooltip
          />
        ))}
      </SideBarItemWrapper>

      <LogoutIcon cursor="pointer" onClick={handleLogout} />
    </StyledSideBar>
  );
};

export default SideBar;
