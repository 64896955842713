import BalanceCard from "components/BalanceCard";
import { useAppSelector } from "redux/hooks";
import { DepositForm } from "../Form";
import { DepositWrapper, FormWrapper } from "./styles";

const Deposit = () => {
  const { userData } = useAppSelector("user");

  return (
    <DepositWrapper>
      <BalanceCard balance={`${Number(userData?.balance).toFixed(2)} USD`} />

      <FormWrapper>
        <DepositForm />
      </FormWrapper>
    </DepositWrapper>
  );
};

export default Deposit;
