import { ChangeEvent } from "react";
import { Form } from "react-bootstrap";
import { SelectWrapper } from "./styles";

const SelectInput: React.FC<AdminInputProps> = ({
  width = "100%",
  type,
  name,
  label,
  value,
  options,
  handleChange,
  required,
}) => {
  const onChange = (event: ChangeEvent<HTMLSelectElement | any>) => {
    const { value, name } = event.target;

    handleChange(value, name);
  };

  return (
    <SelectWrapper width={width}>
      {label && <span>{label}</span>}

      {type === "select" ? (
        <Form.Select
          required={required}
          name={name}
          value={value}
          onChange={onChange}
        >
          {options.map(({ value, label }) => (
            <option value={value}>{label}</option>
          ))}
        </Form.Select>
      ) : (
        <Form.Control
          required={required}
          name={name}
          value={value}
          onChange={onChange}
        />
      )}
    </SelectWrapper>
  );
};

export default SelectInput;
