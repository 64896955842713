import styled from "styled-components";

export const StyledDocumentModal = styled.div({
  display: "flex",
  position: "fixed",
  width: "100vw",
  height: "100vh",
  alignItems: "center",
  justifyContent: "center",
  backdropFilter: "blur(5px)",
  zIndex: 4,

  "@media screen and (max-width: 1000px)": {
    padding: "50px 50px 50px",
  },
});
