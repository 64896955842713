import styled from "styled-components";
import withdrawSmallBackground from "assets/withdrawSmallBackground.png";
import withdrawMiddleBackground from "assets/withdrawMiddleBackground.png";
import withdrawLargeBackground from "assets/withdrawLargeBackground.png";
import withdrawMobileBackground from "assets/withdrawMobileBackground.png";
import walletDetailsBackground from "assets/walletDetailsBackground.png";
import { FieldError } from "react-hook-form";

const widths = {
  small: {
    mobile: "322px",
    tab: "100%",
    desktop: "100%",
  },
  large: {
    mobile: "322px",
    tab: "100%",
    desktop: "100%",
  },
};

const background = {
  small: {
    mobile: `url(${withdrawMobileBackground})`,
    tab: `url(${withdrawSmallBackground})`,
    desktop: `url(${withdrawSmallBackground})`,
  },
  large: {
    mobile: `url(${withdrawMobileBackground})`,
    tab: `url(${withdrawMiddleBackground})`,
    desktop: `url(${withdrawLargeBackground})`,
  },
};

const walletDetailsSizes = {
  small: {
    mobile: "86px",
    tab: "95px",
    desktop: "80px",
  },
  large: {
    mobile: "86px",
    tab: "112px",
    desktop: "125px",
  },
};

export const CardBody = styled.div({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
});

export const CardTitle = styled.div({
  display: "block",
  fontSize: "16px",
  textAlign: "center",
  textTransform: "uppercase",
  color: "#FFFFFF",
  fontFamily: "Benzin",
});

export const CardAmount = styled.div<{
  size: keyof typeof background;
  error?: string | FieldError;
}>(({ size, error }) => ({
  borderRadius: "35px",
  padding: "30px 20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "20px",
  height: "110px",
  width: widths[size].desktop,
  background: background[size].desktop,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "0px -6px",
  border: error ? "1px solid rgba(255, 65, 65, 0.65);" : "none",

  "& input": {
    fontSize: "41px",
    textAlign: "center",
  },

  "& div div": {
    background: "none!important",
  },

  "@media screen and (max-width: 1200px)": {
    background: background[size].tab,
    width: widths[size].tab,
    backgroundSize: "cover",
    height: "110px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },

  "@media screen and (max-width: 1000px)": {
    background: background[size].mobile,
    width: widths[size].mobile,
    height: "110px",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
}));

export const CardAmountTitle = styled.div<{ margin?: string }>(
  ({ margin = "" }) => ({
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#FFFFFF",
    display: "block",
    marginBottom: "10px",
    fontFamily: "Stolzl",
    margin,
  })
);

export const WalletDetails = styled.div<{
  size: "small" | "large";
}>(({ size }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: walletDetailsSizes[size].desktop,
  background: `url(${walletDetailsBackground})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  cursor: "pointer",
  // paddingLeft: "60px",

  "@media screen and (max-width: 1200px)": {
    height: walletDetailsSizes[size].tab,
  },
  "@media screen and (max-width: 1000px)": {
    height: walletDetailsSizes[size].mobile,
  },
}));

export const WalletDetailsContent = styled.div({
  display: "flex",
  justifyContent: "center",
  gap: "15px",
});

export const IconWrapper = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minWidth: "50px",
  minHeight: "50px",
  background: "#8797F4",
  borderRadius: "5px",

  "@media screen and (max-width: 1200px)": {
    width: "50px",
    height: "50px",
  },
});

export const WalletDetailsText = styled.div({
  // 15.6
  width: "211px",
  display: "flex",
  alignItems: "center",
  fontFamily: "Benzin",
  fontSize: "13px",
  lineHeight: "24px",
  color: "#FFFFFF",

  "@media screen and (max-width: 1000px)": {
    width: "200px",
    fontSize: "12px",
    lineHeight: "13px",
  },
});

export const WarningText = styled.span({
  alignSelf: "center",
  width: "385px",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "25px",
  textAlign: "center",
  color: "#C3CEDB",
  marginTop: "15px",

  "@media screen and (max-width: 1000px)": {
    width: "320px",
  },
});

export const ErrorMessage = styled.span({
  color: "#fc6f6f",
  fontFamily: "Stolzl",
});
