import styled from "styled-components";
import verificationIconBackground from "assets/verificationIconBackground.png";
import documentVerificationBackground from "assets/documentVerificationBackground.png";

export const StyledPasswordModal = styled.div({
  position: "fixed",
  width: "100vw",
  height: "100vh",
  backdropFilter: "blur(5px)",
  zIndex: 4,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const VerificationModalBody = styled.div({
  width: "485px",
  height: "425px",
  background: `url(${documentVerificationBackground})`,
  backgroundSize: "cover",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  padding: "35px 60px 90px",
  gap: "20px",

  "& p": {
    marginTop: "70px",
    fontFamily: "Stolzl",
    textAlign: "center",
    fontSize: "31px",
    color: "white",
    textTransform: "uppercase",
    fontWeight: 900,
  },

  "@media screen and (max-width: 1000px)": {
    width: "360px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    padding: "20px 60px 15px",
    height: "320px",
    gap: "10px",
    justifyContent: "flex-start",

    "& p": {
      fontSize: "21px",
      marginBottom: "0px",
    },
  },
});

export const VerificationIconWrapper = styled.div({
  width: "93px",
  height: "93px",
  background: `url(${verificationIconBackground})`,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
