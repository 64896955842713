export const EN = {
  PERSONAL_AREA: "PERSONAL AREA",
  DATE_OF_BIRTH: "Date of Birth",
  PERSONAL_INFORMATION: "Personal information",
  ACCOUNT_CURRENCY: "Account currency",
  PHONE_NUMBER: "Phone number",
  DAY: "Day",
  MONTH: "Month",
  YEAR: "Year",
  OLD_PASSWORD: "Old password",
  NEW_PASSWORD: "New password",
  REGISTERED: "Registered",
  CHANGE: "Change",
  LOADING: "Loading",
  FIRST_NAME: "First name",
  LAST_NAME: "Last name",
  SAFETY: "Safety",
  WALLET: "WALLET",
  YOUR_DETAILS: "Details",
  WITHDRAW_HISTORY: "History",
  YOUR_BALANCE: "Your balance",
  REPLENISH_FUNDS: "Replenish funds",
  TOP_UP_AMOUNT: "Top-up amount",
  SELECT_REPLENISHMENT_DETAILS: "Select replenishment details",
  CONFIRM_ENTRY: "Confirm entry",
  CONFIRM_ENTRY_VIA_FIAT: "Confirm entry via Fiat",
  WITHDRAWAL: "WITHDRAWAL",
  WITHDRAWAL_AMOUNT: "Withdrawal amount",
  SELECT_DETAILS_FOR_WITHDRAWAL: "Select crypto for withdrawal",
  SELECT_WALLET_FOR_WITHDRAWAL: "Select wallet for withdrawal",
  CONFIRM_WITHDRAWAL: "Confirm Withdrawal",
  TYPE: "Type",
  DATE: "Date",
  DETAILS: "Details",
  AMOUNT: "Amount",
  STATUS: "Status",
  ID: "id",
  CONTACT_DETAILS: "Contact details",
  SAVE: "Save changes",
  ID_CONFIRMATION: "ID confirmation",
  SECURITY: "Security",
  MAX_FILE_SIZE: "Max file size 5MB (jpg/png)",
  BACK_SIDE: "Back side",
  DOCUMENT_ATTACHED: "Document attached",
  PASSPORT: "Passport",
  DRIVERS_LICENSE: "Drivers license",
  NATIONAL_ID: "National ID",
  NOTIFICATION_EMPTY_STATE: "You don`t have new notifications",
  WARNING_WITHDRAWAL_TEXT:
    "Attention! Choose a network and check the correctness of the entered data, otherwise your funds may be lost",
  VERIFICATION_APPROVED: "Documents approved",
  VERIFICATION_PENDING: "Documents waiting verification",
  VERIFICATION_REJECTED: "Documents rejected",
  GAME: "Game",
  PERSONAL_AREA_LABEL: "Personal area",
  WALLET_HISTORY: "Wallet history",
  WALLET_LABEL: "Wallet",
  TABLE_EMPTY_STATE: "Your transactions will be displayed here",
  ERROR_EMPTY_FIELD: "This field can`t be empty!",
  ERROR_LENGTH_FIELD: "This field can`t be less 8 and more than 30 symbols",
  ERROR_EQUALS_PASSWORD: "Passwords must be equal!",
  CREATE_PASSWORD: "Create a password",
  CONFIRM_PASSWORD: "Confirm password",
  MIN_DEPOSIT_AMOUNT: "Minimum deposit amount is $10",
  NOTIFICATIONS: "Notifications",
  VERIFICATIONS: "Verifications",
  VERIFY: "Verify",
  VERIFICATION_FAILED: "Verification failed!",
  DELETE_ALL: "Delete all",
  PASSWORD_STATUS_SUCCESS: "Your password has been successfully changed",
  PASSWORD_STATUS_FAIL: "You have entered an invalid password",
  PASSWORD_INVALID: "New password cannot be the same as the old password.",
  PASSPORT_UPLOAD_SUCCESS_TITLE: "Passport loaded!",
  PASSPORT_UPLOAD_FAILED_TITLE: "Passport was not loaded!",
  PASSPORT_UPLOAD_FAILED:
    "Your documents were not uploaded when the file size exceeds 4 megabytes",
  PASSPORT_UPLOAD_SUCCESS:
    "Please wait for verification before proceeding further..",
  PASSWORD_WHITESPACE_ERROR: "Password should not start or end with a space",
  REQUIRED_FIELD: "This field can`t be empty",
  ALPHABET_EMAIL_ERROR:
    "The e-mail must contain only Latin letters and numbers",
  EMAIL_LENGTH_ERROR: "Email must be more than 5 and less than 50 characters",
  CODE_LENGTH_ERROR: "Code must be 6 symbols",
  BD_VALIDATION: "You must be over 18 years old",
  PAY: "Pay",
  GENERATE_NEW_WALLET: "Generate new wallet",
};
