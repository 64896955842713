import { StyledSocialNetworks, IconWrapper } from "./styles";
// import { ReactComponent as GoogleIcon } from "assets/googleIcon.svg";
import { ReactComponent as FacebookIcon } from "assets/faceBookIcon.svg";
import { ReactComponent as TwitterIcon } from "assets/twitterIcon.svg";

const SocialNetworks = () => {
  return (
    <StyledSocialNetworks>
      {/* <IconWrapper>
        <GoogleIcon width={30} height={30} name="googleIcon" />
      </IconWrapper> */}

      <IconWrapper interactive={false}>
        <FacebookIcon width={30} height={30} name="faceBookIcon" />
      </IconWrapper>

      <IconWrapper>
        <TwitterIcon width={30} height={30} name="twitterIcon" />
      </IconWrapper>
    </StyledSocialNetworks>
  );
};

export default SocialNetworks;
