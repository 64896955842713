import styled from "styled-components";
import inputBackground from "assets/inputBackground.png";
import inputMiddleBackground from "assets/inputMiddleBackground.png";

export const StyledPhoneInput = styled.div({
  width: "100%",
  display: "flex",
});

export const PhoneInputWrapper = styled.div({
  height: "56px",
  maxWidth: "500px",
  background: `url(${inputBackground})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  padding: "10px 25px",
  fontSize: "20px",
  lineHeight: "28px",
  inputBorder: "none",
  width: "100%",
  borderRadius: "14px",
  display: "flex",
  alignItems: "center",
  outline: "none",

  "& svg": {
    width: "20px",
    height: "20px",
  },

  "& div": {
    "& .flag-dropdown": {
      border: "none",
      background: "transparent!important",

      "& .selected-flag": {
        background: "transparent!important",
      },
    },

    "& .country-list": {
      border: "none",
      background: "rgb(66, 67, 130)",
      padding: "10px 20px",
      borderRadius: "12px",
      bottom: "35px",
      left: "-25px",

      "& .country": {
        background: "none",
        color: "white",

        "&.highlight": {
          background: `url(${inputMiddleBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "center",

          "&::before": {
            background: `initial`,

            content: '">"',
            marginRight: "10px",
          },
        },

        "&:hover": {
          fontWeight: 900,
          background: `url(${inputMiddleBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        },

        "& .dial-code": {
          color: "lightgrey",
        },
      },
    },

    "& .form-control": {
      background: "transparent",
      width: "100%",
      height: "100%",
      outline: "none",
      fontWeight: 400,
      fontSize: "20px",
      cursor: "pointer",
      margin: "0px 20px 0px 0px",
      border: "none",
      color: "white",

      "&:focus": {
        border: "none",
        boxShadow: "none",
      },

      "&::placeholder": {
        color: "#536886",
      },
    },
  },

  "@media screen and (max-width: 1200px)": {
    background: `url(${inputMiddleBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },

  "@media screen and (max-width: 1000px)": {
    height: "56px",
    width: "100%",
    backgroundSize: "cover",
  },
});
