import {
  ActualPrice,
  AttentionText,
  CopyBoard,
  CryptoCoin,
  CryptoCoinInfo,
  CryptoName,
  CryptosWrapper,
  CryptoTitle,
  DepositDetails,
  DepositInfo,
  DetailsWrapper,
  ErrorMessage,
  HelpText,
  InputsWrapper,
  ModalTitle,
  QRcodeWrapper,
  Seconds,
  StyledCloseIcon,
  StyledDepositInput,
  StyledWalletDetailsModal,
  WalletBackground,
  WalletDetailsModalBody,
} from "./styles";
import { ReactComponent as EthIcon } from "assets/eth.svg";
import { ReactComponent as UsdtIcon } from "assets/usdt.svg";
import { ReactComponent as BtcIcon } from "assets/btc.svg";
import { ReactComponent as CopyIcon } from "assets/copyIcon.svg";
import buttonBackground from "assets/activeRadioBackground.png";
import {
  useAppDispatch,
  useAppSelector,
  useCountdown,
  useTranslate,
} from "redux/hooks";
import {
  changeDeposit,
  toggleWalletDepositModal,
} from "redux/stores/userSlice";
import {
  useCreateTransactionMutation,
  useGetDepositMethodsQuery,
} from "redux/api/userApi";
import {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button } from "components/index";
import QRCode from "react-qr-code";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const icons = {
  USDTTRC20: <UsdtIcon />,
  ETH: <EthIcon />,
  BTC: <BtcIcon />,
};

const HADRCODE_FOR_USDT = {
  symbol: "USDTTRC20",
  price: "1",
};

const VerificationModal = () => {
  const { depositAmount } = useAppSelector("user");
  const [isTextCopied, setCopiedStatus] = useState(false);
  const [selectedCrypto, setSelectedCrypto] = useState<any>();
  const [coins, setCoinsPrice] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const seconds = useCountdown(60, true);
  const [amount, setAmount] = useState(depositAmount);
  const { data: paymentMethods } = useGetDepositMethodsQuery(null);
  const [createTransaction, { isLoading }] = useCreateTransactionMutation();
  const [transaction, setTransaction] = useState<any>(null);
  const translate = useTranslate();

  const getCoinsPrice = async () => {
    const formattedMethods = paymentMethods.data.reduce(
      (acc: any, cur: any) => {
        if (cur.symbol === "USDTTRC20") {
          return {
            methods: [...acc.methods, cur],
            pairs: [...acc.pairs],
            usdt: { ...cur, ...HADRCODE_FOR_USDT },
          };
        }

        return {
          methods: [...acc.methods, cur],
          pairs: [...acc.pairs, `"${cur.symbol}USDT"`],
          usdt: null,
        };
      },
      { methods: [], pairs: [], usdt: null }
    );

    await fetch(
      `https://api.binance.com/api/v3/ticker/price?symbols=[${formattedMethods.pairs}]`
    )
      .then((response) => response.json())
      .then((response) => {
        const formattedResponse = response.map((coin: any) => ({
          ...coin,
          ...formattedMethods.methods.find((method: any) =>
            coin.symbol.includes(method.symbol)
          ),
        }));

        if (formattedMethods.usdt) {
          formattedResponse.push(formattedMethods.usdt);
        }

        if (!selectedCrypto) {
          setSelectedCrypto(formattedResponse[0]);
        }

        setCoinsPrice(formattedResponse);
      });
  };

  useEffect(() => {
    if ((seconds === 0 || !coins.length) && paymentMethods) {
      getCoinsPrice();
    }
  }, [seconds, coins, paymentMethods]);

  const handleClose = (event: SyntheticEvent) => {
    //@ts-ignore
    if (["close", "cover"].includes(event.target.id)) {
      dispatch(toggleWalletDepositModal(false));
      dispatch(changeDeposit(""));
    }
  };

  const copyboard = (target: string) => {
    const element = document.getElementById(`${target}`) as HTMLElement;
    //@ts-ignore
    navigator.clipboard.writeText(element?.innerHTML);

    setCopiedStatus(true);

    setTimeout(() => {
      setCopiedStatus(false);
    }, 1000);
  };

  const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (Number.isNaN(+value - 1)) return;

    setAmount(value);
  };

  const handleSubmit = async () => {
    setTransaction(null);

    await createTransaction({
      payment_method_id: selectedCrypto.id,
      amount: +amount,
      gateway: "switchere",
      fiat: false,
    })
      .unwrap()
      .then((response) => {
        if (response.status === "success") {
          setTransaction({ status: "success", data: response.payment });
        }

        if (response.status === "error") {
          setTransaction({ status: "error", data: "Something went wrong" });
        }
      });
  };

  const handleSelect = (data: any) => {
    setTransaction(null);

    setSelectedCrypto(data);
  };

  const buttonText = useMemo(() => {
    if (transaction?.status) {
      return translate("GENERATE_NEW_WALLET");
    }

    return translate(isLoading ? "LOADING" : "PAY");
  }, [isLoading, transaction]);

  return (
    <StyledWalletDetailsModal id="cover" onClick={handleClose}>
      <WalletDetailsModalBody>
        <StyledCloseIcon
          id="close"
          onClick={() => dispatch(toggleWalletDepositModal(false))}
        />

        <ModalTitle>пополнить средства</ModalTitle>

        <CryptosWrapper>
          {coins.map((coin) => (
            <CryptoCoin
              onClick={() => handleSelect(coin)}
              key={coin.id}
              isActive={selectedCrypto.id === coin.id}
            >
              <CryptoCoinInfo>
                {/* @ts-ignore */}
                {icons[coin.symbol]}

                <CryptoName>{coin.name}</CryptoName>
              </CryptoCoinInfo>

              <CryptoCoinInfo>
                <CryptoName>1 {coin.symbol}</CryptoName>

                <CryptoName>-</CryptoName>

                <CryptoName>{Number(coin.price).toFixed(2)}$</CryptoName>
              </CryptoCoinInfo>
            </CryptoCoin>
          ))}
        </CryptosWrapper>

        <AttentionText>
          Указанный выбор кошелька принимает переводы в сети{" "}
          {selectedCrypto?.name}. При переводе в другую сеть вы рискуете
          потерять свои деньги
        </AttentionText>

        <DetailsWrapper>
          <DepositDetails>
            <QRcodeWrapper>
              {transaction && transaction.status === "success" ? (
                <QRCode
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={transaction.data.crypto_address}
                />
              ) : null}
            </QRcodeWrapper>

            <DepositInfo>
              <CryptoTitle>{selectedCrypto?.name}</CryptoTitle>

              <HelpText>
                Используйте конвертер для расчета нужной суммы в криптовалюте.{" "}
                <ActualPrice device="desktop">
                  Актуальность курса: <Seconds>{seconds} сек</Seconds>
                </ActualPrice>
              </HelpText>

              <InputsWrapper device="desktop">
                <StyledDepositInput>
                  <input
                    type="text"
                    value={amount}
                    onChange={handleAmountChange}
                  />

                  <span>USD</span>
                </StyledDepositInput>

                <span>~</span>

                <StyledDepositInput>
                  <p id="deposit">
                    {selectedCrypto
                      ? (1 / +selectedCrypto.price) * +amount
                      : ""}
                  </p>

                  <span>{selectedCrypto?.symbol}</span>

                  <OverlayTrigger
                    overlay={
                      <Tooltip>{isTextCopied ? "Copied" : "Copy"}</Tooltip>
                    }
                  >
                    <CopyBoard onClick={() => copyboard("deposit")}>
                      <CopyIcon />
                    </CopyBoard>
                  </OverlayTrigger>
                </StyledDepositInput>
              </InputsWrapper>
            </DepositInfo>
          </DepositDetails>

          <InputsWrapper device="mobile">
            <StyledDepositInput>
              <input type="text" value={amount} onChange={handleAmountChange} />

              <span>USD</span>
            </StyledDepositInput>

            <span>~</span>

            <StyledDepositInput>
              <p id="deposit">
                {selectedCrypto ? (1 / +selectedCrypto.price) * +amount : ""}
              </p>

              <span>{selectedCrypto?.symbol}</span>

              <OverlayTrigger
                overlay={<Tooltip>{isTextCopied ? "Copied" : "Copy"}</Tooltip>}
              >
                <CopyBoard onClick={() => copyboard("deposit")}>
                  <CopyIcon />
                </CopyBoard>
              </OverlayTrigger>
            </StyledDepositInput>
          </InputsWrapper>
        </DetailsWrapper>

        {transaction && transaction.status === "success" && (
          <WalletBackground>
            {/* @ts-ignore */}
            {icons[transaction?.data.payin_currency]}
            <span id="wallet">{transaction?.data?.crypto_address}</span>

            <OverlayTrigger
              overlay={<Tooltip>{isTextCopied ? "Copied" : "Copy"}</Tooltip>}
            >
              <CopyBoard onClick={() => copyboard("wallet")}>
                <CopyIcon />
              </CopyBoard>
            </OverlayTrigger>
          </WalletBackground>
        )}

        {transaction && transaction.status === "error" && (
          <ErrorMessage>{transaction.data}</ErrorMessage>
        )}

        <Button
          disabled={isLoading}
          width="210px"
          padding="0px"
          margin="0px 0px 20px"
          background={`url(${buttonBackground})`}
          text={buttonText}
          onClick={handleSubmit}
        />

        <ActualPrice device="mobile">
          Актуальность курса: <Seconds>{seconds} сек</Seconds>
        </ActualPrice>
      </WalletDetailsModalBody>
    </StyledWalletDetailsModal>
  );
};

export default VerificationModal;
