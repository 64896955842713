import AuthButton from "./AuthButton";
import AuthInput from "./AuthInput";
// styles
import {
  StyledLoginForm,
  LoginFormTitle,
  InputsContainer,
  StyledLink,
  StyledForm,
} from "./styles";
import { ReactComponent as EmailIcon } from "assets/emailIcon.svg";
import { ReactComponent as UserIcon } from "assets/userIcon.svg";
import { ReactComponent as EyeIcon } from "assets/eyeIcon.svg";
import { useRegistrationMutation } from "redux/api/AuthApi";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslate } from "redux/hooks";

const LoginForm = () => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const [inputType, setInputType] = useState("password");
  const { handleSubmit, control, setError, clearErrors } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      email: "",
      password: "",
      "confirm-password": "",
    },
  });
  const [registration, { isLoading }] = useRegistrationMutation();

  const typeToggle = () => {
    setInputType(inputType === "password" ? "text" : "password");
  };

  const submit = async (data: RegisterUserProps) => {
    const response = await registration(data)
      .unwrap()
      .then((response) => {
        if (response.code === 400) {
          if (response?.errors?.email) {
            setError("email", { message: response.errors.email[0] });
          }
        }

        return response;
      });

    if (response.status === "success") {
      window.localStorage.setItem(
        "token",
        `Bearer ${response.data.user.token}`
      );

      navigate("/game");
    }
  };

  return (
    <StyledLoginForm>
      <LoginFormTitle>Регистрация</LoginFormTitle>

      <StyledForm onSubmit={handleSubmit(submit)}>
        <InputsContainer>
          <Controller
            control={control}
            rules={{
              required: translate("ERROR_EMPTY_FIELD"),
              validate: {
                alphabet: (value) =>
                  /^[a-z0-9\.]+@([\w-]+\.)+[\w-]{2,4}$/gi.test(value) ||
                  translate("ALPHABET_EMAIL_ERROR"),
                length: (value) =>
                  (value.length >= 5 && value.length <= 50) ||
                  translate("EMAIL_LENGTH_ERROR"),
              },
            }}
            name="email"
            render={({
              field: { name, value, onChange },
              fieldState: { error },
            }) => {
              return (
                <AuthInput
                  placeholder="E-mail"
                  name={name}
                  value={value}
                  error={error?.message}
                  inputType="email"
                  handleChange={onChange}
                  leftIcon={<EmailIcon />}
                />
              );
            }}
          />

          <Controller
            control={control}
            name="password"
            rules={{
              required: translate("ERROR_EMPTY_FIELD"),
              validate: {
                length: (value) =>
                  (value.length >= 8 && value.length <= 30) ||
                  translate("ERROR_LENGTH_FIELD"),
                whiteSpace: (value) =>
                  !/^\s+|\s+$/gi.test(value) ||
                  translate("PASSWORD_WHITESPACE_ERROR"),
              },
            }}
            render={({
              field: { name, value, onChange },
              fieldState: { error },
              formState,
            }) => (
              <AuthInput
                placeholder={translate("CREATE_PASSWORD")}
                name={name}
                value={value}
                error={error?.message}
                inputType={inputType}
                handleChange={(value: string) => {
                  if (
                    formState.errors?.["confirm-password"]?.type === "equals"
                  ) {
                    clearErrors("confirm-password");
                  }

                  onChange(value);
                }}
                leftIcon={<UserIcon />}
                rightIcon={<EyeIcon cursor="pointer" onClick={typeToggle} />}
              />
            )}
          />

          <Controller
            control={control}
            name="confirm-password"
            rules={{
              required: translate("ERROR_EMPTY_FIELD"),
              validate: {
                equals: (value, formValues) =>
                  value === formValues.password ||
                  translate("ERROR_EQUALS_PASSWORD"),
                length: (value) =>
                  (value.length >= 8 && value.length <= 30) ||
                  translate("ERROR_LENGTH_FIELD"),
                whiteSpace: (value) =>
                  !/^\s+|\s+$/gi.test(value) ||
                  translate("PASSWORD_WHITESPACE_ERROR"),
              },
            }}
            render={({
              field: { name, value, onChange },
              fieldState: { error },
            }) => (
              <AuthInput
                placeholder={translate("CONFIRM_PASSWORD")}
                name={name}
                value={value}
                error={error?.message}
                inputType={inputType}
                handleChange={onChange}
                leftIcon={<UserIcon />}
                rightIcon={<EyeIcon cursor="pointer" onClick={typeToggle} />}
              />
            )}
          />
        </InputsContainer>

        <AuthButton
          width="250px"
          height="60px"
          margin="0px auto 25px"
          onClick={() => {}}
          text={isLoading ? "Загрузка.." : "Зарегистрироваться"}
        />
      </StyledForm>

      <StyledLink to="/login">
        Есть аккаунт <b>&rsaquo;</b>
      </StyledLink>
    </StyledLoginForm>
  );
};

export default LoginForm;
