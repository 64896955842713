import styled from "styled-components";

export const SelectWrapper = styled.div<{ width?: string; minWidth?: string }>(
  ({ width = "100%", minWidth }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    width,
    minWidth,
  })
);
