import styled from "styled-components";
import background from "assets/mobileNotificationBackground.png";
import { ReactComponent as ArrowLeft } from "assets/arrowLeftIcon.svg";

export const StyledPaginationWrapper = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "20px",
});

export const ArrowIcon = styled(ArrowLeft)<{ side?: "left" | "right" }>(
  ({ side = "left" }) => ({
    transform: side === "left" ? "none" : "rotate(180deg)",
  })
);

export const ArrowButton = styled.div({
  width: "40px",
  height: "40px",
  background: `url(${background})`,
  backgroundSize: "contain",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
});

export const Page = styled.div<{ isSelected: boolean }>(({ isSelected }) => ({
  fontFamily: "Stolzl",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  color: isSelected ? "white" : "#94B0C8",
  cursor: "pointer",
}));
