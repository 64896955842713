import { Avatar } from "components/index";
import { useAppSelector } from "redux/hooks";
import { StyledUserInfo, UserData, UserId, UserName } from "./styles";

const UserInfo = () => {
  const { userData } = useAppSelector("user");

  if (!userData) return null;

  return (
    <StyledUserInfo>
      <Avatar width="100px" height="100px" />

      <UserData>
        <UserId>ID: {userData.id}</UserId>

        <UserName>
          {userData.last_name} <br />
          {userData.first_name}
        </UserName>
      </UserData>
    </StyledUserInfo>
  );
};

export default UserInfo;
