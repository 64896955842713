import { Button, Input, SelectInput } from "components/index";
import { Controller, useForm } from "react-hook-form";
import {
  CardAmount,
  CardAmountTitle,
  CardBody,
  CardTitle,
  WarningText,
} from "../styles";
import { ReactComponent as EthIcon } from "assets/eth.svg";
import { ReactComponent as UsdtIcon } from "assets/usdt.svg";
import { ReactComponent as BtcIcon } from "assets/btc.svg";
import { useTranslate } from "redux/hooks";
import buttonBackground from "assets/buttonBackground.png";
import { useCreateWithdrawalMutation } from "redux/api/userApi";
import { useState } from "react";
import WithdrawModal from "components/ModalContainer/modals/WithdrawModal";

const icons = {
  usdt: <UsdtIcon />,
  eth: <EthIcon />,
  btc: <BtcIcon />,
};

const WithdrawForm = () => {
  const translate = useTranslate();
  const [createWithdrawal, { isLoading }] = useCreateWithdrawalMutation();
  const [transaction, setTransaction] = useState<any>(null);

  const {
    handleSubmit: submit,
    control: secondControl,
    watch,
  } = useForm({
    defaultValues: {
      withdrawAmount: "",
      cryptoCoin: "usdt",
      wallet: "",
    },
  });

  const handleWithdrawOnSubmitForm = async (data: any) => {
    await createWithdrawal({
      amount: data.withdrawAmount,
      wallet: data.wallet,
    })
      .unwrap()
      .then((response) => {
        setTransaction(response);
      });
  };

  const data = [
    { value: "usdt", label: "USDT TRC-20" },
    { value: "btc", label: "Bitcoin BTC" },
    { value: "eth", label: "Ethereum ETH" },
  ];

  return (
    <>
      {transaction && (
        <WithdrawModal
          data={transaction}
          handleClose={() => setTransaction(null)}
        />
      )}

      <form onSubmit={submit(handleWithdrawOnSubmitForm)}>
        <CardBody>
          <Controller
            control={secondControl}
            name="withdrawAmount"
            rules={{ required: true }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => {
              return (
                <CardAmount size="large" error={error}>
                  <CardTitle>{translate("WITHDRAWAL")}</CardTitle>

                  <Input
                    variant="dark"
                    name={name}
                    placeholder="0 USD"
                    value={value}
                    inputType="text"
                    handleChange={onChange}
                  />
                </CardAmount>
              );
            }}
          />

          <CardAmountTitle>
            {translate("SELECT_DETAILS_FOR_WITHDRAWAL")}
          </CardAmountTitle>

          <Controller
            control={secondControl}
            name="cryptoCoin"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <SelectInput
                //@ts-ignore
                icon={icons[value]}
                value={value}
                data={data}
                handleChange={onChange}
              />
            )}
          />

          <CardAmountTitle margin="10px 0px">
            {translate("SELECT_WALLET_FOR_WITHDRAWAL")}
          </CardAmountTitle>

          <Controller
            control={secondControl}
            name="wallet"
            rules={{ required: translate("ERROR_EMPTY_FIELD") }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <Input
                error={error?.message}
                name={name}
                // @ts-ignore
                leftIcon={icons[watch("cryptoCoin")]}
                value={value}
                handleChange={onChange}
              />
            )}
          />

          <WarningText>{translate("WARNING_WITHDRAWAL_TEXT")}</WarningText>

          <Button
            disabled={isLoading}
            background={`url(${buttonBackground})`}
            margin="20px 0px 0px"
            onClick={() => {}}
            width="100%"
            height="58px"
            padding="0px"
            text={isLoading ? "Зaгрузка..." : translate("CONFIRM_WITHDRAWAL")}
          />
        </CardBody>
      </form>
    </>
  );
};

export default WithdrawForm;
