import styled, { keyframes } from "styled-components";
import notificationBackground from "assets/notificationBackground.png";
import notificationIconBackground from "assets/notificationIconBackground.png";
import mobileNotificationBackground from "assets/mobileNotificationBackground.png";
import verificationBackground from "assets/verificationBackground.png";
import verificationSuccessBackground from "assets/verificationSuccessBackground.png";

const rotation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const verificationStatusBackground = {
  approved: "transparent",
  pending: `url(${verificationBackground})`,
  rejected: `linear-gradient(0.25turn, rgba(239, 109, 109, 0.5), rgba(255, 55, 55, 0.45))`,
};

const verificationIConWrapperBackground = {
  approved: `url(${verificationSuccessBackground})`,
  pending: "linear-gradient(to bottom, #aa67bf 50%, white 50%)",
  rejected: `rgba(229, 81, 114, 1)`,
};

const notificationsType: Record<ApiNotification["type"], string> = {
  info: "#E5AB52",
  kyc: "#a3ade8",
  withdrawal: "#565295",
  deposit: "#74B586",
};

export const StyledNotification = styled.div<{ isActive?: boolean }>(
  ({ isActive }) => ({
    width: "218px",
    height: "58px",
    display: "flex",
    alignItems: "center",
    padding: "0px 5px 0 30px",
    background: `url(${notificationBackground})`,
    cursor: "pointer",
    justifyContent: "space-between",
    position: "relative",

    "& span": {
      width: "115px",
      fontSize: "16px",
      lineHeight: "20px",
      textAlign: "center",
    },

    "@media screen and (max-width: 1000px)": {
      backgroundSize: "cover",
      height: "52px",
      width: "auto",
      padding: "2px",
      background: isActive ? "rgb(24, 28, 68)" : "transparent",
      borderRadius: "25px 25px 0px 0px",

      "& > span": {
        display: "none",
      },
    },
  })
);

export const StyledVerification = styled(StyledNotification)<{
  status?: "pending" | "approved" | "rejected" | null;
}>(({ status }) => ({
  width: "240px",
  borderRadius: "50px",
  cursor: "pointer",
  background: status
    ? verificationStatusBackground[status]
    : verificationStatusBackground.rejected,

  "@media screen and (max-width: 1000px)": {
    background: "transparent",
    width: "53px",
  },
}));

export const IconWrapper = styled.div({
  width: "48px",
  height: "48px",
  background: `url(${notificationIconBackground})`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  "@media screen and (max-width: 1000px)": {
    background: `url(${mobileNotificationBackground})`,
    backgroundSize: "cover",
  },
});

// ? `url(${verificationSuccessBackground})`
// : "linear-gradient(to bottom, #aa67bf 50%, white 50%)"};
export const VerificationIconWrapper = styled.div<{
  status?: "pending" | "approved" | "rejected" | null;
}>`
  width: 48px;
  height: 48px;
  border-radius: 48px;
  border: ${({ status }) =>
    status !== "pending" ? "0px" : "3px solid #914aa7"};
  padding: 2px;
  background: ${({ status }) =>
    status
      ? verificationIConWrapperBackground[status]
      : verificationIConWrapperBackground.rejected};
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  animation: 4s ${rotation} infinite linear;
  animation-play-state: ${({ status }) =>
    status === "pending" ? "running" : "paused"};
  @media screen and (max-width: 1000px) : {
    width: ${({ status }) => (status ? "53px" : undefined)};
    padding-right: ${({ status }) => (status ? "5px" : undefined)};
  }
`;

export const FilesWrapper = styled.div<{
  status?: "pending" | "approved" | "rejected" | null;
}>(({ status }) => ({
  width: "38px",
  height: "38px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: status !== "pending" ? "transparent" : `#914aa7`,
  borderRadius: "50%",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
}));

export const NotificationCounter = styled.div({
  width: "16px",
  height: "16px",
  background: "#677AEC",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: "5px",
  right: "5px",
  borderRadius: "50%",
  fontSize: "10px",
});

export const VerificationIndicator = styled(NotificationCounter)({
  right: "0px",
  background: "#70a77e",
});

export const NotificationList = styled.div({
  position: "absolute",
  width: "350px",
  padding: "10px 20px 20px",
  maxHeight: "430px",
  background: "rgb(24, 28, 68)",
  top: "51px",
  right: "-67px",
  borderRadius: "17px",
  display: "flex",
  flexDirection: "column",
  overflow: "scroll",
  zIndex: 1,

  "& button": {
    border: "1px solid white",
    borderRadius: "5px",
    background: "none",
    outline: "none",
    color: "white",
    fontFamily: "Stolzl",

    "&:hover": {
      border: "1px solid black",
      borderRadius: "5px",
      background: "white",
      color: "black",
    },
  },

  "&::-webkit-scrollbar": {
    display: "none",
  },
});

export const Notification = styled.div({
  padding: "15px 0px",
  display: "flex",
  alignItems: "center",
  gap: "15px",
  borderBottom: "1px solid #313268",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "19px",
});

export const NotificationImage = styled.div<{ type: ApiNotification["type"] }>(
  ({ type }) => ({
    width: "38px",
    height: "38px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    background: notificationsType[type],
    borderRadius: "50%",
  })
);

export const Indicator = styled.div({
  width: "15px",
  height: "15px",
  left: -2,
  top: -2,
  position: "absolute",
  background: "#677AEC",
  borderRadius: "50%",
  border: "2px solid rgb(24, 28, 68)",
});

export const EmptyState = styled.div({
  padding: "15px 0px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
