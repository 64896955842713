import AuthButton from "./AuthButton";
import AuthInput from "./AuthInput";
// styles
import {
  StyledLoginForm,
  StyledForm,
  LoginFormTitle,
  InputsContainer,
} from "./styles";
import { ReactComponent as EmailIcon } from "assets/emailIcon.svg";
import { useSendOtpMutation } from "redux/api/AuthApi";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslate } from "redux/hooks";

const ResetForm = () => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const { handleSubmit, control, setError } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
    },
  });
  const [sendOTP, { isLoading }] = useSendOtpMutation();

  const submit = async (data: { email: string }) => {
    const response = await sendOTP(data).unwrap();

    if (response.status === "success") {
      window.localStorage.setItem("email", data.email);

      navigate("/code-verification");
    }

    if (response.status === "error") {
      setError("email", {
        message: response.message || "The email must be a valid email address.",
      });
    }
  };

  return (
    <StyledLoginForm>
      <LoginFormTitle>
        Забыли <br /> свой пароль
      </LoginFormTitle>

      <StyledForm onSubmit={handleSubmit(submit)}>
        <InputsContainer>
          <Controller
            control={control}
            name="email"
            rules={{
              required: translate("REQUIRED_FIELD"),
              validate: {
                alphabet: (value) =>
                  /^[a-z0-9\.]+@([\w-]+\.)+[\w-]{2,4}$/gi.test(value) ||
                  translate("ALPHABET_EMAIL_ERROR"),
                length: (value) =>
                  (value.length >= 5 && value.length <= 50) ||
                  translate("EMAIL_LENGTH_ERROR"),
              },
            }}
            render={({
              field: { name, value, onChange, onBlur },
              fieldState: { error },
            }) => {
              return (
                <AuthInput
                  placeholder="E-mail"
                  name={name}
                  value={value}
                  error={error?.message}
                  inputType="email"
                  handleChange={onChange}
                  handleBlur={onBlur}
                  leftIcon={<EmailIcon />}
                />
              );
            }}
          />
        </InputsContainer>

        <AuthButton
          width="250px"
          disabled={isLoading}
          onClick={handleSubmit(submit)}
          text={isLoading ? "Загрузка..." : "Отправить письмо"}
        />
      </StyledForm>
    </StyledLoginForm>
  );
};

export default ResetForm;
